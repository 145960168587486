import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab.component';

const COMPONENTS = [TabGroupComponent, TabComponent];

@NgModule({
	declarations: [...COMPONENTS],
	imports: [CommonModule],
	exports: [...COMPONENTS],
})
export class TabsModule {}
