import { EuiIcon } from '../types/icon.interface';

export const usersFilled: EuiIcon = {
	name: 'users-filled',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.12971 6.5554C9.12971 8.28487 7.83866 9.68803 6.25464 9.68803C4.67061 9.68803 3.37956 8.29373 3.3782 6.56908C3.37065 4.88874 4.67469 3.5 6.25464 3.5C7.84424 3.5 9.12971 4.86891 9.12971 6.5554Z" fill="#262626"/>
  <path d="M6.25 10.75C4.91372 10.75 3.64786 11.0152 2.68771 11.5691C1.71083 12.1327 1 13.037 1 14.25C1 14.9572 1.27554 15.5559 1.78148 15.9607C2.26489 16.3474 2.88561 16.5 3.5 16.5H9C9.61439 16.5 10.2351 16.3474 10.7185 15.9607C11.2245 15.5559 11.5 14.9572 11.5 14.25C11.5 13.037 10.7892 12.1327 9.81229 11.5691C8.85214 11.0152 7.58628 10.75 6.25 10.75Z" fill="#262626"/>
  <path d="M13.0733 12.283C13.2965 12.2611 13.5231 12.25 13.7501 12.25C14.9138 12.25 15.8979 12.4848 16.5628 12.8684C17.2109 13.2423 17.5 13.713 17.5001 14.25C17.5001 14.5428 17.4006 14.6941 17.2815 14.7893C17.1399 14.9026 16.8857 15 16.5001 15H13.5001C13.0858 15 12.7501 15.3358 12.7501 15.75C12.7501 16.1642 13.0858 16.5 13.5001 16.5H16.5001C17.1144 16.5 17.7352 16.3474 18.2186 15.9607C18.7245 15.5559 19.0001 14.9572 19.0001 14.25C19 13.037 18.2892 12.1327 17.3123 11.5691C16.3522 11.0152 15.0863 10.75 13.7501 10.75C13.4743 10.75 13.1988 10.7635 12.9268 10.7902C12.5146 10.8306 12.2132 11.1976 12.2536 11.6098C12.2941 12.0221 12.661 12.3235 13.0733 12.283Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7441 9.68803C15.3282 9.68803 16.6192 8.28487 16.6192 6.5554C16.6192 4.86891 15.3337 3.5 13.7441 3.5C12.1642 3.5 10.8601 4.88874 10.8677 6.56908C10.8691 8.29373 12.1601 9.68803 13.7441 9.68803ZM13.7441 8.40927C12.8793 8.40927 12.1545 7.5899 12.1545 6.56908C12.1518 5.57755 12.8758 4.78012 13.7441 4.78012C14.6235 4.78012 15.34 5.56523 15.34 6.5554C15.34 7.5899 14.6104 8.40927 13.7441 8.40927Z" fill="#262626"/>
  </svg>
  `,
};
