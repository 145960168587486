import { NgModule } from '@angular/core';

import { SidePageComponent } from './side-page.component';
import { SidePageHeaderComponent } from './side-page-header.component';
import { SidePageBodyComponent } from './side-page-body.component';
import { SidePageTitleComponent } from './side-page-title.component';
import { SidePageFooterComponent } from './side-page-footer.component';

const COMPONENTS = [
	SidePageComponent,
	SidePageHeaderComponent,
	SidePageBodyComponent,
	SidePageTitleComponent,
	SidePageFooterComponent,
];

@NgModule({
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
})
export class SidePageModule {}
