import { EuiIcon } from '../types/icon.interface';

export const addComment: EuiIcon = {
	name: 'add-comment',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.7502 5.77213C10.7502 5.35791 10.4144 5.02213 10.0002 5.02213C9.58597 5.02213 9.25018 5.35791 9.25018 5.77213V7.77213H7.25012C6.83591 7.77213 6.50012 8.10791 6.50012 8.52213C6.50012 8.93634 6.83591 9.27213 7.25012 9.27213H9.25018V11.2721C9.25018 11.6863 9.58597 12.0221 10.0002 12.0221C10.4144 12.0221 10.7502 11.6863 10.7502 11.2721V9.27213H12.7501C13.1643 9.27213 13.5001 8.93634 13.5001 8.52213C13.5001 8.10791 13.1643 7.77213 12.7501 7.77213H10.7502V5.77213Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66806 17.2545C7.14673 17.7118 6.83534 17.9167 6.41649 17.9167C5.81117 17.9167 5.46216 17.4935 5.46216 16.8293V14.9762H5.15242C2.96682 14.9762 1.66663 13.6823 1.66663 11.445V5.61455C1.66663 3.37721 2.93567 2.08337 5.19983 2.08337H14.7988C17.0643 2.08337 18.3333 3.38338 18.3333 5.61455V11.445C18.3333 13.6762 17.0643 14.9762 14.7988 14.9762H10.2439L7.66806 17.2545ZM6.78191 14.1145V16.2609L9.17099 13.8985C9.48945 13.5769 9.70694 13.5032 10.1636 13.5032H14.7581C16.1745 13.5032 16.859 12.7804 16.859 11.3982V5.65515C16.859 4.27304 16.1745 3.55628 14.7581 3.55628H5.24181C3.81926 3.55628 3.14094 4.27304 3.14094 5.65515V11.3982C3.14094 12.7804 3.81926 13.5032 5.24181 13.5032H6.17011C6.59273 13.5032 6.78191 13.6734 6.78191 14.1145Z" fill="#262626"/>
  </svg>
  `,
};
