import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[euiTableColumnFixed]',
})
export class TableColumnFixedDirective {
	constructor(private el: ElementRef) {
		this.el.nativeElement.classList.add('eui-table__fixed-column');
	}
}
