import { EuiIcon } from '../types/icon.interface';

export const listBulletRectangle: EuiIcon = {
	name: 'list-bullet-rectangle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.65967 7.60706C6.21195 7.60706 6.65967 7.15934 6.65967 6.60706C6.65967 6.05477 6.21195 5.60706 5.65967 5.60706C5.10738 5.60706 4.65967 6.05477 4.65967 6.60706C4.65967 7.15934 5.10738 7.60706 5.65967 7.60706Z" fill="#262626"/>
  <path d="M7.53856 6.60706C7.53856 6.19284 7.87434 5.85706 8.28856 5.85706H14.5904C15.0046 5.85706 15.3404 6.19284 15.3404 6.60706C15.3404 7.02127 15.0046 7.35706 14.5904 7.35706H8.28856C7.87434 7.35706 7.53856 7.02127 7.53856 6.60706Z" fill="#262626"/>
  <path d="M8.28856 12.6429C7.87434 12.6429 7.53856 12.9787 7.53856 13.3929C7.53856 13.8072 7.87434 14.1429 8.28856 14.1429H14.5904C15.0046 14.1429 15.3404 13.8072 15.3404 13.3929C15.3404 12.9787 15.0046 12.6429 14.5904 12.6429H8.28856Z" fill="#262626"/>
  <path d="M6.65967 10.0001C6.65967 10.5523 6.21195 11.0001 5.65967 11.0001C5.10738 11.0001 4.65967 10.5523 4.65967 10.0001C4.65967 9.44778 5.10738 9.00006 5.65967 9.00006C6.21195 9.00006 6.65967 9.44778 6.65967 10.0001Z" fill="#262626"/>
  <path d="M6.65967 13.3929C6.65967 13.9452 6.21195 14.3929 5.65967 14.3929C5.10738 14.3929 4.65967 13.9452 4.65967 13.3929C4.65967 12.8407 5.10738 12.3929 5.65967 12.3929C6.21195 12.3929 6.65967 12.8407 6.65967 13.3929Z" fill="#262626"/>
  <path d="M7.53856 10.0001C7.53856 9.58585 7.87434 9.25006 8.28856 9.25006H14.5904C15.0046 9.25006 15.3404 9.58585 15.3404 10.0001C15.3404 10.4143 15.0046 10.7501 14.5904 10.7501H8.28856C7.87434 10.7501 7.53856 10.4143 7.53856 10.0001Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 15C1.75 16.7949 3.20508 18.25 5 18.25H15C16.7949 18.25 18.25 16.7949 18.25 15V5C18.25 3.20507 16.7949 1.75 15 1.75L5 1.75C3.20507 1.75 1.75 3.20508 1.75 5V15ZM5 16.75C4.0335 16.75 3.25 15.9665 3.25 15V5C3.25 4.0335 4.0335 3.25 5 3.25L15 3.25C15.9665 3.25 16.75 4.0335 16.75 5V15C16.75 15.9665 15.9665 16.75 15 16.75H5Z" fill="#262626"/>
  </svg>
  `,
};
