import { EuiIcon } from '../types/icon.interface';

export const user: EuiIcon = {
	name: 'user',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.001 9.58473C11.7299 9.58473 13.1389 8.05315 13.1389 6.16539C13.1389 4.32456 11.7359 2.83038 10.001 2.83038C8.27653 2.83038 6.85295 4.34623 6.86116 6.18033C6.8628 8.06283 8.27201 9.58473 10.001 9.58473ZM10.001 8.18892C9.05671 8.18892 8.26585 7.29459 8.26585 6.18033C8.26298 5.09806 9.05301 4.22765 10.001 4.22765C10.9608 4.22765 11.7427 5.08461 11.7427 6.16539C11.7427 7.29459 10.9465 8.18892 10.001 8.18892Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99994 10.8608C6.4952 10.8608 3.69116 12.1353 3.69116 15.0667C3.69116 16.4364 4.61201 17.1696 5.79409 17.1696H14.2058C15.3879 17.1696 16.3087 16.4364 16.3087 15.0667C16.3087 12.1353 13.5047 10.8608 9.99994 10.8608ZM5.79409 15.9079C5.25378 15.9079 4.95292 15.6683 4.95292 15.0667C4.95292 13.1039 7.11191 12.1226 9.99994 12.1226C12.888 12.1226 15.047 13.1039 15.047 15.0667C15.047 15.6683 14.7461 15.9079 14.2058 15.9079H5.79409Z" fill="#262626"/>
  </svg>
  `,
};
