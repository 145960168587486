<div
	#euiTableContainer
	class="eui-table"
	[ngClass]="{
		'eui-table__sm': rowsDensity === 'sm',
		'eui-table__md': rowsDensity === 'md',
		'eui-table__lg': rowsDensity === 'lg',
		'eui-table__dividers-vertical': showVerticalDividers,
		'eui-table__dividers-horizontal': showHorizontalDividers,
		'eui-table__skeleton': loader
	}">
	<table #euiTable>
		<thead>
			<tr>
				<ng-container *ngIf="!loader && rows && rows.length > 0">
					<!-- Row Headers column -->
					<ng-container *ngIf="config?.rows?.headers">
						<th euiTableRowHeader euiTableColumnFixed></th>
					</ng-container>

					<!-- Multiselect column -->
					<ng-container *ngIf="config?.rows?.multiselect">
						<th euiTableRowMultiselect></th>
					</ng-container>
				</ng-container>

				<!-- Regular columns -->
				<ng-container
					*ngFor="let header of headers; index as i; trackBy: trackByFn">
					<!-- euiResizeColumn -->
					<!-- [resizable]="false" -->
					<!-- [columnIndex]="i" -->
					<th
						[attr.colspan]="header.colSpan || 1"
						[ngClass]="{
							'eui-table__fixed-column':
								!loader && rows && rows.length > 0 && isFixedColumn(i)
						}"
						[ngStyle]="{
							left: !loader ? getLeftOffsetForFixedColumn(i) : null
						}">
						<div
							class="eui-table__header-cell"
							[ngStyle]="{ width: header.width || '' }"
							[ngClass]="{
								'eui-table__header-sortable': header.sortable,
								'eui-table__header-sort-active':
									header.property && getCurrentSortProperty(header.property),
								'eui-table__header-expandable': header.expandable
							}">
							<!-- Header cell content -->
							<div
								class="eui-table__header-cell__content"
								euiSort
								[sortProperty]="header.property"
								[sortOrder]="'desc'"
								[sortType]="''"
								(sortClick)="onSortClick($event)">
								<!-- Header cell Expand button -->
								<ng-container
									*ngIf="
										!loader && rows && rows.length > 0 && header.expandable
									">
									<eui-expand-button
										[expanded]="expanded"
										(expandClick)="onExpandClick($event)"></eui-expand-button>
								</ng-container>

								<!-- Header cell name -->
								{{ header.name }}

								<!-- Header cell sort -->
								<ng-container *ngIf="header.sortable && header.property">
									<ng-container
										*ngIf="
											!currentSort ||
												(getCurrentSortProperty(header.property) &&
													currentSort.order === 'desc');
											else upArrow
										">
										<eui-icons name="arrow-down" [size]="16"></eui-icons>
									</ng-container>

									<ng-template #upArrow>
										<eui-icons name="arrow-up" [size]="16"></eui-icons>
									</ng-template>
								</ng-container>

								<!-- Header cell tooltip -->
								<ng-container *ngIf="header.tooltip">
									<span
										class="eui-table__header-cell__tooltip"
										[euiTooltip]="header.tooltip.text"
										[euiTooltipTrigger]="header.tooltip.trigger">
										<eui-icons
											[name]="header.tooltip.icon"
											[size]="16"></eui-icons>
									</span>
								</ng-container>
							</div>

							<!-- Header cell filters -->
							<div
								*ngIf="
									header.property && getFilters(header.property) as filter
								">
								<ng-template #customTriggerTemplate>
									<eui-icons
										[ngClass]="getFilterButtonClass(filter)"
										name="filters"
										[size]="16"></eui-icons>
								</ng-template>

								<eui-dropdown
									label=""
									[options]="filter.options"
									[displayField]="filter.displayField || 'name'"
									[search]="true"
									[multiple]="filter.multiple || false"
									[actionsButtons]="filter.multiple || false"
									[customTriggerTemplateRef]="customTriggerTemplate"
									[(ngModel)]="filter.selectedValue"
									(ngModelChange)="onFilterOptionChange($event, filter)">
								</eui-dropdown>
							</div>
						</div>
					</th>
				</ng-container>
			</tr>

			<ng-container *ngIf="rows && rows.length !== 0 && !loader">
				<!-- DEFAULT SUMMARY TEMPLATE -->
				<ng-template #defaultSummaryTemplateRef>
					<tr
						*ngFor="let summaryRow of summaryRows; trackBy: trackByFn"
						class="eui-table__summary-row">
						<th
							*ngIf="config?.rows?.multiselect"
							class="eui-table__header-multiselect"></th>
						<th
							*ngFor="
								let data of summaryRow | keyvalue: unsortedPipeFn;
								trackBy: trackByFn
							">
							{{ data.value }}
						</th>
					</tr>
				</ng-template>

				<ng-container
					*ngIf="summaryRowsTemplateRef; else defaultSummaryTemplateRef">
					<ng-container
						*ngFor="
							let summaryRow of summaryRows;
							index as i;
							trackBy: trackByFn
						"
						[ngTemplateOutletContext]="{
							$implicit: summaryRow,
							index: i
						}"
						[ngTemplateOutlet]="summaryRowsTemplateRef"></ng-container>
				</ng-container>
			</ng-container>
		</thead>

		<tbody>
			<ng-container *ngIf="!loader; else loaderTemplateRef">
				<ng-container
					*ngFor="let row of rows; index as i; trackBy: trackByFn"
					[ngTemplateOutletContext]="{
						$implicit: row,
						index: i,
						rowHeader: i + 1
					}"
					[ngTemplateOutlet]="rowsTemplateRef"></ng-container>
			</ng-container>

			<ng-container *ngIf="rows && rows.length === 0 && !loader">
				<tr>
					<td [attr.colspan]="headers.length">Ничего не найдено</td>
				</tr>
			</ng-container>

			<ng-template #loaderTemplateRef>
				<tr *ngFor="let item of [].constructor(15)">
					<ng-container
						*ngFor="let header of headers; index as i; trackBy: trackByFn">
						<td [attr.colspan]="header.colSpan || 1">
							<eui-skeleton-loader></eui-skeleton-loader>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</tbody>
	</table>
</div>
