import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'eui-side-page',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./side-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePageComponent implements OnChanges {
	@Input() shadow: boolean | undefined = false;

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['shadow']) {
			this.renderer.removeClass(
				this.hostElement.nativeElement,
				'eui-side-page__shadow',
			);

			if (this.shadow) {
				this.renderer.addClass(
					this.hostElement.nativeElement,
					'eui-side-page__shadow',
				);
			}
		}
	}
}
