import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';

import { SortEvent } from '@emrm/common/helpers/sort';

import { TableHeader, TableHeaderFilterConfig } from './table-header.interface';
import { TableRowsDensity } from './table-rows-density.type';
import { TableConfig } from './table-options.interface';

@Component({
	selector: 'eui-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnChanges {
	@ViewChild('euiTableContainer') tableContainer!: ElementRef;
	@ViewChild('euiTable') table!: ElementRef;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() rowsTemplateRef!: TemplateRef<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() summaryRowsTemplateRef!: TemplateRef<any>;

	@Input() rows: unknown[] | null = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() summaryRows: any[] | null = [];
	@Input() loader: boolean | null = true;
	@Input() headers: TableHeader[] = [];
	@Input() config: Partial<TableConfig> | null = null;
	@Input() filters: TableHeaderFilterConfig[] = [];
	@Input() expanded = false;

	@Input() rowsDensity: TableRowsDensity | null = 'sm';
	@Input() showVerticalDividers: boolean | null = false;
	@Input() showHorizontalDividers: boolean | null = true;

	@Output() sortChange = new EventEmitter();
	@Output() filterChange = new EventEmitter();
	@Output() expandClick = new EventEmitter();

	currentSort!: SortEvent;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['loader']) {
			// this fixed loading skeleton position when load new data
			const loaderValue = changes['loader'].currentValue;

			if (loaderValue && this.tableContainer) {
				this.tableContainer.nativeElement.scrollTo(0, 0);
			}
		}
	}

	trackByFn(index: number): number {
		return index;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
	unsortedPipeFn(a: any, b: any) {
		return a;
	}

	getFilters(headerProperty: string) {
		return this.filters.find((x) => x.property === headerProperty);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getFilterButtonClass(filter: any) {
		return filter &&
			filter.selectedValue &&
			Array.isArray(filter.selectedValue) &&
			filter.selectedValue.length === 0
			? 'eui-table__filter-button-disabled'
			: 'eui-table__filter-button-active';
	}

	getCurrentSortProperty(property: string) {
		return this.currentSort && this.currentSort.property === property;
	}

	isFixedColumn(index: number) {
		return !!this.getFixedColumnOption(index);
	}

	getLeftOffsetForFixedColumn(index: number) {
		const findOption = this.getFixedColumnOption(index);

		if (!findOption || this.loader) {
			return null;
		}

		return findOption.offset;
	}

	private getFixedColumnOption(index: number) {
		return this.config?.columns?.fixed?.find((x) => x.index === index);
	}

	onExpandClick(e: { expanded: boolean; context: unknown }) {
		this.expandClick.emit(e);
	}

	onSortClick(e: SortEvent) {
		this.currentSort = e;
		this.sortChange.emit(e);
	}

	onFilterOptionChange(e: unknown, header: TableHeaderFilterConfig) {
		this.filterChange.emit({ value: e, property: header.property });
	}
}
