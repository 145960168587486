import { EuiIcon } from '../types/icon.interface';

export const personTextRectangle: EuiIcon = {
	name: 'person-text-rectangle',
	data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.7701 20H19.2299C21.0595 20 22 19.0523 22 17.2232V6.78382C22 4.9532 21.0595 4 19.2299 4H4.7701C2.94746 4 2 4.9532 2 6.78382V17.2232C2 19.0523 2.94746 20 4.7701 20ZM4.86602 18.3206C4.08086 18.3206 3.65441 17.908 3.65441 17.0751V6.93039C3.65441 6.09754 4.08086 5.6794 4.86602 5.6794H19.134C19.9123 5.6794 20.3456 6.09754 20.3456 6.93039V17.0751C20.3456 17.908 19.9123 18.3206 19.134 18.3206H4.86602Z" fill="#262626"/>
  <path d="M18.1907 9.60683H13.9834C13.6813 9.60683 13.458 9.39158 13.458 9.11324C13.458 8.84399 13.6813 8.63014 13.9834 8.63014H18.1907C18.4831 8.63014 18.7079 8.84399 18.7079 9.11324C18.7079 9.39158 18.4831 9.60683 18.1907 9.60683Z" fill="#262626"/>
  <path d="M18.1907 12.5838H13.9834C13.6813 12.5838 13.458 12.3622 13.458 12.0839C13.458 11.8132 13.6813 11.6071 13.9834 11.6071H18.1907C18.4831 11.6071 18.7079 11.8132 18.7079 12.0839C18.7079 12.3622 18.4831 12.5838 18.1907 12.5838Z" fill="#262626"/>
  <path d="M18.1907 15.5503H13.9834C13.6813 15.5503 13.458 15.3441 13.458 15.0735C13.458 14.7952 13.6813 14.5736 13.9834 14.5736H18.1907C18.4831 14.5736 18.7079 14.7952 18.7079 15.0735C18.7079 15.3441 18.4831 15.5503 18.1907 15.5503Z" fill="#262626"/>
  <path d="M11.8471 15.5503H5.72885C5.43653 15.5503 5.29224 15.3597 5.29224 15.1074C5.29224 14.3934 6.39214 12.5667 8.78537 12.5667C11.1772 12.5667 12.2837 14.3934 12.2837 15.1074C12.2837 15.3597 12.1394 15.5503 11.8471 15.5503Z" fill="#262626"/>
  <path d="M10.5345 10.23C10.5345 11.2717 9.75687 12.0538 8.78537 12.0475C7.81761 12.0475 7.03483 11.2717 7.03483 10.23C7.03483 9.25643 7.81761 8.44977 8.78537 8.44977C9.75687 8.44977 10.5345 9.25643 10.5345 10.23Z" fill="#262626"/>
  </svg>
  `,
};
