import { animate, style, transition, trigger } from '@angular/animations';
import {
	Component,
	ChangeDetectionStrategy,
	TemplateRef,
	ElementRef,
	ViewChild,
	AfterViewInit,
	Renderer2,
} from '@angular/core';

@Component({
	selector: 'eui-tooltip',
	template: `
		<div class="tooltip" @tooltip #tooltipElement>
			@if (tooltipTemplateRef) {
				<ng-template [ngTemplateOutlet]="tooltipTemplateRef"></ng-template>
			} @else {
				{{ tooltip }}
			}

			<div class="corner" #tooltipCorner>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="6"
					viewBox="0 0 20 6">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M10 0C7 -2.62268e-07 4.0001 6 0 6L20 6C16.0251 6 13 2.62268e-07 10 0Z" />
				</svg>
			</div>
		</div>
	`,
	styleUrls: ['./tooltip.component.scss'],
	animations: [
		trigger('tooltip', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(300, style({ opacity: 1 })),
			]),
			transition(':leave', [animate(100, style({ opacity: 0 }))]),
		]),
	],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { '[@tooltip]': 'in' },
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit {
	position = '';
	tooltip: string | undefined = '';
	tooltipTemplateRef: TemplateRef<unknown> | undefined;
	width = 0;
	height = 0;

	@ViewChild('tooltipElement')
	tooltipElement: ElementRef | undefined;
	@ViewChild('tooltipCorner')
	tooltipCorner: ElementRef | undefined;

	constructor(private renderer: Renderer2) {}

	ngAfterViewInit(): void {
		this.setCorner(
			this.tooltipCorner?.nativeElement,
			this.tooltipElement?.nativeElement,
		);
	}

	setCorner(cornerEl: HTMLElement, tooltipEl: HTMLElement) {
		switch (this.position) {
			case 'top':
				this.renderer.setStyle(cornerEl, 'bottom', `-12px`);
				this.renderer.setStyle(
					cornerEl,
					'left',
					`${tooltipEl.offsetWidth / 2 - cornerEl.offsetWidth / 2}px`,
				);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(180deg)`);

				break;
			case 'top-left':
				this.renderer.setStyle(cornerEl, 'bottom', `-12px`);
				this.renderer.setStyle(cornerEl, 'left', `12px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(180deg)`);

				break;
			case 'top-right':
				this.renderer.setStyle(cornerEl, 'bottom', `-12px`);
				this.renderer.setStyle(cornerEl, 'right', `12px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(180deg)`);

				break;
			case 'bottom':
				this.renderer.setStyle(cornerEl, 'top', `-12px`);
				this.renderer.setStyle(
					cornerEl,
					'left',
					`${tooltipEl.offsetWidth / 2 - cornerEl.offsetWidth / 2}px`,
				);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(0deg)`);

				break;
			case 'bottom-left':
				this.renderer.setStyle(cornerEl, 'top', `-12px`);
				this.renderer.setStyle(cornerEl, 'left', `12px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(0deg)`);

				break;
			case 'bottom-right':
				this.renderer.setStyle(cornerEl, 'top', `-12px`);
				this.renderer.setStyle(cornerEl, 'right', `12px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(0deg)`);

				break;
			case 'left':
				this.renderer.setStyle(
					cornerEl,
					'top',
					`${tooltipEl.offsetHeight / 2 - cornerEl.offsetHeight / 2}px`,
				);
				this.renderer.setStyle(cornerEl, 'right', `-13px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(90deg)`);

				break;
			case 'right':
				this.renderer.setStyle(
					cornerEl,
					'top',
					`${tooltipEl.offsetHeight / 2 - cornerEl.offsetHeight / 2}px`,
				);
				this.renderer.setStyle(cornerEl, 'left', `-13px`);
				this.renderer.setStyle(cornerEl, 'transform', `rotate(270deg)`);
				break;

			default:
				this.renderer.setStyle(
					cornerEl,
					'top',
					`${this.height / 2 - cornerEl.offsetHeight / 2}px`,
				);
				this.renderer.setStyle(cornerEl, 'left', `${this.width + 10}px`);
		}
	}
}
