import { EuiIcon } from '../types/icon.interface';

export const userPlus: EuiIcon = {
	name: 'user-plus',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0011 8.66949C9.7301 8.66949 11.139 7.13794 11.139 5.25018C11.139 3.40933 9.73596 1.91516 8.0011 1.91516C6.27673 1.91516 4.85315 3.431 4.86121 5.26511C4.86292 7.14761 6.27209 8.66949 8.0011 8.66949ZM8.0011 7.27371C7.05676 7.27371 6.26599 6.37936 6.26599 5.26511C6.26306 4.18283 7.0531 3.31244 8.0011 3.31244C8.96106 3.31244 9.7428 4.1694 9.7428 5.25018C9.7428 6.37936 8.94666 7.27371 8.0011 7.27371Z" fill="#262626"/>
  <path d="M8.00012 9.94562C4.49524 9.94562 1.69128 11.2201 1.69128 14.1515C1.69128 15.5212 2.61218 16.2544 3.79431 16.2544H9.80286C9.61243 15.8599 9.47253 15.4365 9.39124 14.9926H3.79431C3.25378 14.9926 2.953 14.7531 2.953 14.1515C2.953 12.1887 5.11194 11.2074 8.00012 11.2074C8.7533 11.2074 9.45667 11.2741 10.0853 11.4076C10.3409 11.0052 10.6525 10.6419 11.0089 10.3285C10.1166 10.0694 9.09216 9.94562 8.00012 9.94562Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.309 14.0834C18.309 16.2656 16.4799 18.0848 14.309 18.0848C12.1139 18.0848 10.309 16.2784 10.309 14.0834C10.309 11.8898 12.1139 10.0848 14.309 10.0848C16.4989 10.0848 18.309 11.8898 18.309 14.0834ZM13.7753 16.0933C13.7753 16.4039 13.9843 16.6192 14.309 16.6192C14.6288 16.6192 14.8375 16.4039 14.8375 16.0933V14.6134H16.3099C16.6268 14.6134 16.8422 14.4045 16.8422 14.0848C16.8422 13.7664 16.6268 13.5575 16.3099 13.5575H14.8375V12.0817C14.8375 11.7683 14.6288 11.553 14.309 11.5544C13.9843 11.5559 13.7753 11.7712 13.7753 12.0817V13.5575H12.3046C11.9847 13.5575 11.7758 13.7664 11.7772 14.0848C11.7787 14.4045 11.9877 14.6134 12.3046 14.6134H13.7753V16.0933Z" fill="#262626"/>
  </svg>
  `,
};
