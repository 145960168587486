import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

const COMPONENTS = [TooltipComponent, TooltipDirective];

@NgModule({
	imports: [CommonModule],
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
})
export class TooltipModule {}
