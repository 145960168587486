import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	Renderer2,
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
	selector: 'eui-tab-group',
	template: `
		<ul>
			<li
				class="eui-tab"
				*ngFor="let tab of tabs; trackBy: trackByFn"
				(click)="selectTab(tab)"
				[class.active]="tab.active">
				<a>{{ tab.title }}</a>
				<ng-container
					*ngIf="tab.suffixTemplate"
					[ngTemplateOutlet]="tab.suffixTemplate">
				</ng-container>
			</li>
			<li class="eui-tab-border"></li>
		</ul>
		<ng-content></ng-content>
	`,
	styleUrls: ['./tab-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent implements AfterContentInit {
	@ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

	@Input() set fullWidth(fullWidth: boolean) {
		if (fullWidth) {
			this.renderer.addClass(
				this.hostElement.nativeElement,
				'eui-tab-group__full-width',
			);
		}
	}

	@Input() set vertical(vertical: boolean) {
		if (vertical) {
			this.renderer.addClass(
				this.hostElement.nativeElement,
				'eui-tab-group__vertical',
			);
		}
	}

	@Input() set color(color: string) {
		this.renderer.addClass(
			this.hostElement.nativeElement,
			`eui-tab-group__${color}`,
		);
	}

	@Output() tabChange = new EventEmitter();

	constructor(
		private renderer: Renderer2,
		private hostElement: ElementRef,
		private cdr: ChangeDetectorRef,
	) {}

	ngAfterContentInit() {
		const activeTabs = this.tabs.filter((tab) => tab.active);

		if (activeTabs.length === 0) {
			this.selectTab(this.tabs.first);
		}
	}

	trackByFn(index: number): number {
		return index;
	}

	selectTab(tab: TabComponent) {
		const activeTabs = this.tabs.filter((tab) => tab.active);

		if (activeTabs.includes(tab)) {
			return;
		}

		this.tabs.toArray().forEach((tab) => {
			tab.active = false;
			tab.cdr.detectChanges();
		});
		tab.active = true;
		tab.cdr.detectChanges();
		this.cdr.detectChanges();

		const tabIndex = this.tabs.toArray().indexOf(tab);
		this.tabChange.emit(tabIndex);
	}
}
