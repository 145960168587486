import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	TemplateRef,
} from '@angular/core';

@Component({
	selector: 'eui-tab',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
	@Input() active = false;
	@Input() title = 'New tab';
	@Input() suffixTemplate!: TemplateRef<unknown>;

	@HostBinding('style.display')
	get displayStyle() {
		return this.active ? 'block' : 'none';
	}

	constructor(public readonly cdr: ChangeDetectorRef) {}
}
