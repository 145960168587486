import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import {
	Paginator,
	PaginatorConfig,
	generatePaginationRange,
	DOTS,
} from './types';

@Component({
	selector: 'eui-paginator',
	templateUrl: 'paginator.component.html',
	styleUrls: ['./paginator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
	@Input() fullWidth = false;
	@Input() showPageSizeOptions = true;
	@Input() nextLabel = 'Дальше';
	@Input() previousLabel = 'Назад';
	@Input() pageSizeOptionsLabel = 'Показать';
	@Input() align = 'space-between';
	@Output() changed = new EventEmitter<Paginator>();

	minPageToShowPagination = 2;
	pageSizeOptions: number[] = [10, 20, 50];
	pageSize = this.pageSizeOptions[0];
	pageSizeModel = this.pageSizeOptions[0];

	currentPage = 1;
	siblingCount = 1;
	dots = DOTS;
	paginationRange = generatePaginationRange(
		this.total,
		this.pageSize,
		this.siblingCount,
		this.currentPage,
	);
	lastPage = this.paginationRange[this.paginationRange.length - 1];

	private wrappedTotal = 0;

	constructor(private cdr: ChangeDetectorRef) {}

	@Input() set paginatorOptions(value: PaginatorConfig | undefined) {
		if (value) {
			if (value.pageSizeOptions && value.pageSizeOptions.length > 0) {
				this.pageSizeOptions = value.pageSizeOptions;
			}

			let newPageSize = this.pageSizeOptions[0];

			if (value.pageSize) {
				if (this.pageSizeOptions.filter((x) => x === value.pageSize)[0]) {
					newPageSize = value.pageSize;
				}
			}

			this.pageSize = newPageSize;
			this.pageSizeModel = newPageSize;
			this.cdr.markForCheck();
		}
	}

	get total() {
		return this.wrappedTotal;
	}

	@Input() set total(value: number) {
		this.wrappedTotal = value;
		this.updateState();
		this.cdr.markForCheck();
	}

	trackByFn(index: number): number {
		return index;
	}

	pageSizeChangeHandler(e: number) {
		if (this.pageSize === e) {
			return;
		}

		this.pageSize = e;
		this.currentPage = 1;
		this.updateState();
	}

	previousPageHandler() {
		this.pageChange(this.currentPage - 1);
	}

	nextPageHandler() {
		this.pageChange(this.currentPage + 1);
	}

	pageChangeHandler(e: number | string) {
		if (typeof e === 'number') {
			this.pageChange(e);
		}
	}

	resetState() {
		this.currentPage = 1;
		this.updatePagination();
		this.cdr.detectChanges();
	}

	private pageChange(newPage: number) {
		if (this.currentPage === newPage) {
			return;
		}

		this.currentPage = newPage;
		this.updateState();
	}

	private updateState() {
		this.updatePagination();
		const pageEvent: Paginator = {
			page: this.currentPage,
			limit: this.pageSize,
			offset: this.pageSize * (this.currentPage - 1),
		};

		this.changed.emit(pageEvent);
		this.cdr.detectChanges();
	}

	private updatePagination() {
		this.paginationRange = generatePaginationRange(
			this.total,
			this.pageSize,
			this.siblingCount,
			this.currentPage,
		);
		this.lastPage = this.paginationRange[this.paginationRange.length - 1];
	}
}
