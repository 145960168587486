import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private afterClosedSubject = new Subject<any>();

	constructor(public readonly overlayRef: OverlayRef) {}

	/**
	 * Closes the overlay. You can optionally provide a result.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public close(result?: any) {
		this.overlayRef.dispose();
		this.afterClosedSubject.next(result);
		this.afterClosedSubject.complete();
		document.body.style.overflow = '';
	}

	/**
	 * An Observable that notifies when the overlay has closed
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public afterClosed(): Observable<any> {
		return this.afterClosedSubject.asObservable();
	}
}
