import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SortModule } from '../../directives/sort';
import { CheckboxModule } from '../checkbox';
import { DropdownModule } from '../dropdown';
import { IconButtonModule } from '../icon-button';
import { IconsModule } from '../icons';
import { MenuModule } from '../menu';
import { TooltipModule } from '../tooltip';
import { SkeletonLoaderModule } from '../skeleton-loader';
import { ExpandButtonModule } from '../expand-button';

import { TableComponent } from './table.component';
import { ResizeColumnDirective } from './resize-column.directive';
import { TableInputCellDirective } from './table-input-cell.directive';
import { TableRowHeaderDirective } from './table-row-header.directive';
import { TableRowMultiselectDirective } from './table-row-multiselect.directive';
import { TableColumnFixedDirective } from './table-column-fixed.directive';

const COMPONENTS = [
	TableComponent,
	ResizeColumnDirective,
	TableInputCellDirective,
	TableRowHeaderDirective,
	TableRowMultiselectDirective,
	TableColumnFixedDirective,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SortModule,
		MenuModule,
		IconsModule,
		CheckboxModule,
		IconButtonModule,
		DropdownModule,
		TooltipModule,
		SkeletonLoaderModule,
		ExpandButtonModule,
	],
	exports: [...COMPONENTS],
	declarations: [...COMPONENTS],
})
export class TableModule {}
