import { EuiIcon } from '../types/icon.interface';

export const selectedElements: EuiIcon = {
	name: 'selected-elements',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.47852 6.17267C5.32715 6.17267 5.18604 6.21759 5.06787 6.29498C4.86328 6.42902 4.72852 6.6601 4.72852 6.92267C4.72852 7.33685 5.06445 7.67267 5.47852 7.67267H10.9888C11.2593 7.67267 11.4961 7.5296 11.6279 7.315C11.6982 7.20087 11.7388 7.06647 11.7388 6.92267C11.7388 6.50848 11.4028 6.17267 10.9888 6.17267H5.47852Z" fill="#262626"/>
<path d="M5.20703 12.3781C5.1333 12.4068 5.06543 12.4467 5.00537 12.4957C4.83643 12.6332 4.72852 12.8427 4.72852 13.0775C4.72852 13.4916 5.06445 13.8275 5.47852 13.8275H10.9888C11.4028 13.8275 11.7388 13.4916 11.7388 13.0775C11.7388 12.8624 11.6484 12.6684 11.5034 12.5316C11.3691 12.405 11.188 12.3275 10.9888 12.3275H5.47852C5.38281 12.3275 5.2915 12.3454 5.20703 12.3781Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9561 9.28607V10.5336C15.9561 11.0666 15.6123 11.3994 15.0298 11.3994H4.9707C4.38916 11.3994 4.04443 11.0666 4.04443 10.5336V9.28607C4.04443 8.75311 4.38916 8.42035 4.9707 8.42035H15.0298C15.6123 8.42035 15.9561 8.75311 15.9561 9.28607ZM5.47998 9.15985C5.06592 9.15985 4.72998 9.49567 4.72998 9.90985C4.72998 9.96478 4.73584 10.0184 4.74707 10.07C4.75537 10.1082 4.7666 10.1453 4.78076 10.1811C4.8335 10.3168 4.92432 10.4335 5.04053 10.5176C5.16406 10.6071 5.31592 10.6599 5.47998 10.6599H11.4707C11.6851 10.6599 11.8779 10.5701 12.0146 10.4263C12.1426 10.2918 12.2207 10.11 12.2207 9.90985C12.2207 9.49567 11.8853 9.15985 11.4707 9.15985H5.47998Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 3.00006C3.20508 3.00006 1.75 4.45514 1.75 6.25006V13.7501C1.75 15.545 3.20508 17.0001 5 17.0001H15C16.7949 17.0001 18.25 15.545 18.25 13.7501V6.25006C18.25 4.45514 16.7949 3.00006 15 3.00006H5ZM3.25 6.25006C3.25 5.28351 4.03369 4.50006 5 4.50006H15C15.9663 4.50006 16.75 5.28351 16.75 6.25006V13.7501C16.75 14.7166 15.9663 15.5001 15 15.5001H5C4.03369 15.5001 3.25 14.7166 3.25 13.7501V6.25006Z" fill="#262626"/>
</svg>
`,
};
