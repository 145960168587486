import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
} from '@angular/core';

type IconButtonColor =
	| 'primary'
	| 'secondary'
	| 'fade'
	| 'transparent'
	| 'transparent-grey'
	| 'transparent-light-grey';
type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg';
type IconButtonShape = 'square' | 'round';

@Component({
	selector: 'eui-icon-button, button[euiIconButton], a[euiIconButton]',
	template: '<ng-content></ng-content>',
	styleUrls: ['./icon-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
	@Input()
	set shape(shape: IconButtonShape) {
		this.renderer.addClass(
			this.hostElement.nativeElement,
			`eui-icon-btn__${shape}`,
		);
	}

	private wrappedColor!: IconButtonColor;

	get color() {
		return this.wrappedColor;
	}

	@Input()
	set color(color: IconButtonColor) {
		if (this.color) {
			this.renderer.removeClass(
				this.hostElement.nativeElement,
				`eui-icon-btn__${this.color}`,
			);
		}

		this.renderer.addClass(
			this.hostElement.nativeElement,
			`eui-icon-btn__${color}`,
		);

		this.wrappedColor = color;
	}

	@Input() set size(size: IconButtonSize) {
		this.renderer.addClass(
			this.hostElement.nativeElement,
			`eui-icon-btn__${size}`,
		);
	}

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {}
}
