import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-card',
	template: `<div class="eui-card" [ngStyle]="{ padding: padding }">
		<ng-content></ng-content>
	</div>`,
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
	@Input() padding = '24px';
}
