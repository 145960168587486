import { NgModule } from '@angular/core';

import {
	ButtonModule,
	CheckboxModule,
	IconButtonModule,
	ToggleModule,
	PaginatorModule,
	TabsModule,
	InputModule,
	InputNumberModule,
	ProgressBarModule,
	TooltipModule,
	DialogModule,
	BadgeModule,
	BreadcrumbsModule,
	DatepickerModule,
	DropdownModule,
	AvatarModule,
	TableModule,
	RadioModule,
	MenuModule,
	IconsModule,
	DividerModule,
	CardModule,
	LoadingSpinnerModule,
	ActionButtonModule,
	SideMenuModule,
	DotModule,
	IconToggleModule,
	SidePageModule,
	SkeletonLoaderModule,
	SpinnerLoaderModule,
	ExpandButtonModule,
	ToastModule,
} from './components';

import {
	ClickOutsideModule,
	DigitOnlyModule,
	SortModule,
	TrimInputModule,
} from './directives';

import { FilterModule } from './pipes';

const MODULES = [
	ButtonModule,
	CheckboxModule,
	IconButtonModule,
	ToggleModule,
	PaginatorModule,
	TabsModule,
	InputModule,
	InputNumberModule,
	ProgressBarModule,
	TooltipModule,
	DialogModule,
	BadgeModule,
	BreadcrumbsModule,
	DatepickerModule,
	DropdownModule,
	AvatarModule,
	TableModule,
	RadioModule,
	MenuModule,
	IconsModule,
	DividerModule,
	CardModule,
	LoadingSpinnerModule,
	ActionButtonModule,
	SideMenuModule,
	DotModule,
	ClickOutsideModule,
	DigitOnlyModule,
	TrimInputModule,
	SortModule,
	FilterModule,
	IconToggleModule,
	SidePageModule,
	SkeletonLoaderModule,
	SpinnerLoaderModule,
	ExpandButtonModule,
	ToastModule,
];

@NgModule({
	imports: [...MODULES],
	exports: [...MODULES],
})
export class UIKitModule {}
