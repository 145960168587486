import { EuiIcon } from '../types/icon.interface';

export const share: EuiIcon = {
	name: 'share',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47077 6.78125C5.76366 7.07415 6.23853 7.07415 6.53143 6.78126L9.25013 4.06259L9.25012 12.255C9.25012 12.6692 9.58591 13.005 10.0001 13.005C10.4143 13.005 10.7501 12.6692 10.7501 12.255L10.7501 4.06229L13.4686 6.78077C13.7615 7.07366 14.2364 7.07366 14.5293 6.78077C14.8222 6.48787 14.8222 6.013 14.5293 5.72011L10.5306 1.72145C10.2377 1.42856 9.76285 1.42856 9.46995 1.72145L5.47077 5.72059C5.17788 6.01349 5.17787 6.48836 5.47077 6.78125ZM4.50147 11.75C4.50147 11.3358 4.16568 11 3.75147 11C3.33725 11 3.00147 11.3358 3.00147 11.75V12.8045C3.00146 13.5615 3.00145 14.1752 3.0421 14.6727C3.08404 15.186 3.17295 15.6415 3.38839 16.0643C3.72874 16.7323 4.27182 17.2753 4.9398 17.6157C5.36263 17.8311 5.81807 17.92 6.33136 17.962C6.82893 18.0026 7.44258 18.0026 8.19961 18.0026H11.8034C12.5605 18.0026 13.1741 18.0026 13.6717 17.962C14.185 17.92 14.6404 17.8311 15.0633 17.6157C15.7312 17.2753 16.2743 16.7323 16.6147 16.0643C16.8301 15.6415 16.919 15.186 16.9609 14.6727C17.0016 14.1752 17.0016 13.5615 17.0016 12.8045V11.75C17.0016 11.3358 16.6658 11 16.2516 11C15.8374 11 15.5016 11.3358 15.5016 11.75V12.7726C15.5016 13.5691 15.501 14.1213 15.4659 14.5506C15.4316 14.9711 15.3679 15.2072 15.2782 15.3833C15.0816 15.769 14.768 16.0826 14.3823 16.2792C14.2061 16.3689 13.9701 16.4326 13.5495 16.467C13.1203 16.502 12.568 16.5026 11.7716 16.5026H8.23146C7.43502 16.5026 6.88279 16.502 6.4535 16.467C6.03298 16.4326 5.79691 16.3689 5.62078 16.2792C5.23505 16.0826 4.92144 15.769 4.7249 15.3833C4.63516 15.2072 4.57148 14.9711 4.53712 14.5506C4.50205 14.1213 4.50147 13.5691 4.50147 12.7726V11.75Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47077 6.78125C5.76366 7.07415 6.23853 7.07415 6.53143 6.78126L9.25013 4.06259L9.25012 12.255C9.25012 12.6692 9.58591 13.005 10.0001 13.005C10.4143 13.005 10.7501 12.6692 10.7501 12.255L10.7501 4.06229L13.4686 6.78077C13.7615 7.07366 14.2364 7.07366 14.5293 6.78077C14.8222 6.48787 14.8222 6.013 14.5293 5.72011L10.5306 1.72145C10.2377 1.42856 9.76285 1.42856 9.46995 1.72145L5.47077 5.72059C5.17788 6.01349 5.17787 6.48836 5.47077 6.78125ZM4.50147 11.75C4.50147 11.3358 4.16568 11 3.75147 11C3.33725 11 3.00147 11.3358 3.00147 11.75V12.8045C3.00146 13.5615 3.00145 14.1752 3.0421 14.6727C3.08404 15.186 3.17295 15.6415 3.38839 16.0643C3.72874 16.7323 4.27182 17.2753 4.9398 17.6157C5.36263 17.8311 5.81807 17.92 6.33136 17.962C6.82893 18.0026 7.44258 18.0026 8.19961 18.0026H11.8034C12.5605 18.0026 13.1741 18.0026 13.6717 17.962C14.185 17.92 14.6404 17.8311 15.0633 17.6157C15.7312 17.2753 16.2743 16.7323 16.6147 16.0643C16.8301 15.6415 16.919 15.186 16.9609 14.6727C17.0016 14.1752 17.0016 13.5615 17.0016 12.8045V11.75C17.0016 11.3358 16.6658 11 16.2516 11C15.8374 11 15.5016 11.3358 15.5016 11.75V12.7726C15.5016 13.5691 15.501 14.1213 15.4659 14.5506C15.4316 14.9711 15.3679 15.2072 15.2782 15.3833C15.0816 15.769 14.768 16.0826 14.3823 16.2792C14.2061 16.3689 13.9701 16.4326 13.5495 16.467C13.1203 16.502 12.568 16.5026 11.7716 16.5026H8.23146C7.43502 16.5026 6.88279 16.502 6.4535 16.467C6.03298 16.4326 5.79691 16.3689 5.62078 16.2792C5.23505 16.0826 4.92144 15.769 4.7249 15.3833C4.63516 15.2072 4.57148 14.9711 4.53712 14.5506C4.50205 14.1213 4.50147 13.5691 4.50147 12.7726V11.75Z" fill="#262626"/>
  </svg>
  `,
};
