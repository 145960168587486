import { EuiIcon } from '../types/icon.interface';

export const checkCircle: EuiIcon = {
	name: 'check-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.2802 8.78033C13.5731 8.48744 13.5731 8.01256 13.2802 7.71967C12.9873 7.42678 12.5124 7.42678 12.2195 7.71967L8.99988 10.9393L7.78021 9.71967C7.48732 9.42678 7.01244 9.42678 6.71955 9.71967C6.42665 10.0126 6.42665 10.4874 6.71955 10.7803L8.46955 12.5303C8.76244 12.8232 9.23731 12.8232 9.53021 12.5303L13.2802 8.78033Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4999 10C18.4999 14.6944 14.6943 18.5 9.99988 18.5C5.30546 18.5 1.49988 14.6944 1.49988 10C1.49988 5.30558 5.30546 1.5 9.99988 1.5C14.6943 1.5 18.4999 5.30558 18.4999 10ZM16.9999 10C16.9999 13.866 13.8659 17 9.99988 17C6.13388 17 2.99988 13.866 2.99988 10C2.99988 6.13401 6.13388 3 9.99988 3C13.8659 3 16.9999 6.13401 16.9999 10Z" fill="#262626"/>
  </svg>
  `,
};
