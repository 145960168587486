import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DatepickerCoreComponent } from './datepicker-core.component';
import { DatepickerDirective } from './datepicker.directive';
import { LOCALE_CONFIG } from './datepicker.config';
import { LocaleService } from './locale.service';
import { DatepickerComponent } from './datepicker.component';
import { InputModule } from '../input';
import { ButtonModule } from '../button';
import { IconsModule } from '../icons';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		ButtonModule,
		IconsModule,
	],
	exports: [DatepickerComponent, DatepickerCoreComponent, DatepickerDirective],
	declarations: [
		DatepickerCoreComponent,
		DatepickerDirective,
		DatepickerComponent,
	],
	providers: [
		{ provide: LOCALE_CONFIG, useValue: {} },
		{
			provide: LocaleService,
			useClass: LocaleService,
			deps: [LOCALE_CONFIG],
		},
	],
})
export class DatepickerModule {}
