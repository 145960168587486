import { EuiIcon } from '../types/icon.interface';

export const mrmCircle: EuiIcon = {
	name: 'mrm-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z" fill="#5295FF"/>
<path d="M9.42923 12.6723C9.52973 12.7036 9.63021 12.7256 9.73071 12.7381C9.83122 12.7444 9.93173 12.7475 10.0322 12.7475C10.2835 12.7475 10.5253 12.713 10.7577 12.644C10.9901 12.575 11.2069 12.4778 11.4079 12.3523C11.6152 12.2206 11.7973 12.0638 11.9543 11.8818C12.1177 11.6936 12.2496 11.4866 12.3501 11.2608L14.2345 13.1522C13.9958 13.4909 13.7194 13.7952 13.4053 14.0649C13.0976 14.3347 12.7615 14.5636 12.3972 14.7518C12.0391 14.94 11.6591 15.0812 11.2571 15.1753C10.8614 15.2757 10.4531 15.3258 10.0322 15.3258C9.32244 15.3258 8.65345 15.1941 8.02531 14.9306C7.40346 14.6672 6.85699 14.3002 6.38588 13.8297C5.92106 13.3592 5.55359 12.8009 5.28349 12.1547C5.01339 11.5023 4.87835 10.7872 4.87835 10.0093C4.87835 9.21257 5.01339 8.48487 5.28349 7.82618C5.55359 7.16749 5.92106 6.60604 6.38588 6.14182C6.85699 5.6776 7.40346 5.31689 8.02531 5.05968C8.65345 4.80248 9.32244 4.67388 10.0322 4.67388C10.4531 4.67388 10.8645 4.72406 11.2665 4.82444C11.6685 4.92481 12.0486 5.06909 12.4066 5.25729C12.7709 5.44549 13.1101 5.6776 13.4242 5.95362C13.7383 6.22337 14.0147 6.52762 14.2533 6.86638L9.42923 12.6723ZM10.7483 7.37451C10.629 7.33059 10.5096 7.30237 10.3903 7.28982C10.2772 7.27727 10.1579 7.271 10.0322 7.271C9.68048 7.271 9.34756 7.33687 9.03349 7.46861C8.7257 7.59407 8.45559 7.776 8.22318 8.01438C7.99705 8.25276 7.81805 8.54133 7.68614 8.88008C7.55423 9.21256 7.48827 9.58896 7.48827 10.0093C7.48827 10.1034 7.4914 10.21 7.49768 10.3292C7.51024 10.4484 7.52596 10.5707 7.5448 10.6962C7.56993 10.8154 7.59821 10.9314 7.62961 11.0444C7.66102 11.1573 7.70182 11.2576 7.75208 11.3455L10.7483 7.37451Z" fill="white"/>
</svg>
`,
};
