import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'eui-dropdown-options-search',
	template: `
		<div class="eui-dropdown__options-search">
			<eui-input-group>
				<eui-icons name="search" [size]="20"></eui-icons>

				<input
					[formControl]="formControl"
					[placeholder]="placeholder"
					type="text" />
			</eui-input-group>
		</div>
	`,
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionsSearchComponent implements OnDestroy {
	@Input() placeholder = 'Найти';
	@Output() valueChange = new EventEmitter<string>();

	formControl = new FormControl();
	private destroy$ = new Subject();

	constructor(public readonly cdr: ChangeDetectorRef) {
		this.formControl.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
			.subscribe((value) => {
				this.valueChange.emit(value);
			});
	}

	ngOnDestroy() {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
