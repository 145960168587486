import { EuiIcon } from '../types/icon.interface';

export const noteText: EuiIcon = {
	name: 'note-text',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 13.25C15 13.6642 14.6642 14 14.25 14L5.75 14C5.33579 14 5 13.6642 5 13.25C5 12.8358 5.33579 12.5 5.75 12.5L14.25 12.5C14.6642 12.5 15 12.8358 15 13.25Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 9.74999C15 10.1642 14.6642 10.5 14.25 10.5L5.75 10.5C5.33579 10.5 5 10.1642 5 9.74998C5 9.33577 5.33579 8.99998 5.75 8.99998L14.25 8.99999C14.6642 8.99999 15 9.33577 15 9.74999Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8722 18C14.4825 18 15.2124 17.8622 15.9537 17.4657C16.6036 17.1181 17.1182 16.6036 17.4657 15.9537C17.8622 15.2124 18 14.4825 18 12.8722V7.12776C18 5.51748 17.8622 4.78762 17.4657 4.04629C17.1182 3.39637 16.6036 2.88183 15.9537 2.53425C15.2124 2.13779 14.4825 1.99999 12.8722 1.99999L7.12777 1.99998C5.5175 1.99998 4.78763 2.13779 4.04631 2.53425C3.39639 2.88183 2.88185 3.39637 2.53427 4.04629C2.1378 4.78762 2 5.51748 2 7.12775L2 12.8722C2 14.4825 2.1378 15.2124 2.53427 15.9537C2.88185 16.6036 3.39639 17.1181 4.04631 17.4657C4.78763 17.8622 5.51749 18 7.12777 18H12.8722ZM15.2463 16.143C14.8336 16.3637 14.4032 16.5 12.8722 16.5L7.12777 16.5C5.59677 16.5 5.16644 16.3637 4.75371 16.143C4.36519 15.9352 4.06477 15.6348 3.85699 15.2463C3.63625 14.8335 3.5 14.4032 3.5 12.8722V7.12775C3.5 5.59675 3.63625 5.16643 3.85699 4.75369C4.06477 4.36518 4.36519 4.06475 4.75371 3.85697C5.10347 3.66991 5.46586 3.54353 6.5 3.50932L6.5 6.24998C6.5 6.6642 6.83579 6.99998 7.25 6.99998C7.66421 6.99998 8 6.6642 8 6.24998L8 3.49998L12 3.49999V6.24999C12 6.6642 12.3358 6.99999 12.75 6.99999C13.1642 6.99999 13.5 6.6642 13.5 6.24999V3.50932C14.5341 3.54353 14.8965 3.66992 15.2463 3.85697C15.6348 4.06475 15.9352 4.36518 16.143 4.75369C16.3638 5.16643 16.5 5.59675 16.5 7.12776V12.8722C16.5 14.4032 16.3637 14.8335 16.143 15.2463C15.9352 15.6348 15.6348 15.9352 15.2463 16.143ZM14.25 14C14.6642 14 15 13.6642 15 13.25C15 12.8358 14.6642 12.5 14.25 12.5L5.75 12.5C5.33579 12.5 5 12.8358 5 13.25C5 13.6642 5.33579 14 5.75 14L14.25 14ZM14.25 10.5C14.6642 10.5 15 10.1642 15 9.74999C15 9.33577 14.6642 8.99999 14.25 8.99999L5.75 8.99998C5.33579 8.99998 5 9.33577 5 9.74998C5 10.1642 5.33579 10.5 5.75 10.5L14.25 10.5Z" fill="#262626"/>
</svg>
`,
};
