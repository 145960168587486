import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'eui-menu-item',
	template: `<li
		class="eui-menu__item"
		[class.eui-menu__item-active]="active"
		[class.eui-menu__item-disabled]="disabled"
		[ngClass]="color ? 'eui-menu__item-' + color : ''"
		(click)="onClick()">
		<div class="eui-menu__item-container">
			<span>
				<eui-icons *ngIf="icon" [name]="icon" [size]="20"></eui-icons>
				{{ title }}
			</span>

			<eui-icons *ngIf="submenu" name="chevron-right" [size]="20"></eui-icons>
		</div>

		<ng-content></ng-content>
	</li>`,
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class MenuItemComponent {
	@Input() active = false;
	@Input() icon = '';
	@Input() title = 'Some';
	@Input() color = '';
	@Input() submenu = false;
	@Input() disabled = false;
	@Output() itemClick = new EventEmitter();

	constructor(public readonly elementRef: ElementRef) {}

	onClick() {
		this.itemClick.emit();
	}
}
