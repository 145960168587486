<div class="eui-pagination" [ngStyle]="{ 'justify-content': align }">
	<ng-container *ngIf="showPageSizeOptions">
		<div class="eui-pagination__options">
			<div class="eui-pagination__options-title">
				{{ pageSizeOptionsLabel }}:
			</div>

			<ng-template #customPageSizeOptionTemplate let-option>
				<div class="eui-pagination__options__dropdown-menu">
					{{ option }}
				</div>
			</ng-template>


			<ng-template #customPageSizeTriggerTemplate let-value let-opened="opened">
				<div class="eui-pagination__options__trigger">
					<div class="eui-pagination__options__trigger-value">
						{{ value }}
					</div>

					<ng-container *ngIf="opened; else chevronDown">
						<eui-icons name="chevron-up" [size]="16"></eui-icons>
					</ng-container>

					<ng-template #chevronDown>
						<eui-icons name="chevron-down" [size]="16"></eui-icons>
					</ng-template>
				</div>
			</ng-template>

			<div class="eui-pagination__options-list">
				<eui-dropdown
					[optionsFitContent]="true"
					[options]="pageSizeOptions"
					[customTriggerTemplateRef]="customPageSizeTriggerTemplate"
					[customOptionsTemplateRef]="customPageSizeOptionTemplate"
					[(ngModel)]="pageSizeModel"
					(ngModelChange)="pageSizeChangeHandler($event)"></eui-dropdown>
			</div>
		</div>
	</ng-container>

	<div
		class="eui-pagination__nav"
		[ngClass]="[fullWidth ? 'eui-pagination__nav-full-width' : '']"
		*ngIf="paginationRange.length >= minPageToShowPagination">
		<div class="eui-pagination__pages">
			<button
				class="eui-pagination__pages-item eui-pagination__pages-item-arrow"
				[ngClass]="{ 'eui-pagination__pages-item-no-label': !previousLabel }"
				(click)="previousPageHandler()"
				[disabled]="currentPage === 1">
				<eui-icons name="chevron-left" [size]="20"></eui-icons>
				{{ previousLabel }}
			</button>
		</div>

		<div class="eui-pagination__pages">
			<button
				class="eui-pagination__pages-item"
				[ngClass]="{ active: currentPage === page }"
				[disabled]="currentPage === page || page === dots"
				*ngFor="let page of paginationRange; trackBy: trackByFn"
				(click)="pageChangeHandler(page)">
				{{ page }}
			</button>
		</div>

		<div class="eui-pagination__pages">
			<button
				class="eui-pagination__pages-item eui-pagination__pages-item-arrow"
				[ngClass]="{ 'eui-pagination__pages-item-no-label': !nextLabel }"
				(click)="nextPageHandler()"
				[disabled]="currentPage === lastPage">
				{{ nextLabel }}
				<eui-icons name="chevron-right" [size]="20"></eui-icons>
			</button>
		</div>
	</div>
</div>
