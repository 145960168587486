import { EuiIcon } from '../types/icon.interface';

export const hideBox: EuiIcon = {
	name: 'hide-box',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.2461 9.25C13.6604 9.25 13.9961 9.58582 13.9961 10C13.9961 10.4142 13.6604 10.75 13.2461 10.75H6.75391C6.3396 10.75 6.00391 10.4142 6.00391 10C6.00391 9.58582 6.3396 9.25 6.75391 9.25H13.2461Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8.40002C2 6.15979 2 5.03967 2.43604 4.18402C2.81958 3.4314 3.4314 2.81946 4.18408 2.43597C5.03979 2 6.15967 2 8.3999 2H11.6001C13.8401 2 14.9602 2 15.8159 2.43597C16.5686 2.81946 17.1804 3.4314 17.564 4.18402C18 5.03967 18 6.15979 18 8.40002V11.6C18 13.8402 18 14.9603 17.564 15.816C17.1804 16.5686 16.5686 17.1805 15.8159 17.564C14.9602 18 13.8401 18 11.6001 18H8.3999C6.15991 18 5.03979 18 4.18408 17.564C3.4314 17.1805 2.81958 16.5686 2.43604 15.816C2 14.9603 2 13.8402 2 11.6V8.40002ZM8.3999 3.5H11.6001C12.7449 3.5 13.5132 3.50116 14.105 3.5495C14.6792 3.59644 14.9539 3.68018 15.135 3.77246C15.6055 4.01215 15.9878 4.39459 16.2275 4.86505C16.3198 5.04614 16.4036 5.32068 16.4504 5.89496C16.4988 6.48669 16.5 7.25513 16.5 8.40002V11.6C16.5 12.7449 16.4988 13.5133 16.4504 14.105C16.4185 14.4976 16.3691 14.7501 16.3118 14.9285C16.2852 15.011 16.2568 15.0776 16.2275 15.1349C15.9878 15.6054 15.6055 15.9879 15.135 16.2275C15.0435 16.2742 14.9277 16.3187 14.7617 16.3574C14.5994 16.3951 14.3887 16.4273 14.105 16.4505C13.5132 16.4988 12.7449 16.5 11.6001 16.5H8.3999C7.25513 16.5 6.48682 16.4988 5.89502 16.4505C5.3208 16.4036 5.04614 16.3198 4.86499 16.2275C4.39453 15.9879 4.01221 15.6054 3.77246 15.1349C3.68018 14.9539 3.59644 14.6793 3.54956 14.105C3.50122 13.5133 3.5 12.7449 3.5 11.6V8.40002C3.5 7.25513 3.50122 6.48669 3.54956 5.89496C3.59644 5.32068 3.68018 5.04614 3.77246 4.86505C4.01221 4.39459 4.39453 4.01215 4.86499 3.77246C5.04614 3.68018 5.3208 3.59644 5.89502 3.5495C6.48682 3.50116 7.25513 3.5 8.3999 3.5Z" fill="#262626"/>
  </svg>
  `,
};
