<div class="eui-datepicker-wrapper" (click)="open($event)">
	<eui-input-label *ngIf="label">{{ label }}</eui-input-label>

	<eui-input-group>
		<input
			[alwaysShowCalendars]="true"
			[formControl]="formControl"
			[isInvalidDate]="isInvalidDate"
			[linkedCalendars]="false"
			[opens]="position"
			[placeholder]="placeholder"
			[ranges]="!single ? ranges : null"
			[showCancel]="true"
			[showClearButton]="!single"
			[showCustomRangeLabel]="!single"
			[showDropdowns]="true"
			[singleDatePicker]="single"
			[showWeekNumbers]="showWeekNumbers"
			[timePicker]="timePicker"
			[timePicker24Hour]="true"
			[minDate]="minDate"
			euiDatepicker
			readonly
			autocomplete="off"
			type="text" />

		<eui-icons name="calendar" [size]="20"></eui-icons>
	</eui-input-group>
</div>
