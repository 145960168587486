import { EuiIcon } from '../types/icon.interface';

export const infoOutline: EuiIcon = {
	name: 'info-outline',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.25 6.25C9.25 5.83579 9.58579 5.5 10 5.5C10.4142 5.5 10.75 5.83579 10.75 6.25V10.75C10.75 11.1642 10.4142 11.5 10 11.5C9.58579 11.5 9.25 11.1642 9.25 10.75V6.25Z" fill="#262626"/>
<path d="M9.99998 13C9.50292 13 9.09998 13.4029 9.09998 13.9C9.09998 14.3971 9.50292 14.8 9.99998 14.8C10.497 14.8 10.9 14.3971 10.9 13.9C10.9 13.4029 10.497 13 9.99998 13Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5ZM3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10Z" fill="#262626"/>
</svg>
`,
};
