import { EuiIcon } from '../types/icon.interface';

export const clock: EuiIcon = {
	name: 'clock',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 6C10.1642 6 10.5 6.33579 10.5 6.75V10.0365L13.5854 11.5792C13.9559 11.7644 14.1061 12.2149 13.9208 12.5854C13.7356 12.9559 13.2851 13.1061 12.9146 12.9208L9.41459 11.1708C9.1605 11.0438 9 10.7841 9 10.5V6.75C9 6.33579 9.33579 6 9.75 6Z" fill="#262626"/>
</svg>
`,
};
