import { EuiIcon } from '../types/icon.interface';

export const users: EuiIcon = {
	name: 'users',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12964 6.5554C9.12964 8.28487 7.83862 9.68803 6.25464 9.68803C4.67065 9.68803 3.37964 8.29373 3.37817 6.56908C3.37061 4.88875 4.6748 3.5 6.25464 3.5C7.84424 3.5 9.12964 4.86891 9.12964 6.5554ZM4.66504 6.56908C4.66504 7.5899 5.38965 8.40927 6.25464 8.40927C7.12085 8.40927 7.85034 7.5899 7.85034 6.5554C7.85034 5.56523 7.13403 4.78012 6.25464 4.78012C5.38623 4.78012 4.66235 5.57755 4.66504 6.56908Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.68774 11.5691C3.64795 11.0152 4.91382 10.75 6.25 10.75C7.58618 10.75 8.85205 11.0152 9.81226 11.5691C10.7891 12.1327 11.5 13.037 11.5 14.25C11.5 14.9572 11.2244 15.5559 10.7185 15.9606C10.2351 16.3474 9.6145 16.5 9 16.5H3.5C2.8855 16.5 2.26489 16.3474 1.78149 15.9606C1.27563 15.5559 1 14.9572 1 14.25C1 13.037 1.71094 12.1327 2.68774 11.5691ZM3.43726 12.8684C2.78906 13.2423 2.5 13.713 2.5 14.25C2.5 14.5428 2.59937 14.6941 2.71851 14.7894C2.86011 14.9026 3.1145 15 3.5 15H9C9.3855 15 9.63989 14.9026 9.78149 14.7894C9.90063 14.6941 10 14.5428 10 14.25C10 13.713 9.71094 13.2423 9.06274 12.8684C8.39795 12.4848 7.41382 12.25 6.25 12.25C5.08618 12.25 4.10205 12.4848 3.43726 12.8684Z" fill="#262626"/>
  <path d="M13.75 12.25L13.6404 12.2509C13.5881 12.2517 13.5359 12.2531 13.4836 12.2551C13.3462 12.2604 13.209 12.2697 13.0732 12.283C12.6609 12.3235 12.2939 12.0221 12.2537 11.6098C12.2131 11.1976 12.5146 10.8306 12.9268 10.7902C13.1987 10.7635 13.4741 10.75 13.75 10.75C15.0862 10.75 16.3521 11.0152 17.3123 11.5691C18.2891 12.1327 19 13.037 19 14.25C19 14.9572 18.7244 15.5559 18.2185 15.9606C17.7351 16.3474 17.1145 16.5 16.5 16.5H13.5C13.0857 16.5 12.75 16.1642 12.75 15.75C12.75 15.3358 13.0857 15 13.5 15H16.5C16.8855 15 17.1399 14.9026 17.2815 14.7894C17.4006 14.6941 17.5 14.5428 17.5 14.25C17.5 13.713 17.2109 13.2423 16.5627 12.8684C15.8979 12.4848 14.9138 12.25 13.75 12.25Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7441 9.68803C15.3281 9.68803 16.6191 8.28487 16.6191 6.5554C16.6191 4.86891 15.3337 3.5 13.7441 3.5C12.1643 3.5 10.8601 4.88875 10.8677 6.56908C10.8691 8.29373 12.1602 9.68803 13.7441 9.68803ZM13.7441 8.40927C12.8792 8.40927 12.1545 7.5899 12.1545 6.56908C12.1519 5.57755 12.8757 4.78012 13.7441 4.78012C14.6235 4.78012 15.3398 5.56523 15.3398 6.5554C15.3398 7.5899 14.6104 8.40927 13.7441 8.40927Z" fill="#262626"/>
  </svg>
  `,
};
