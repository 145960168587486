import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Input,
} from '@angular/core';

import { DropdownOptionsSearchComponent } from './dropdown-options-search.component';

@Component({
	selector: 'eui-dropdown-options',
	template: `
		<div
			class="eui-dropdown__options"
			[ngClass]="{ 'eui-dropdown__options-fit-content': optionsFitContent }">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./dropdown.component.scss', './dropdown-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionsComponent {
	@ContentChild(DropdownOptionsSearchComponent)
	searchComponent!: DropdownOptionsSearchComponent;

	@Input() optionsFitContent = false;
}
