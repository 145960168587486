import { EuiIcon } from '../types/icon.interface';

export const download: EuiIcon = {
	name: 'download',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 16.6862C4 16.272 4.33579 15.9362 4.75 15.9362H15.25C15.6642 15.9362 16 16.272 16 16.6862C16 17.1004 15.6642 17.4362 15.25 17.4362H4.75C4.33579 17.4362 4 17.1004 4 16.6862Z" fill="#262626"/>
  <path d="M10.7493 3.2896C10.7493 2.88875 10.4138 2.56379 9.99999 2.56379C9.58614 2.56379 9.25066 2.88875 9.25066 3.2896L9.25065 12.132L5.28033 8.16168C4.98744 7.86879 4.51256 7.86879 4.21967 8.16168C3.92678 8.45458 3.92678 8.92945 4.21967 9.22235L9.46499 14.4677C9.75789 14.7606 10.2328 14.7606 10.5257 14.4677L15.7815 9.21182C16.0744 8.91893 16.0744 8.44405 15.7815 8.15116C15.4886 7.85827 15.0138 7.85827 14.7209 8.15116L10.7493 12.1227L10.7493 3.2896Z" fill="#262626"/>
  </svg>
  `,
};
