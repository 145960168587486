import { SortOrder } from './sort-order.type';

export class Sort {
	private sortOrder = 1;

	private collator = new Intl.Collator(undefined, {
		numeric: true,
		sensitivity: 'base',
	});

	public startSort(property: string, order: SortOrder, type = '') {
		if (order === SortOrder.Desc) {
			this.sortOrder = -1;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return (a: any, b: any) => {
			const aProp = property.split('.').reduce((prev, cur) => prev[cur], a);
			const bProp = property.split('.').reduce((prev, cur) => prev[cur], b);

			switch (type) {
				case 'date':
					return this.sortDate(new Date(aProp), new Date(bProp));
				default:
					return this.collator.compare(aProp, bProp) * this.sortOrder;
			}
		};
	}

	private sortDate(a: Date, b: Date) {
		if (a < b) {
			return -1 * this.sortOrder;
		} else if (a > b) {
			return this.sortOrder;
		} else {
			return 0;
		}
	}
}
