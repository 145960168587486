import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'eui-avatar',
	template: `
		<div class="eui-avatar" #avatar>
			<div
				#onlineStatus
				*ngIf="onlineStatus !== 'none'"
				class="eui-avatar__online-indicator"></div>

			<ng-container *ngIf="avatarUrl; else noAvatar">
				<img class="eui-avatar__img" [src]="avatarUrl" alt="avatar" />
			</ng-container>

			<ng-template #noAvatar>
				<eui-user-no-avatar [size]="size"></eui-user-no-avatar>
			</ng-template>
		</div>
	`,
	styleUrls: ['./avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements AfterViewInit {
	@Input() size = 'sm';
	@Input() avatarUrl: string | undefined | null = '';
	@Input() onlineStatus = 'none';
	@Input() color = '';

	@ViewChild('avatar') avatarRef!: ElementRef;
	@ViewChild('onlineStatus') onlineStatusRef!: ElementRef;

	constructor(private renderer: Renderer2) {}

	ngAfterViewInit(): void {
		this.renderer.addClass(
			this.avatarRef.nativeElement,
			`eui-avatar__${this.size}`,
		);

		if (this.onlineStatus !== 'none') {
			this.renderer.addClass(
				this.onlineStatusRef.nativeElement,
				`eui-avatar__online-indicator__${this.onlineStatus}__${this.size}`,
			);
		}

		if (this.color) {
			this.renderer.addClass(
				this.avatarRef.nativeElement,
				`eui-avatar__${this.color}`,
			);
		}
	}
}
