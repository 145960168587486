import { EuiIcon } from '../types/icon.interface';

export const notificationBellSplash: EuiIcon = {
	name: 'notification-bell-splash',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.05696 8.30707C6.01357 8.66225 5.98554 9.05351 5.97346 9.48217C5.89437 11.0144 5.6214 12.1765 5.06132 12.8137C4.65073 13.2675 4.17895 13.7002 3.9688 13.93V14.0239H11.8863L13.3918 15.5003H13.1942C13.1299 17.0593 11.844 18.4622 9.98049 18.4622C8.1087 18.4622 6.82428 17.0527 6.7585 15.5003H3.3747C2.52637 15.5003 2 15.0488 2 14.365C2 13.5067 2.80608 12.7714 3.55098 12.0659C4.13062 11.5102 4.26448 10.3972 4.37021 9.28566C4.40073 8.42003 4.49487 7.63596 4.66559 6.94256L6.05696 8.30707ZM8.30897 15.5003C8.37179 16.5183 9.04336 17.1668 9.98049 17.1668C10.9094 17.1668 11.5878 16.5183 11.6437 15.5003H8.30897ZM12.4292 3.42563C14.5792 4.18617 15.4747 6.2267 15.5893 9.28566C15.6883 10.3972 15.8288 11.5102 16.4085 12.0659C17.1534 12.7714 17.9528 13.5067 17.9528 14.365C17.9528 14.6527 17.861 14.8993 17.69 15.0875L14.3632 11.8225C14.152 11.2017 14.034 10.4098 13.9861 9.48217C13.8868 6.20247 12.861 5.1122 11.5364 4.75599C11.3368 4.70901 11.2217 4.61648 11.2061 4.39204C11.1681 3.57271 10.6999 3.01967 9.98049 3.01967C9.25285 3.01967 8.79144 3.57271 8.74671 4.39204C8.73109 4.61648 8.62421 4.70901 8.4231 4.75599C8.11486 4.83847 7.82299 4.9603 7.55637 5.14212L6.41085 4.01787C6.73762 3.7718 7.11016 3.57426 7.53038 3.42563C7.84395 2.34908 8.75181 1.53784 9.98049 1.53784C11.201 1.53784 12.1155 2.34908 12.4292 3.42563Z" fill="#262626"/>
<path d="M2.34548 3.25818L17.2748 17.9007C17.5304 18.1568 17.9532 18.1568 18.2005 17.9007C18.4531 17.6498 18.4562 17.2477 18.2005 16.9916L3.28096 2.34908C3.02543 2.09815 2.60776 2.09815 2.34548 2.34908C2.09964 2.59048 2.09964 3.01532 2.34548 3.25818Z" fill="#262626"/>
</svg>
`,
};
