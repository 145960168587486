import { EuiIcon } from '../types/icon.interface';

export const avito: EuiIcon = {
	name: 'avito',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8261 18.3333C16.3153 18.3333 18.3333 16.3153 18.3333 13.8261C18.3333 11.3368 16.3153 9.31887 13.8261 9.31887C11.3368 9.31887 9.31887 11.3368 9.31887 13.8261C9.31887 16.3153 11.3368 18.3333 13.8261 18.3333Z" fill="#97CF26"/>
<path d="M5.37256 15.9294C6.53421 15.9294 7.47592 14.9877 7.47592 13.8261C7.47592 12.6644 6.53421 11.7227 5.37256 11.7227C4.2109 11.7227 3.26919 12.6644 3.26919 13.8261C3.26919 14.9877 4.2109 15.9294 5.37256 15.9294Z" fill="#A169F7"/>
<path d="M13.8261 8.25717C15.4192 8.25717 16.7107 6.96568 16.7107 5.37256C16.7107 3.77943 15.4192 2.48794 13.8261 2.48794C12.233 2.48794 10.9415 3.77943 10.9415 5.37256C10.9415 6.96568 12.233 8.25717 13.8261 8.25717Z" fill="#FF6163"/>
<path d="M5.37256 9.07848C7.41928 9.07848 9.07848 7.41928 9.07848 5.37256C9.07848 3.32583 7.41928 1.66663 5.37256 1.66663C3.32583 1.66663 1.66663 3.32583 1.66663 5.37256C1.66663 7.41928 3.32583 9.07848 5.37256 9.07848Z" fill="#00AAFF"/>
</svg>
`,
};
