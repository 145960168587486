import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
} from '@angular/core';
import { ActionButtonColor } from './types/action-button.model';

@Component({
	selector: 'eui-action-button, button[euiActionButton], a[euiActionButton]',
	template: '<ng-content></ng-content>',
	styleUrls: ['./action-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent {
	@Input()
	set color(color: ActionButtonColor) {
		this.renderer.addClass(
			this.hostElement.nativeElement,
			`eui-action-btn__${color}`,
		);
	}

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {}
}
