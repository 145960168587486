import { EuiIcon } from '../types/icon.interface';

export const userMinus: EuiIcon = {
	name: 'user-minus',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00085 8.66949C9.72986 8.66949 11.1388 7.13794 11.1388 5.25018C11.1388 3.40933 9.73572 1.91516 8.00085 1.91516C6.27649 1.91516 4.85291 3.431 4.86096 5.26511C4.86267 7.14761 6.27185 8.66949 8.00085 8.66949ZM8.00085 7.27371C7.05652 7.27371 6.26575 6.37936 6.26575 5.26511C6.26282 4.18283 7.05286 3.31244 8.00085 3.31244C8.96082 3.31244 9.74255 4.1694 9.74255 5.25018C9.74255 6.37936 8.94641 7.27371 8.00085 7.27371Z" fill="#262626"/>
  <path d="M7.99988 9.94562C4.495 9.94562 1.69104 11.2201 1.69104 14.1515C1.69104 15.5212 2.61194 16.2544 3.79407 16.2544H9.80261C9.61218 15.8599 9.47229 15.4365 9.39099 14.9926H3.79407C3.25354 14.9926 2.95276 14.7531 2.95276 14.1515C2.95276 12.1887 5.11169 11.2074 7.99988 11.2074C8.75305 11.2074 9.45642 11.2741 10.0851 11.4076C10.3407 11.0052 10.6522 10.6419 11.0087 10.3285C10.1163 10.0694 9.09192 9.94562 7.99988 9.94562Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3087 14.0834C18.3087 16.2656 16.4796 18.0848 14.3087 18.0848C12.1136 18.0848 10.3087 16.2784 10.3087 14.0834C10.3087 11.8898 12.1136 10.0848 14.3087 10.0848C16.4987 10.0848 18.3087 11.8898 18.3087 14.0834ZM11.7706 14.0848C11.7721 14.3642 12.0148 14.6134 12.3043 14.6134H16.316C16.6005 14.6134 16.8419 14.3642 16.8419 14.0848C16.8419 13.7989 16.6005 13.5575 16.316 13.5575H12.3043C12.0148 13.5575 11.7692 13.8003 11.7706 14.0848Z" fill="#262626"/>
  </svg>
  `,
};
