import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-loading-spinner',
	template: `
		<div [ngStyle]="{ width: size + 4, height: size + 4 }">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 48 48"
				fill="none"
				class="spinner"
				[ngStyle]="{ width: size + 4, height: size + 4 }"
				[attr.width]="size"
				[attr.height]="size">
				<mask
					id="mask0_3801_3"
					style="mask-type:alpha"
					maskUnits="userSpaceOnUse"
					x="-1"
					y="0"
					width="49"
					height="48">
					<circle cx="23.951" cy="24" r="24" fill="#44546F" />
				</mask>
				<g mask="url(#mask0_3801_3)">
					<path
						d="M20.0878 0.244543C20.4194 0.177158 20.7458 0.103216 21.0788 0.043119C22.4607 -0.192215 23.622 0.573537 23.9162 1.91018C24.203 3.21038 23.515 4.36719 22.1503 4.7053C19.8236 5.27687 17.5188 5.90091 15.4642 7.18369C9.10412 11.1554 5.49185 16.8303 5.10642 24.479C5.07559 25.0659 5.07508 25.6541 5.05942 26.2417C5.01324 27.75 4.07826 28.7255 2.6742 28.7225C1.37549 28.7208 0.407032 27.7224 0.330783 26.2579C0.170878 23.2926 0.545852 20.3742 1.48281 17.5806C4.56007 8.40577 10.8461 2.70354 20.0878 0.244543Z"
						fill="#BFBFBF" />
				</g>
			</svg>
		</div>
	`,
	styleUrls: ['./loading-spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
	@Input() size = 48;
}
