import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BadgeComponent } from './badge.component';
import { TooltipModule } from '../tooltip';
import { BadgeGroupComponent } from './badge-group.component';

@NgModule({
	declarations: [BadgeComponent, BadgeGroupComponent],
	imports: [CommonModule, TooltipModule],
	exports: [BadgeComponent, BadgeGroupComponent],
})
export class BadgeModule {}
