import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClickOutsideModule } from '../../directives/click-outside';
import { IconsModule } from '../icons';

import { MenuComponent } from './menu.component';
import { MenuItemComponent } from './menu-item.component';
// import { MenuTriggerComponent } from './menu-trigger.component';
// import { MenuGroupComponent } from './menu-group.component';
import { OverlayModule } from '@angular/cdk/overlay';

const COMPONENTS = [
	MenuComponent,
	MenuItemComponent,
	// MenuTriggerComponent,
	// MenuGroupComponent,
];

@NgModule({
	imports: [CommonModule, OverlayModule, ClickOutsideModule, IconsModule],
	exports: [...COMPONENTS],
	declarations: [...COMPONENTS],
})
export class MenuModule {}
