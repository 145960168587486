import { EuiIcon } from '../types/icon.interface';

export const driveCloud: EuiIcon = {
	name: 'drive-cloud',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.5963 15.5554H10.098C10.2739 15.1631 10.3319 14.6474 10.2416 14.227H16.5963C17.8514 14.227 18.6817 13.5116 18.6817 12.2724C18.6817 11.1505 17.8514 10.3164 16.5963 10.3164H8.31072C7.95379 9.71424 7.41652 9.25455 6.7619 8.98806H16.5963C16.9274 8.98806 17.2369 9.04228 17.5167 9.1412L16.0105 5.4018C15.7663 4.79428 15.23 4.47945 14.5013 4.47945H8.77093C8.04223 4.47945 7.50593 4.79428 7.26176 5.4018L5.92707 8.72998C5.46628 8.63722 4.89995 8.65124 4.44486 8.78224L6.00772 5.09188C6.49035 3.93947 7.45268 3.3335 8.74592 3.3335H14.5206C15.8139 3.3335 16.7819 3.93947 17.2646 5.09188L19.6495 10.7392C19.8395 11.2032 20 11.7066 20 12.2724C20 14.1463 18.5979 15.5554 16.5963 15.5554Z" fill="#262626"/>
  <path d="M16.5675 13.1751C16.0677 13.1751 15.6578 12.7804 15.6578 12.2724C15.6578 11.7841 16.0702 11.3696 16.5675 11.3696C17.0451 11.3696 17.4634 11.7841 17.4634 12.2724C17.4634 12.7747 17.0476 13.1751 16.5675 13.1751Z" fill="#262626"/>
  <path d="M6.95379 16.6665H2.14103C0.923768 16.6665 0.000488281 15.7116 0.000488281 14.5694C0.000488281 13.631 0.487576 12.8614 1.28111 12.7166C1.31194 11.6569 2.24453 10.9365 3.1291 11.1965C3.57014 10.5205 4.26517 9.99999 5.18949 9.99999C6.6476 9.99999 7.78445 11.2251 7.78143 12.9224C8.46582 13.2264 8.88913 13.9376 8.88913 14.7305C8.88913 15.8007 8.04222 16.6665 6.95379 16.6665Z" fill="#262626"/>
  </svg>
  `,
};
