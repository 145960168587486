import { EuiIcon } from '../types/icon.interface';

export const helpCircle: EuiIcon = {
	name: 'help-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.99986 6.25C9.41843 6.25 8.928 6.64765 8.78919 7.18694C8.68595 7.58808 8.27706 7.82957 7.87592 7.72633C7.47478 7.62308 7.23329 7.2142 7.33654 6.81306C7.64177 5.62712 8.71744 4.75 9.99986 4.75C11.5186 4.75 12.7499 5.98121 12.7499 7.5C12.7498 8.71116 12.0959 9.34041 11.6054 9.79829L11.5972 9.80593C11.1192 10.252 10.8251 10.5265 10.7423 11.1061C10.6838 11.5161 10.3039 11.801 9.89381 11.7425C9.48376 11.6839 9.19884 11.304 9.25741 10.8939C9.41609 9.78322 10.0605 9.18547 10.5143 8.76451L10.5819 8.70171C11.0288 8.28459 11.2499 8.03884 11.2499 7.5C11.2499 6.80965 10.6902 6.25 9.99986 6.25Z" fill="#262626"/>
  <path d="M9.99988 15C10.5522 15 10.9999 14.5523 10.9999 14C10.9999 13.4477 10.5522 13 9.99988 13C9.44759 13 8.99988 13.4477 8.99988 14C8.99988 14.5523 9.44759 15 9.99988 15Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 1.5C5.30546 1.5 1.49988 5.30558 1.49988 10C1.49988 14.6944 5.30546 18.5 9.99988 18.5C14.6943 18.5 18.4999 14.6944 18.4999 10C18.4999 5.30558 14.6943 1.5 9.99988 1.5ZM2.99988 10C2.99988 13.866 6.13388 17 9.99988 17C13.8659 17 16.9999 13.866 16.9999 10C16.9999 6.13401 13.8659 3 9.99988 3C6.13388 3 2.99988 6.13401 2.99988 10Z" fill="#262626"/>
  </svg>
  `,
};
