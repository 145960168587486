import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkMenuModule } from '@angular/cdk/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { ClickOutsideModule } from '../../directives/click-outside';
import { FilterModule } from '../../pipes/filter';
import { InputModule } from '../input';
import { DropdownOptionsSearchComponent } from './dropdown-options-search.component';
import { DropdownComponent } from './dropdown.component';
import { DropdownOptionsComponent } from './dropdown-options.component';
import { CheckboxModule } from '../checkbox';
import { IconsModule } from '../icons';
import { ButtonModule } from '../button';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		ClickOutsideModule,
		FilterModule,
		CheckboxModule,
		IconsModule,
		CdkMenuModule,
		ButtonModule,
		OverlayModule,
		PortalModule,
		A11yModule,
		ScrollingModule,
	],
	exports: [
		DropdownComponent,
		DropdownOptionsComponent,
		DropdownOptionsSearchComponent,
	],
	declarations: [
		DropdownComponent,
		DropdownOptionsComponent,
		DropdownOptionsSearchComponent,
	],
})
export class DropdownModule {}
