import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
} from '@angular/core';

import { Breadcrumb } from './types/breadcrumb.interface';

@Component({
	selector: 'eui-breadcrumbs',
	template: `
		<nav class="eui-breadcrumbs eui-breadcrumbs_subtler">
			<ng-container
				*ngFor="
					let breadcrumb of breadcrumbs;
					let i = index;
					trackBy: trackByFn
				">
				<eui-breadcrumb
					[url]="breadcrumb.url"
					[active]="breadcrumbs.length > 1 && i === breadcrumbs.length - 1"
					[dots]="breadcrumb === DOTS"
					[subtler]="true">
					{{ breadcrumb.name }}
				</eui-breadcrumb>

				<span class="delimiter" *ngIf="i < breadcrumbs.length - 1">/</span>
			</ng-container>
		</nav>
	`,
	styleUrls: ['./breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
	@Input() set breadcrumbs(breadcrumbs: Breadcrumb[]) {
		if (breadcrumbs.length > this.siblingCount + 1) {
			this.wrappedBreadcrumbs = this.wrappedBreadcrumbs.concat(
				[breadcrumbs[0], this.DOTS],
				breadcrumbs.slice(
					breadcrumbs.length - this.siblingCount,
					breadcrumbs.length,
				),
			);
		} else {
			this.wrappedBreadcrumbs = breadcrumbs;
		}

		this.cdr.markForCheck();
	}

	get breadcrumbs() {
		return this.wrappedBreadcrumbs;
	}

	@Input() siblingCount = 3;

	DOTS: Breadcrumb = {
		name: '...',
		url: '',
	};

	private wrappedBreadcrumbs: Breadcrumb[] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	trackByFn(index: number): number {
		return index;
	}
}
