import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-input-group',
	template: `
		<div
			class="eui-input-group"
			[ngClass]="{
				'eui-input-group__no-border': noBorder
			}">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./input-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputGroupComponent {
	@Input() error = false;
	@Input() disabled = false;
	@Input() noBorder = false;
}
