import { Directive, HostListener, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[euiTrimInput],textarea[euiTrimInput]',
})
export class TrimInputDirective implements OnInit {
	constructor(@Optional() private ngControl: NgControl) {}

	ngOnInit() {
		if (!this.ngControl) {
			console.warn(
				'TrimInputDirective required ngModel, formControl or formControlName.',
			);
			return;
		}
	}

	@HostListener('blur', ['$event.target', '$event.target.value'])
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onBlur(el: any, value: string) {
		if ('function' === typeof value.trim && value.trim() !== value) {
			el.value = value.trim();

			const event = new Event('input', {
				bubbles: false,
				cancelable: false,
			});
			el.dispatchEvent(event);

			const eventNew = new Event('blur', {
				bubbles: false,
				cancelable: false,
			});
			el.dispatchEvent(eventNew);
		}
	}
}
