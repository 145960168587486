import { EuiIcon } from '../types/icon.interface';

export const sparkles: EuiIcon = {
	name: 'sparkles',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.45484 4.27891C9.57322 4.27891 9.64357 4.19992 9.66214 4.0877C9.90692 2.68465 9.88443 2.62914 11.3602 2.35477C11.4716 2.3271 11.5474 2.25837 11.5474 2.13588C11.5474 2.02053 11.4701 1.94311 11.3587 1.92415C9.88443 1.66542 9.90539 1.60679 9.66214 0.192779C9.64357 0.0789908 9.57322 0 9.45484 0C9.33492 0 9.2661 0.0789906 9.24754 0.195911C9.00736 1.59426 9.02678 1.64976 7.54944 1.92415C7.42952 1.94468 7.36224 2.02053 7.36224 2.13588C7.36224 2.25837 7.42953 2.3271 7.55404 2.35477C9.02218 2.61975 9.00276 2.67838 9.24754 4.08613C9.2661 4.19992 9.33492 4.27891 9.45484 4.27891Z" fill="#262626"/>
<path d="M5.325 10.3565C5.50061 10.3565 5.62819 10.2309 5.6483 10.0571C5.9503 7.75312 6.01623 7.74529 8.36535 7.29362C8.527 7.26439 8.64846 7.14277 8.64846 6.96338C8.64846 6.78957 8.52548 6.65926 8.36229 6.64029C6.02083 6.30571 5.94332 6.23212 5.6483 3.88396C5.62819 3.70144 5.50215 3.57582 5.325 3.57582C5.1533 3.57582 5.02724 3.70144 5.0017 3.89421C4.73068 6.19506 4.61827 6.19418 2.28463 6.64029C2.12145 6.66952 2 6.78957 2 6.96338C2 7.15304 2.12145 7.26439 2.32177 7.29362C4.62917 7.667 4.73068 7.73259 5.0017 10.0397C5.02724 10.2309 5.1533 10.3565 5.325 10.3565Z" fill="#262626"/>
<path d="M11.2144 20C11.4773 20 11.6743 19.805 11.7215 19.5279C12.3323 14.8209 12.9805 14.1059 17.5246 13.5885C17.8044 13.5578 18 13.3462 18 13.0691C18 12.8021 17.8075 12.5921 17.5261 12.5566C12.9835 12.0206 12.3538 11.3258 11.7215 6.61175C11.6713 6.33459 11.4757 6.14669 11.2144 6.14669C10.9531 6.14669 10.7561 6.33459 10.7159 6.61175C10.1035 11.3258 9.4499 12.0409 4.91124 12.5566C4.62439 12.5875 4.43038 12.799 4.43038 13.0691C4.43038 13.3447 4.62286 13.5547 4.90971 13.5885C9.43985 14.2062 10.0541 14.8209 10.7159 19.5279C10.7576 19.805 10.9547 20 11.2144 20Z" fill="#262626"/>
</svg>
`,
};
