import { EuiIcon } from '../types/icon.interface';

export const filtersOff: EuiIcon = {
	name: 'filters-off',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.11796 2.96014C2.71657 2.5207 2.03491 2.48992 1.59543 2.89136C1.15604 3.29281 1.12521 3.97446 1.5267 4.41384L2.19751 5.14811C2.15457 5.17905 2.11374 5.21442 2.07491 5.25425C1.90021 5.43358 1.81285 5.65554 1.81285 5.92017C1.81285 6.1848 1.90021 6.4068 2.07491 6.58614C2.24908 6.76484 2.46504 6.85419 2.72288 6.85419H3.75614L6.31594 9.65614H5.45296C5.19511 9.65614 4.97884 9.74549 4.80414 9.92419C4.63007 10.1035 4.54293 10.3255 4.54293 10.5901C4.54293 10.8547 4.63007 11.0764 4.80414 11.2551C4.97884 11.4344 5.19511 11.5241 5.45296 11.5241H8.02243L10.5823 14.326H9.09316C8.83532 14.326 8.61936 14.4157 8.44519 14.595C8.27049 14.7737 8.18314 14.9954 8.18314 15.2601C8.18314 15.5247 8.27049 15.7463 8.44519 15.925C8.61936 16.1044 8.83532 16.194 9.09316 16.194H10.9132C11.1711 16.194 11.3873 16.1044 11.5621 15.925C11.6514 15.8335 11.7177 15.7306 11.7611 15.6165L13.0616 17.0399C13.463 17.4793 14.1447 17.5101 14.5842 17.1087C15.0235 16.7072 15.0544 16.0256 14.6529 15.5862L3.11796 2.96014Z" fill="#262626"/>
  <path d="M14.5534 9.65614H10.9792L12.7226 11.5241H14.5534C14.8113 11.5241 15.0272 11.4344 15.2013 11.2551C15.3761 11.0764 15.4634 10.8547 15.4634 10.5901C15.4634 10.3255 15.3761 10.1035 15.2013 9.92419C15.0272 9.74549 14.8113 9.65614 14.5534 9.65614Z" fill="#262626"/>
  <path d="M6.62061 4.9862L8.36405 6.85419H17.2835C17.5413 6.85419 17.7573 6.76484 17.9315 6.58614C18.1062 6.4068 18.1935 6.1848 18.1935 5.92017C18.1935 5.65554 18.1062 5.43358 17.9315 5.25425C17.7573 5.07555 17.5413 4.9862 17.2835 4.9862H6.62061Z" fill="#262626"/>
  </svg>
  `,
};
