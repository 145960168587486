import { EuiIcon } from '../types/icon.interface';

export const columnVertical: EuiIcon = {
	name: 'column-vertical',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 5.5C7.91421 5.5 8.25 5.83579 8.25 6.25V13.75C8.25 14.1642 7.91421 14.5 7.5 14.5C7.08579 14.5 6.75 14.1642 6.75 13.75V6.25C6.75 5.83579 7.08579 5.5 7.5 5.5Z" fill="#262626"/>
  <path d="M12.5 5.5C12.9142 5.5 13.25 5.83579 13.25 6.25V13.75C13.25 14.1642 12.9142 14.5 12.5 14.5C12.0858 14.5 11.75 14.1642 11.75 13.75V6.25C11.75 5.83579 12.0858 5.5 12.5 5.5Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3C3.20507 3 1.75 4.45507 1.75 6.25V13.75C1.75 15.5449 3.20507 17 5 17H15C16.7949 17 18.25 15.5449 18.25 13.75V6.25C18.25 4.45507 16.7949 3 15 3H5ZM3.25 6.25C3.25 5.2835 4.0335 4.5 5 4.5H15C15.9665 4.5 16.75 5.2835 16.75 6.25V13.75C16.75 14.7165 15.9665 15.5 15 15.5H5C4.0335 15.5 3.25 14.7165 3.25 13.75V6.25Z" fill="#262626"/>
  </svg>
  `,
};
