import { EuiIcon } from '../types/icon.interface';

export const link: EuiIcon = {
	name: 'link',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.5166 4.17572C13.4279 3.27476 14.9089 3.27476 15.8202 4.17572C16.7267 5.07199 16.7267 6.52155 15.8202 7.41782L15.1335 8.09671C14.839 8.38793 14.8363 8.8628 15.1275 9.15735C15.4187 9.45191 15.8936 9.45462 16.1881 9.1634L16.8748 8.48451C18.3752 7.0011 18.3752 4.59244 16.8748 3.10904C15.3791 1.63032 12.9577 1.63032 11.462 3.10904L8.12541 6.40787C6.62501 7.89128 6.62501 10.2999 8.12542 11.7833C8.41997 12.0746 8.89484 12.0719 9.18606 11.7773C9.47728 11.4827 9.47457 11.0079 9.18002 10.7167C8.27348 9.82039 8.27348 8.37083 9.18002 7.47456L12.5166 4.17572Z" fill="#262626"/>
  <path d="M7.48358 15.8243C6.57229 16.7252 5.09131 16.7252 4.18003 15.8243C3.27349 14.928 3.27349 13.4784 4.18003 12.5822L4.86669 11.9033C5.16125 11.6121 5.16396 11.1372 4.87274 10.8426C4.58152 10.5481 4.10665 10.5454 3.81209 10.8366L3.12542 11.5155C1.62502 12.9989 1.62502 15.4076 3.12542 16.891C4.62108 18.3697 7.04252 18.3697 8.53818 16.891L11.8748 13.5921C13.3752 12.1087 13.3752 9.70006 11.8748 8.21666C11.5803 7.92544 11.1054 7.92814 10.8142 8.2227C10.5229 8.51726 10.5257 8.99212 10.8202 9.28334C11.7267 10.1796 11.7267 11.6292 10.8202 12.5254L7.48358 15.8243Z" fill="#262626"/>
  </svg>
  `,
};
