import { EuiIcon } from '../types/icon.interface';

export const warningOutline: EuiIcon = {
	name: 'warning-outline',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0001 13.999C11.0001 14.5513 10.5523 14.999 10.0001 14.999C9.44778 14.999 9.00006 14.5513 9.00006 13.999C9.00006 13.4467 9.44778 12.999 10.0001 12.999C10.5523 12.999 11.0001 13.4467 11.0001 13.999Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3757 3.36226C12.2182 3.09133 11.4897 2 9.9993 2C8.50885 2 7.79034 3.07694 7.62437 3.36226L1.52317 13.8511C1.11219 14.5576 1.03554 15.4096 1.31384 16.178C1.70988 17.2715 2.74918 18 3.91322 18H16.0869C17.2509 18 18.2902 17.2715 18.6863 16.178C18.9646 15.4096 18.8879 14.5576 18.477 13.8511L12.3757 3.36226ZM16.0869 16.5015C16.6193 16.5015 17.0946 16.1683 17.2758 15.6682C17.4031 15.3168 17.368 14.9271 17.18 14.6039L11.0788 4.11514C11.0788 4.11514 10.75 3.49387 9.99927 3.49387C9.24854 3.49387 8.9213 4.11514 8.9213 4.11514L2.82009 14.6039C2.63213 14.9271 2.59707 15.3168 2.72435 15.6682C2.90549 16.1683 3.38083 16.5015 3.91322 16.5015H16.0869Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.99902C10.4142 5.99902 10.75 6.33481 10.75 6.74902V11.249C10.75 11.6632 10.4142 11.999 10 11.999C9.58579 11.999 9.25 11.6632 9.25 11.249V6.74902C9.25 6.33481 9.58579 5.99902 10 5.99902Z" fill="#262626"/>
</svg>
`,
};
