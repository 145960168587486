import { EuiIcon } from '../types/icon.interface';

export const ellipsisMessageOutline: EuiIcon = {
	name: 'ellipsis-message-outline',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.04065 8.92234C5.04065 9.60031 5.5944 10.1541 6.27378 10.1541C6.95034 10.1541 7.49487 9.60031 7.49487 8.92234C7.49487 8.24578 6.94393 7.69844 6.27378 7.69844C5.5944 7.69844 5.04065 8.24578 5.04065 8.92234Z" fill="#262626"/>
  <path d="M10.0442 10.1541C9.36759 10.1541 8.81384 9.60031 8.81384 8.92234C8.81384 8.24578 9.36759 7.69844 10.0442 7.69844C10.7221 7.69844 11.2759 8.24578 11.2759 8.92234C11.2759 9.60031 10.7221 10.1541 10.0442 10.1541Z" fill="#262626"/>
  <path d="M12.5938 8.92234C12.5938 9.60031 13.1382 10.1541 13.8211 10.1541C14.4955 10.1541 15.0479 9.60031 15.0479 8.92234C15.0479 8.24578 14.4955 7.69844 13.8211 7.69844C13.1382 7.69844 12.5938 8.24578 12.5938 8.92234Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46446 15.8089C7.94618 16.9233 5.92704 17.9167 4.92935 17.9167C4.07908 17.9167 3.76452 17.1415 4.21859 16.5237C4.53345 16.0989 5.08628 15.2689 5.39595 14.6704C3.14071 13.5474 1.66663 11.3804 1.66663 8.95894C1.66663 5.15247 5.37105 2.08337 10.0006 2.08337C14.6288 2.08337 18.3333 5.15247 18.3333 8.95894C18.3333 12.9005 14.5439 15.9054 9.46446 15.8089ZM5.72621 16.3263C5.66819 16.406 5.70144 16.4569 5.78884 16.4228C6.43092 16.1562 7.72001 15.3216 8.58726 14.6645C8.87376 14.4311 9.10541 14.3391 9.41292 14.3391L9.58432 14.3433C9.75809 14.3477 9.89867 14.3512 10.0006 14.3512C13.8372 14.3512 16.8929 11.9279 16.8929 8.95894C16.8929 5.9839 13.8372 3.56062 10.0006 3.56062C6.16861 3.56062 3.11291 5.9839 3.11291 8.95894C3.11291 10.8842 4.38659 12.5724 6.58728 13.6291C7.05056 13.8545 7.12557 14.21 6.90976 14.6205C6.64688 15.1236 6.09433 15.8595 5.72621 16.3263Z" fill="#262626"/>
  </svg>
  `,
};
