<div
	#pickerContainer
	[class]="'drops-' + drops + '-' + opens"
	[ngClass]="{
		shown: isShown || inline,
		hidden: !isShown && !inline,
		inline: inline,
		double: !singleDatePicker && showCalInRanges
	}"
	class="eui-datepicker">
	<div class="eui-datepicker__content">
		<!-- CUSTOM RANGES -->
		<ng-container *ngIf="rangesArray.length">
			<div class="eui-datepicker__ranges">
				<div
					class="eui-datepicker__ranges-item"
					*ngFor="let range of rangesArray; trackBy: trackByFn"
					(click)="clickRange($event, range)"
					[ngClass]="{
						active: range === chosenRange,
						disabled: disableRange(range)
					}">
					{{ range }}
				</div>
			</div>
		</ng-container>

		<!-- LEFT CALENDAR -->
		<div
			*ngIf="showCalInRanges"
			[ngClass]="{ right: singleDatePicker, left: !singleDatePicker }"
			class="calendar">
			<div class="calendar-table">
				<table *ngIf="calendarVariables" class="table-condensed">
					<thead>
						<tr>
							<th *ngIf="showWeekNumbers || showISOWeekNumbers"></th>

							<ng-container
								*ngIf="
									!calendarVariables['left'].minDate ||
									(calendarVariables['left'].minDate.isBefore(
										calendarVariables['left'].calendar.firstDay
									) &&
										(!this.linkedCalendars || true))
								">
								<th (click)="clickPrev(sideEnum.left)" class="available prev">
									<eui-icons name="chevron-left" [size]="20"></eui-icons>
								</th>
							</ng-container>

							<ng-container
								*ngIf="
									!(
										!calendarVariables['left'].minDate ||
										(calendarVariables['left'].minDate.isBefore(
											calendarVariables['left'].calendar.firstDay
										) &&
											(!this.linkedCalendars || true))
									)
								">
								<th></th>
							</ng-container>

							<th class="month drp-animate" colspan="5">
								<ng-container
									*ngIf="showDropdowns && calendarVariables['left'].dropdowns">
									<div class="dropdowns-wrapper">
										<div class="dropdowns">
											{{ getLeftMonth() }}
											<select
												(change)="monthChanged($event, sideEnum.left)"
												class="monthselect">
												<option
													*ngFor="
														let m of calendarVariables['left'].dropdowns
															.monthArrays;
														trackBy: trackByFn
													"
													[disabled]="
														(calendarVariables['left'].dropdowns.inMinYear &&
															m < calendarVariables['left'].minDate.month()) ||
														(calendarVariables['left'].dropdowns.inMaxYear &&
															m > calendarVariables['left'].maxDate.month())
													"
													[selected]="
														calendarVariables['left'].dropdowns.currentMonth ===
														m
													"
													[value]="m">
													{{ getLocaleMonthName(m) }}
												</option>
											</select>
										</div>
										<div class="dropdowns">
											{{ getLeftMonthFormat() }}
											<select
												(change)="yearChanged($event, sideEnum.left)"
												class="yearselect">
												<option
													*ngFor="
														let y of calendarVariables['left'].dropdowns
															.yearArrays;
														trackBy: trackByFn
													"
													[selected]="
														y ===
														calendarVariables['left'].dropdowns.currentYear
													">
													{{ y }}
												</option>
											</select>
										</div>
									</div>
								</ng-container>
								<ng-container
									*ngIf="
										!showDropdowns || !calendarVariables['left'].dropdowns
									">
									{{ getLeftMonth() }}
									{{ getLeftMonthFormat() }}
								</ng-container>
							</th>

							<ng-container
								*ngIf="
									(!calendarVariables['left'].maxDate ||
										calendarVariables['left'].maxDate.isAfter(
											calendarVariables['left'].calendar.lastDay
										)) &&
									(!linkedCalendars || singleDatePicker)
								">
								<th (click)="clickNext(sideEnum.left)" class="available next">
									<eui-icons name="chevron-right" [size]="20"></eui-icons>
								</th>
							</ng-container>

							<ng-container
								*ngIf="
									!(
										(!calendarVariables['left'].maxDate ||
											calendarVariables['left'].maxDate.isAfter(
												calendarVariables['left'].calendar.lastDay
											)) &&
										(!linkedCalendars || singleDatePicker)
									)
								">
								<th></th>
							</ng-container>
						</tr>

						<tr>
							<th *ngIf="showWeekNumbers || showISOWeekNumbers" class="week">
								<span>{{ this.locale.weekLabel }}</span>
							</th>
							<th
								*ngFor="let dayofweek of locale.daysOfWeek; trackBy: trackByFn">
								<span>{{ dayofweek }}</span>
							</th>
						</tr>
					</thead>

					<tbody class="drp-animate">
						<tr
							*ngFor="
								let row of calendarVariables['left'].calRows;
								trackBy: trackByFn
							"
							[class]="calendarVariables['left'].classes[row].classList">
							<!-- add week number -->
							<td *ngIf="showWeekNumbers" class="week">
								<span class="cell">{{
									calendarVariables['left'].calendar[row][0].week()
								}}</span>
							</td>
							<td *ngIf="showISOWeekNumbers" class="week">
								<span class="cell">{{
									calendarVariables['left'].calendar[row][0].isoWeek()
								}}</span>
							</td>

							<!-- cell -->
							<td
								(click)="clickDate($event, sideEnum.left, row, col)"
								(mouseenter)="hoverDate($event, sideEnum.left, row, col)"
								*ngFor="
									let col of calendarVariables['left'].calCols;
									trackBy: trackByFn
								"
								[class]="calendarVariables['left'].classes[row][col]">
								<span class="cell">{{
									calendarVariables['left'].calendar[row][col].date()
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- LEFT CALENDAR TIME PICKER -->
			<div *ngIf="timePicker" class="calendar-time">
				<div class="dropdowns-wrapper">
					<div class="dropdowns dropdowns-time">
						{{ timepickerVariables['left'].selectedHour }}
						<select
							[(ngModel)]="timepickerVariables['left'].selectedHour"
							(ngModelChange)="timeChanged($event, sideEnum.left)"
							[disabled]="!startDate"
							class="hourselect">
							<option
								*ngFor="
									let i of timepickerVariables['left'].hours;
									trackBy: trackByFn
								"
								[disabled]="
									timepickerVariables['left'].disabledHours.indexOf(i) > -1
								"
								[value]="i">
								{{ i }}
							</option>
						</select>
					</div>
				</div>

				<div class="dropdowns-time">:</div>

				<div class="dropdowns-wrapper">
					<div class="dropdowns dropdowns-time">
						{{ timepickerVariables['left'].selectedMinute }}
						<select
							[(ngModel)]="timepickerVariables['left'].selectedMinute"
							(ngModelChange)="timeChanged($event, sideEnum.left)"
							[disabled]="!startDate"
							class="minuteselect">
							<option
								*ngFor="
									let i of timepickerVariables['left'].minutes;
									let index = index;
									trackBy: trackByFn
								"
								[disabled]="
									timepickerVariables['left'].disabledMinutes.indexOf(i) > -1
								"
								[value]="i">
								{{ timepickerVariables['left'].minutesLabel[index] }}
							</option>
						</select>
					</div>
				</div>

				<ng-container *ngIf="timePickerSeconds">
					<div class="dropdowns-time">:</div>

					<div class="dropdowns-wrapper">
						<div class="dropdowns dropdowns-time">
							{{ timepickerVariables['left'].selectedSecond }}
							<select
								[(ngModel)]="timepickerVariables['left'].selectedSecond"
								(ngModelChange)="timeChanged($event, sideEnum.left)"
								[disabled]="!startDate"
								class="secondselect">
								<option
									*ngFor="
										let i of timepickerVariables['left'].seconds;
										let index = index;
										trackBy: trackByFn
									"
									[disabled]="
										timepickerVariables['left'].disabledSeconds.indexOf(i) > -1
									"
									[value]="i">
									{{ timepickerVariables['left'].secondsLabel[index] }}
								</option>
							</select>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="!timePicker24Hour">
					<div class="dropdowns-wrapper">
						<div class="dropdowns">
							{{ timepickerVariables['left'].ampmModel }}
							<select
								[(ngModel)]="timepickerVariables['left'].ampmModel"
								(ngModelChange)="timeChanged($event, sideEnum.left)"
								class="ampmselect">
								<option
									[disabled]="timepickerVariables['left'].amDisabled"
									value="AM">
									AM
								</option>
								<option
									[disabled]="timepickerVariables['left'].pmDisabled"
									value="PM">
									PM
								</option>
							</select>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<!-- RIGHT CALENDAR -->
		<div
			*ngIf="showCalInRanges && !singleDatePicker"
			class="calendar calendar__right">
			<div class="calendar-table">
				<table *ngIf="calendarVariables" class="table-condensed">
					<thead>
						<tr>
							<th *ngIf="showWeekNumbers || showISOWeekNumbers"></th>
							<ng-container
								*ngIf="
									(!calendarVariables['right'].minDate ||
										calendarVariables['right'].minDate.isBefore(
											calendarVariables['right'].calendar.firstDay
										)) &&
									!this.linkedCalendars
								">
								<th (click)="clickPrev(sideEnum.right)" class="prev available">
									<eui-icons name="chevron-left" [size]="20"></eui-icons>
								</th>
							</ng-container>

							<ng-container
								*ngIf="
									!(
										(!calendarVariables['right'].minDate ||
											calendarVariables['right'].minDate.isBefore(
												calendarVariables['right'].calendar.firstDay
											)) &&
										!this.linkedCalendars
									)
								">
								<th></th>
							</ng-container>

							<th class="month" colspan="5">
								<ng-container
									*ngIf="showDropdowns && calendarVariables['right'].dropdowns">
									<div class="dropdowns-wrapper">
										<div class="dropdowns">
											{{ getRightMonth() }}
											<select
												(change)="monthChanged($event, sideEnum.right)"
												class="monthselect">
												<option
													*ngFor="
														let m of calendarVariables['right'].dropdowns
															.monthArrays;
														trackBy: trackByFn
													"
													[disabled]="
														(calendarVariables['right'].dropdowns.inMinYear &&
															calendarVariables['right'].minDate &&
															m < calendarVariables['right'].minDate.month()) ||
														(calendarVariables['right'].dropdowns.inMaxYear &&
															calendarVariables['right'].maxDate &&
															m > calendarVariables['right'].maxDate.month())
													"
													[selected]="
														calendarVariables['right'].dropdowns
															.currentMonth === m
													"
													[value]="m">
													{{ getLocaleMonthName(m) }}
												</option>
											</select>
										</div>
										<div class="dropdowns">
											{{ getRightMonthFormat() }}
											<select
												(change)="yearChanged($event, sideEnum.right)"
												class="yearselect">
												<option
													*ngFor="
														let y of calendarVariables['right'].dropdowns
															.yearArrays;
														trackBy: trackByFn
													"
													[selected]="
														y ===
														calendarVariables['right'].dropdowns.currentYear
													">
													{{ y }}
												</option>
											</select>
										</div>
									</div>
								</ng-container>
								<ng-container
									*ngIf="
										!showDropdowns || !calendarVariables['right'].dropdowns
									">
									{{ getRightMonth() }}
									{{ getRightMonthFormat() }}
								</ng-container>
							</th>

							<ng-container
								*ngIf="
									!calendarVariables['right'].maxDate ||
									(calendarVariables['right'].maxDate.isAfter(
										calendarVariables['right'].calendar.lastDay
									) &&
										(!linkedCalendars || singleDatePicker || true))
								">
								<th (click)="clickNext(sideEnum.right)" class="next available">
									<eui-icons name="chevron-right" [size]="20"></eui-icons>
								</th>
							</ng-container>
							<ng-container
								*ngIf="
									!(
										!calendarVariables['right'].maxDate ||
										(calendarVariables['right'].maxDate.isAfter(
											calendarVariables['right'].calendar.lastDay
										) &&
											(!linkedCalendars || singleDatePicker || true))
									)
								">
								<th></th>
							</ng-container>
						</tr>

						<tr class="week-days">
							<th *ngIf="showWeekNumbers || showISOWeekNumbers" class="week">
								<span>{{ this.locale.weekLabel }}</span>
							</th>
							<th
								*ngFor="let dayofweek of locale.daysOfWeek; trackBy: trackByFn">
								<span>{{ dayofweek }}</span>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr
							*ngFor="
								let row of calendarVariables['right'].calRows;
								trackBy: trackByFn
							"
							[class]="calendarVariables['right'].classes[row].classList">
							<td *ngIf="showWeekNumbers" class="week">
								<span class="cell">{{
									calendarVariables['right'].calendar[row][0].week()
								}}</span>
							</td>
							<td *ngIf="showISOWeekNumbers" class="week">
								<span class="cell">{{
									calendarVariables['right'].calendar[row][0].isoWeek()
								}}</span>
							</td>
							<td
								(click)="clickDate($event, sideEnum.right, row, col)"
								(mouseenter)="hoverDate($event, sideEnum.right, row, col)"
								*ngFor="
									let col of calendarVariables['right'].calCols;
									trackBy: trackByFn
								"
								[class]="calendarVariables['right'].classes[row][col]">
								<span class="cell">{{
									calendarVariables['right'].calendar[row][col].date()
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- RIGHT CALENDAR TIME PICKER -->
			<div *ngIf="timePicker" class="calendar-time">
				<div class="dropdowns-wrapper">
					<div class="dropdowns dropdowns-time">
						{{ timepickerVariables['right'].selectedHour }}
						<select
							[(ngModel)]="timepickerVariables['right'].selectedHour"
							(ngModelChange)="timeChanged($event, sideEnum.right)"
							[disabled]="!startDate"
							class="hourselect">
							<option
								*ngFor="
									let i of timepickerVariables['right'].hours;
									trackBy: trackByFn
								"
								[disabled]="
									timepickerVariables['right'].disabledHours.indexOf(i) > -1
								"
								[value]="i">
								{{ i }}
							</option>
						</select>
					</div>
				</div>

				<div class="dropdowns-time">:</div>

				<div class="dropdowns-wrapper">
					<div class="dropdowns dropdowns-time">
						{{ timepickerVariables['right'].selectedMinute }}
						<select
							[(ngModel)]="timepickerVariables['right'].selectedMinute"
							(ngModelChange)="timeChanged($event, sideEnum.right)"
							[disabled]="!startDate"
							class="minuteselect">
							<option
								*ngFor="
									let i of timepickerVariables['right'].minutes;
									let index = index;
									trackBy: trackByFn
								"
								[disabled]="
									timepickerVariables['right'].disabledMinutes.indexOf(i) > -1
								"
								[value]="i">
								{{ timepickerVariables['right'].minutesLabel[index] }}
							</option>
						</select>
					</div>
				</div>

				<ng-container *ngIf="timePickerSeconds"> </ng-container>

				<div class="dropdowns-time">:</div>

				<div class="dropdowns-wrapper">
					<div class="dropdowns dropdowns-time">
						{{ timepickerVariables['right'].selectedSecond }}
						<select
							[(ngModel)]="timepickerVariables['right'].selectedSecond"
							(ngModelChange)="timeChanged($event, sideEnum.right)"
							[disabled]="!startDate"
							class="secondselect">
							<option
								*ngFor="
									let i of timepickerVariables['right'].seconds;
									let index = index;
									trackBy: trackByFn
								"
								[disabled]="
									timepickerVariables['right'].disabledSeconds.indexOf(i) > -1
								"
								[value]="i">
								{{ timepickerVariables['right'].secondsLabel[index] }}
							</option>
						</select>
					</div>
				</div>

				<ng-container *ngIf="!timePicker24Hour">
					<div class="dropdowns-wrapper">
						<div class="dropdowns">
							{{ timepickerVariables['right'].ampmModel }}
							<select
								[(ngModel)]="timepickerVariables['right'].ampmModel"
								(ngModelChange)="timeChanged($event, sideEnum.right)"
								class="ampmselect">
								<option
									[disabled]="timepickerVariables['right'].amDisabled"
									value="AM">
									AM
								</option>
								<option
									[disabled]="timepickerVariables['right'].pmDisabled"
									value="PM">
									PM
								</option>
							</select>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div
		*ngIf="
			!autoApply &&
			(!rangesArray.length || (showCalInRanges && !singleDatePicker))
		"
		class="eui-datepicker__footer">
		<div
			class="eui-datepicker__actions"
			[ngClass]="{ 'eui-datepicker__actions-ranges': rangesArray.length }">
			<button
				(click)="clear()"
				*ngIf="showClearButton"
				euiButton
				color="fade"
				size="sm"
				type="button">
				{{ locale.clearLabel }}
			</button>

			<div class="eui-datepicker__actions-right">
				<button
					(click)="clickCancel($event)"
					*ngIf="showCancel"
					euiButton
					color="secondary"
					size="sm"
					type="button">
					{{ locale.cancelLabel }}
				</button>

				<button
					(click)="clickApply($event)"
					[disabled]="applyBtn.disabled"
					euiButton
					color="primary"
					size="sm"
					type="button">
					{{ locale.applyLabel }}
				</button>
			</div>
		</div>
	</div>
</div>
