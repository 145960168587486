import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';

@Component({
	selector: 'eui-radio',
	template: `
		<div
			class="eui-radio"
			[ngClass]="[disabled ? 'eui-radio__disabled' : '']"
			(click)="clickHandler()">
			<input
				class="eui-radio-input"
				[ngClass]="[error ? 'eui-radio-input__error' : '']"
				name="name"
				type="radio"
				[value]="value"
				[(ngModel)]="modelValue"
				(change)="valueChanged()"
				[disabled]="disabled" />
			<label class="eui-radio-label" *ngIf="label">{{ label }}</label>
		</div>
	`,
	styleUrls: ['./radio.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: RadioComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: RadioComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements ControlValueAccessor {
	@Input() label = '';
	@Input() error = false;
	@Input() disabled = false;
	@Input() value = '';
	@Input() name = '';

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	onChange = (value: unknown) => {};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	touched = false;

	modelValue!: unknown;

	// TODO:
	clickHandler() {
		// if (!this.disabled) {
		// 	this.writeValue(this.value);
		// 	this.valueChanged(this.modelValue);
		// 	this.markAsTouched();
		// 	this.cdr.detectChanges();
		// }
	}

	writeValue(modelValue: unknown) {
		this.modelValue = modelValue;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	validate(): ValidationErrors | null {
		return null;
	}

	valueChanged() {
		this.onChange(this.modelValue);
	}
}
