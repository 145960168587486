<button
	euiIconButton
	color="transparent-light-grey"
	size="sm"
	(click)="onExpandClick($event)">
	
	<ng-container *ngIf="expanded; else notExpanded">
		<eui-icons name="minus" [size]="16"></eui-icons>
	</ng-container>

	<ng-template #notExpanded>
		<eui-icons name="plus" [size]="16"></eui-icons>
	</ng-template>
</button>
