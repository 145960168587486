import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconButtonModule } from '../icon-button';
import { IconsModule } from '../icons';

import { ExpandButtonComponent } from './expand-button.component';

@NgModule({
	imports: [CommonModule, IconButtonModule, IconsModule],
	declarations: [ExpandButtonComponent],
	exports: [ExpandButtonComponent],
})
export class ExpandButtonModule {}
