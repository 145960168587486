import { EuiIcon } from '../types/icon.interface';

export const trash2: EuiIcon = {
	name: 'trash-2',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.95453 3.99997H6.55029V3.09818C6.55029 2.09278 7.29289 1.5 8.55363 1.5H11.4463C12.707 1.5 13.4496 2.09278 13.4496 3.09818V3.99997H12.0454V3.1592C12.0454 2.81876 11.7698 2.60034 11.3461 2.60034H8.65376C8.23011 2.60034 7.95453 2.81876 7.95453 3.1592V3.99997Z" fill="#262626"/>
  <path d="M12.9974 8.31213C13.0318 7.89935 12.725 7.53684 12.3122 7.50246C11.8995 7.46808 11.537 7.77484 11.5026 8.18762L11.0026 14.1905C10.9682 14.6032 11.2749 14.9657 11.6877 15.0001C12.1005 15.0345 12.463 14.7277 12.4974 14.315L12.9974 8.31213Z" fill="#262626"/>
  <path d="M8.49743 8.18762C8.46305 7.77484 8.10055 7.46808 7.68777 7.50247C7.27498 7.53685 6.96823 7.89935 7.00261 8.31213L7.50261 14.315C7.53699 14.7277 7.89949 15.0345 8.31227 15.0001C8.72506 14.9657 9.03181 14.6032 8.99743 14.1905L8.49743 8.18762Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 4H17.25C17.6642 4 18 4.33579 18 4.75C18 5.16421 17.6642 5.5 17.25 5.5H16.7046L15.798 14.7477C15.7466 15.2714 15.7039 15.7072 15.6409 16.0622C15.575 16.4328 15.4782 16.7732 15.2884 17.0901C14.9936 17.5823 14.5594 17.976 14.0408 18.2214C13.7068 18.3794 13.3586 18.4426 12.9833 18.4719C12.6239 18.5 12.1861 18.5 11.6599 18.5H8.34018C7.81403 18.5 7.3761 18.5 7.01669 18.4719C6.64142 18.4426 6.29319 18.3794 5.95928 18.2214C5.44066 17.976 5.00647 17.5823 4.71166 17.0901C4.52185 16.7732 4.42502 16.4328 4.35919 16.0622C4.29613 15.7072 4.25341 15.2714 4.20208 14.7477L3.29544 5.5H2.75C2.33579 5.5 2 5.16421 2 4.75C2 4.33579 2.33579 4 2.75 4ZM4.80263 5.5H15.1974L14.3079 14.5725C14.2531 15.1324 14.2155 15.5095 14.164 15.7999C14.114 16.0811 14.0599 16.222 14.0015 16.3194C13.8592 16.557 13.6496 16.7471 13.3992 16.8655C13.2966 16.9141 13.1511 16.9542 12.8664 16.9765C12.5724 16.9995 12.1934 17 11.6308 17H8.36926C7.80663 17 7.42768 16.9995 7.13369 16.9765C6.84893 16.9542 6.70344 16.9141 6.6008 16.8655C6.35042 16.7471 6.14082 16.557 5.9985 16.3194C5.94015 16.222 5.88602 16.0811 5.83607 15.7999C5.78449 15.5095 5.74699 15.1324 5.69209 14.5725L4.80263 5.5Z" fill="#262626"/>
  </svg>

  `,
};
