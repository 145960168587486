import { NgModule } from '@angular/core';

import { DialogComponent } from './dialog.component';
import { DialogBodyComponent } from './dialog-body.component';
import { DialogActionsComponent } from './dialog-actions.component';
import { DialogHeaderComponent } from './dialog-header.component';
import { DialogService } from './dialog.service';

const COMPONENTS = [
	DialogComponent,
	DialogHeaderComponent,
	DialogActionsComponent,
	DialogBodyComponent,
];

@NgModule({
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
	providers: [DialogService],
})
export class DialogModule {}
