import { EuiIcon } from '../types/icon.interface';

export const edit: EuiIcon = {
	name: 'edit',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.12777 2C5.51749 2 4.78763 2.1378 4.04631 2.53427C3.39639 2.88185 2.88185 3.39639 2.53427 4.04631C2.1378 4.78763 2 5.5175 2 7.12777V12.8722C2 14.4825 2.1378 15.2124 2.53427 15.9537C2.88185 16.6036 3.39639 17.1182 4.04631 17.4657C4.78763 17.8622 5.51749 18 7.12777 18H12.8722C14.4825 18 15.2124 17.8622 15.9537 17.4657C16.6036 17.1182 17.1182 16.6036 17.4657 15.9537C17.8622 15.2124 18 14.4825 18 12.8722V7.12777C18 6.71356 17.6642 6.37777 17.25 6.37777C16.8358 6.37777 16.5 6.71356 16.5 7.12777V12.8722C16.5 14.4032 16.3637 14.8336 16.143 15.2463C15.9352 15.6348 15.6348 15.9352 15.2463 16.143C14.8336 16.3638 14.4032 16.5 12.8722 16.5H7.12777C5.59677 16.5 5.16644 16.3638 4.75371 16.143C4.36519 15.9352 4.06477 15.6348 3.85699 15.2463C3.63625 14.8336 3.5 14.4032 3.5 12.8722V7.12777C3.5 5.59677 3.63625 5.16645 3.85699 4.75371C4.06477 4.36519 4.36519 4.06477 4.75371 3.85699C5.16644 3.63625 5.59677 3.5 7.12777 3.5H12.8722C13.2864 3.5 13.6222 3.16421 13.6222 2.75C13.6222 2.33579 13.2864 2 12.8722 2H7.12777Z" fill="#262626"/>
  <path d="M8.3396 13.0291L6.53416 13.7942C6.33043 13.8765 6.10663 13.6449 6.19359 13.4442L6.99382 11.6868L15.0878 3.5794L16.4423 4.92013L8.3396 13.0291Z" fill="#262626"/>
  <path d="M17.7396 3.61193L17.1198 4.24887L15.7652 2.88788L16.3858 2.27191C16.7169 1.94748 17.2156 1.90073 17.5179 2.20027L17.7507 2.43919C18.0892 2.76771 18.0811 3.25515 17.7396 3.61193Z" fill="#262626"/>
  </svg>
  `,
};
