import { EuiIcon } from '../types/icon.interface';

export const eye: EuiIcon = {
	name: 'eye',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 13C11.6567 13 12.9999 11.6568 12.9999 9.99998C12.9999 8.34313 11.6567 6.99998 9.99988 6.99998C8.34302 6.99998 6.99988 8.34313 6.99988 9.99998C6.99988 11.6568 8.34302 13 9.99988 13ZM9.99988 11.5C10.8283 11.5 11.4999 10.8284 11.4999 9.99998C11.4999 9.17156 10.8283 8.49998 9.99988 8.49998C9.17145 8.49998 8.49988 9.17156 8.49988 9.99998C8.49988 10.8284 9.17145 11.5 9.99988 11.5Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 9.99892C18.3333 11.2184 14.9749 15.1587 10.0019 15.1587C5.09608 15.1587 1.66663 11.2184 1.66663 9.99892C1.66663 8.77672 5.10105 4.84125 10.0019 4.84125C14.9699 4.84125 18.3333 8.77672 18.3333 9.99892ZM3.04034 9.99892C3.04034 10.8299 6.12152 14.015 10.0019 14.015C13.8673 14.015 16.9595 10.8299 16.9595 9.99892C16.9595 9.30233 13.8673 5.98498 10.0019 5.98498C6.12152 5.98498 3.04034 9.30233 3.04034 9.99892Z" fill="#262626"/>
</svg>
`,
};
