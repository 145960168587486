import { EuiIcon } from '../types/icon.interface';

export const arrowRightCircle: EuiIcon = {
	name: 'arrow-right-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.98959 3.98965C5.52695 2.4523 7.653 1.50006 10 1.50006C12.347 1.50006 14.4731 2.4523 16.0104 3.98965C17.5478 5.52701 18.5 7.65306 18.5 10.0001C18.5 12.3471 17.5478 14.4731 16.0104 16.0105C14.4731 17.5478 12.347 18.5001 10 18.5001C7.653 18.5001 5.52695 17.5478 3.98959 16.0105C2.45224 14.4731 1.5 12.3471 1.5 10.0001C1.5 7.65306 2.45224 5.52701 3.98959 3.98965ZM10 3.04552C8.07934 3.04552 6.34176 3.82308 5.08239 5.08245C3.82302 6.34183 3.04546 8.0794 3.04546 10.0001C3.04546 11.9207 3.82302 13.6583 5.08239 14.9177C6.34176 16.177 8.07934 16.9546 10 16.9546C11.9207 16.9546 13.6582 16.177 14.9176 14.9177C16.177 13.6583 16.9545 11.9207 16.9545 10.0001C16.9545 8.0794 16.177 6.34183 14.9176 5.08245C13.6582 3.82308 11.9207 3.04552 10 3.04552Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.0001C6 9.58585 6.33579 9.25006 6.75 9.25006H11.4393L10.2197 8.03039C9.92678 7.7375 9.92678 7.26262 10.2197 6.96973C10.5126 6.67684 10.9874 6.67684 11.2803 6.96973L13.7803 9.46973C14.0732 9.76262 14.0732 10.2375 13.7803 10.5304L11.2803 13.0304C10.9874 13.3233 10.5126 13.3233 10.2197 13.0304C9.92678 12.7375 9.92678 12.2626 10.2197 11.9697L11.4393 10.7501H6.75C6.33579 10.7501 6 10.4143 6 10.0001Z" fill="#262626"/>
  </svg>
  `,
};
