import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SkeletonLoaderComponent } from './skeleton-loader.component';

@NgModule({
	imports: [CommonModule],
	exports: [SkeletonLoaderComponent],
	declarations: [SkeletonLoaderComponent],
})
export class SkeletonLoaderModule {}
