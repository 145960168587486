import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
// import { Observable } from 'rxjs';
import _ from 'lodash';

import { equalsObjects, findObjectInArray } from '../../utils';
import { DropdownsService } from '../../service/dropdowns.service';

@Component({
	selector: 'eui-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: DropdownComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: DropdownComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements ControlValueAccessor, Validator {
	@ViewChild('dropdown') public dropdown!: ElementRef;
	@ViewChild('dropdownOptions') public dropdownOptions!: ElementRef;
	@ViewChild(CdkPortal) public contentTemplate!: CdkPortal;
	private overlayRef!: OverlayRef;

	@Input() showChevronsWithCustomTrigger = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() customOptionsTemplateRef!: TemplateRef<any> | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() customTriggerTemplateRef!: TemplateRef<any> | null;

	@Input() disabled = false;
	@Input() multiple = false;
	@Input() displayField = '';
	@Input() placeholder = 'Placeholder';
	@Input() label = '';
	@Input() search = false;
	@Input() searchPlaceholder = 'Найти';
	@Input() error = '';
	@Input() description = '';
	@Input() compareField = '';
	@Input() actionsButtons = false;
	@Input() optionsFitContent = false;
	@Input() noBorder = false;
	@Input() disableOptionField = '';
	@Input() disableOptions = false;
	@Input() options: unknown[] = [];

	@Output() focusChange = new EventEmitter();

	private wrappedValue!: unknown | unknown[];

	get value() {
		return this.wrappedValue;
	}

	private set value(value: unknown | unknown[]) {
		if (equalsObjects(value, this.displaySelectedOptions)) {
			return;
		}

		this.wrappedValue = this.formatInputValue(value);

		this.cdr.markForCheck();
	}

	get displaySelectedOptions() {
		if (this.displayField) {
			return (
				this.selectedOptionsRaw
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					.map((x) => x[this.displayField])
					.join(', ')
			);
		} else {
			return this.selectedOptionsRaw.map((x) => x).join(', ');
		}
	}

	searchValue = '';
	opened = false;
	showDefaultOptionsTemplate = true;
	selectedOptionsRaw: unknown[] = [];
	// filteredOptions!: Observable<unknown[]>;
	currentSelectedOptions: unknown[] = [];

	touched = false;
	focused = false;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
	onChange = (value: unknown | unknown[]) => {};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	constructor(
		private cdr: ChangeDetectorRef,
		private overlay: Overlay,
		private dropdownsService: DropdownsService,
	) {}

	@HostListener('focusin')
	onFocusIn() {
		if (!this.focused) {
			this.focusChange.emit(true);
		}

		this.focused = true;
	}

	@HostListener('focusout')
	onFocusOut() {
		if (this.focused) {
			this.focusChange.emit(false);
		}

		this.focused = false;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	writeValue(value: unknown | unknown[]) {
		this.value = value;
	}

	validate(): ValidationErrors | null {
		return null;
	}

	trackByFn(index: number): number {
		return index;
	}

	displayFn(option: unknown): unknown {
		if (this.displayField) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return option[this.displayField];
		} else {
			return option;
		}
	}

	getVirtualScrollHeight() {
		let countOptions = this.options.length;
		if (this.multiple) {
			countOptions += 1;
		}

		// 36px высота по умолчанию
		return countOptions >= 5 ? '180px' : countOptions * 36 + 'px';
	}

	toggleAll() {
		this.markAsTouched();

		if (this.selectedOptionsRaw.length < this.options.length) {
			this.selectedOptionsRaw = this.options;
		} else {
			this.selectedOptionsRaw = [];
		}

		this.writeValue(this.selectedOptionsRaw);

		if (!this.actionsButtons) {
			this.onChange(this.value);
		}
	}

	selectedAll() {
		return this.selectedOptionsRaw.length > 0;
	}

	selectedAllIndeterminate() {
		return (
			0 < this.selectedOptionsRaw.length &&
			this.selectedOptionsRaw.length < this.options.length
		);
	}

	select(option: unknown) {
		this.markAsTouched();

		let newValue;

		if (this.multiple) {
			if (findObjectInArray(this.selectedOptionsRaw, option) !== undefined) {
				const index = this.selectedOptionsRaw.indexOf(option);
				this.selectedOptionsRaw.splice(index, 1);
			} else {
				this.selectedOptionsRaw.push(option);
			}

			newValue = this.selectedOptionsRaw;
		} else {
			this.selectedOptionsRaw = [option];
			this.closeDropdown();

			newValue = this.selectedOptionsRaw[0];
		}

		this.writeValue(newValue);

		if (!this.actionsButtons) {
			this.onChange(this.value);
		}
	}

	selected(option: unknown): boolean {
		return !!findObjectInArray(this.selectedOptionsRaw, option);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	toggleDropdown(e: any) {
		if (this.disabled) {
			return;
		}
		e.stopPropagation();

		if (this.opened) {
			this.closeDropdown();
		} else {
			this.openDropdown();
		}
	}

	saveChanges() {
		this.onChange(this.value);
		this.closeDropdown();
	}

	resetChanges() {
		this.value = [];
		this.onChange(this.value);
		this.closeDropdown();
	}

	hide() {
		if (this.actionsButtons && this.multiple) {
			this.selectedOptionsRaw = _.cloneDeep(this.currentSelectedOptions);
		}

		this.closeDropdown();
	}

	@HostListener('window:resize')
	onWindowResize(): void {
		this.syncWidth();
	}

	searchChangeHandler(value: string) {
		this.searchValue = value;
		this.cdr.detectChanges();
	}

	private openDropdown() {
		this.currentSelectedOptions = _.cloneDeep(this.selectedOptionsRaw);

		this.dropdownsService.bind(this);
		this.opened = true;

		this.overlayRef = this.overlay.create(this.getOverlayConfig());
		this.overlayRef.attach(this.contentTemplate);
		this.syncWidth();
		this.overlayRef.backdropClick().subscribe(() => this.hide());

		this.onFocusIn();

		this.cdr.detectChanges();
	}

	private closeDropdown() {
		this.dropdownsService.unbind(this);
		this.opened = false;
		this.searchValue = '';

		this.overlayRef.detach();

		this.onFocusOut();

		this.cdr.detectChanges();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private findOption(option: any): unknown {
		if (this.compareField && option) {
			return this.options?.find(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(x: any) => {
					const optionValue = option[this.compareField]
						? option[this.compareField]
						: option;

					return x[this.compareField].toString() === optionValue.toString();
				},
			);
		}

		return this.options?.find((x: unknown) => equalsObjects(x, option));
	}

	private findValueInOptions(value: unknown[]) {
		const options = [];

		for (const v of value) {
			const option = this.findOption(v);

			if (option) {
				options.push(option);
			}
		}

		return options;
	}

	private formatInputValue(value: unknown | unknown[]) {
		let formattedValue: unknown | unknown[] = [];

		if (value) {
			// Преобразуем 1 значение в массив, т.к. компонент работает с массивом
			formattedValue = Array.isArray(value) ? value : [value];
		}

		// Отбор значений из опций, т.к. входящее значение может отсутствовать в опциях
		formattedValue = this.findValueInOptions(formattedValue as []);

		this.selectedOptionsRaw = formattedValue as [];

		if (Array.isArray(formattedValue) && !this.multiple) {
			formattedValue = formattedValue[0];
		}

		return formattedValue;
	}

	private syncWidth(): void {
		if (!this.overlayRef) {
			return;
		}

		const refRectWidth =
			this.dropdown.nativeElement.getBoundingClientRect().width;
		this.overlayRef.updateSize({ width: refRectWidth });
	}

	private getOverlayConfig(): OverlayConfig {
		// TODO:
		// const x1 = 14;
		// const x2 = 45;
		// const padding = 4;
		// const offsetX = -((x2 / 2) + padding - (x1 / 2));

		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.dropdown.nativeElement)
			.withPush(true)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
					offsetY: 8,
					// offsetX: this.optionsFitContent ? offsetX : 0
				},
				{
					originX: 'start',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom',
					offsetY: -8,
					// offsetX: this.optionsFitContent ? offsetX : 0
				},
			]);

		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		return new OverlayConfig({
			positionStrategy: positionStrategy,
			scrollStrategy: scrollStrategy,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
	}
}
