import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToastComponent } from './toast.component';
import { IconsModule } from '../icons';
import { ActionButtonModule } from '../action-button';
import { IconButtonModule } from '../icon-button';
import { ToastService } from './toast.service';

@NgModule({
	declarations: [ToastComponent],
	imports: [CommonModule, IconsModule, ActionButtonModule, IconButtonModule],
	exports: [ToastComponent],
	providers: [ToastService],
})
export class ToastModule {}
