import { EuiIcon } from '../types/icon.interface';

export const notificationBellBadge: EuiIcon = {
	name: 'notification-bell-badge',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1195 1.78639C10.7817 2.15376 10.5168 2.58575 10.3468 3.06132C10.2386 3.02897 10.1211 3.01263 9.99568 3.01263C9.27412 3.01263 8.80456 3.56624 8.76647 4.38639C8.75081 4.61106 8.63535 4.70368 8.43512 4.75072C7.10663 5.10729 6.07764 6.19867 5.98495 9.4817C5.89886 11.0155 5.63184 12.1788 5.06332 12.8166C4.65826 13.2708 4.17832 13.704 3.96754 13.934V14.028H16.009V13.934C15.7967 13.704 15.3399 13.2708 14.9295 12.8166C14.3723 12.1788 14.1008 11.0155 14.0147 9.4817C14.0026 9.05488 13.9748 8.66511 13.9317 8.31088C13.9934 8.31885 14.0568 8.32033 14.1205 8.32033C14.6147 8.32033 15.0898 8.23035 15.5274 8.06214C15.5769 8.4478 15.6076 8.85641 15.6227 9.28499C15.7288 10.3977 15.8698 11.5119 16.4512 12.068C17.1915 12.7742 18 13.5104 18 14.3694C18 15.0539 17.4721 15.5059 16.6212 15.5059H13.2273C13.1614 17.0665 11.8731 18.4708 9.99568 18.4708C8.12656 18.4708 6.8383 17.0599 6.77233 15.5059H3.37842C2.52081 15.5059 1.99963 15.0539 1.99963 14.3694C1.99963 13.5104 2.80135 12.7742 3.54847 12.068C4.12984 11.5119 4.27086 10.3977 4.3769 9.28499C4.4851 6.22293 5.39006 4.18031 7.5465 3.419C7.85426 2.34136 8.77159 1.5293 9.99568 1.5293C10.4106 1.5293 10.7891 1.62137 11.1195 1.78639ZM8.32743 15.5059C8.38367 16.5251 9.06401 17.1742 9.99568 17.1742C10.9356 17.1742 11.6092 16.5251 11.6722 15.5059H8.32743Z" fill="#262626"/>
<circle cx="13.6515" cy="5.01135" r="2.5" fill="#F5222D"/>
</svg>
`,
};
