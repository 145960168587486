import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconsModule } from '../icons';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbsComponent } from './breadcrumbs.component';

@NgModule({
	imports: [CommonModule, RouterModule, IconsModule],
	declarations: [BreadcrumbsComponent, BreadcrumbComponent],
	exports: [BreadcrumbsComponent, BreadcrumbComponent],
})
export class BreadcrumbsModule {}
