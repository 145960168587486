import { EuiIcon } from '../types/icon.interface';

export const arrowLeftCircle: EuiIcon = {
	name: 'arrow-left-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.98959 3.98965C5.52695 2.4523 7.653 1.50006 10 1.50006C12.347 1.50006 14.4731 2.4523 16.0104 3.98965C17.5478 5.52701 18.5 7.65306 18.5 10.0001C18.5 12.3471 17.5478 14.4731 16.0104 16.0105C14.4731 17.5478 12.347 18.5001 10 18.5001C7.653 18.5001 5.52695 17.5478 3.98959 16.0105C2.45224 14.4731 1.5 12.3471 1.5 10.0001C1.5 7.65306 2.45224 5.52701 3.98959 3.98965ZM10 3.04552C8.07934 3.04552 6.34176 3.82308 5.08239 5.08245C3.82302 6.34183 3.04546 8.0794 3.04546 10.0001C3.04546 11.9207 3.82302 13.6583 5.08239 14.9177C6.34176 16.177 8.07934 16.9546 10 16.9546C11.9207 16.9546 13.6582 16.177 14.9176 14.9177C16.177 13.6583 16.9545 11.9207 16.9545 10.0001C16.9545 8.0794 16.177 6.34183 14.9176 5.08245C13.6582 3.82308 11.9207 3.04552 10 3.04552Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 10.0001C14 10.4143 13.6642 10.7501 13.25 10.7501H8.56066L9.78033 11.9697C10.0732 12.2626 10.0732 12.7375 9.78033 13.0304C9.48744 13.3233 9.01256 13.3233 8.71967 13.0304L6.21967 10.5304C5.92678 10.2375 5.92678 9.76262 6.21967 9.46973L8.71967 6.96973C9.01256 6.67684 9.48744 6.67684 9.78033 6.96973C10.0732 7.26262 10.0732 7.7375 9.78033 8.03039L8.56066 9.25006H13.25C13.6642 9.25006 14 9.58585 14 10.0001Z" fill="#262626"/>
  </svg>
  `,
};
