import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';

import { Menu } from './menu';

@Component({
	selector: 'eui-menu',
	template: `
		<ul class="eui-menu">
			<ng-content></ng-content>
		</ul>
	`,
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnDestroy {
	@Output() itemClicked = new EventEmitter<Menu>();

	// private show = false;
	private destroy$ = new Subject();

	constructor(private readonly hostElement: ElementRef) {}

	ngOnDestroy() {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	// toggleMenu() {
	// 	if (this.show) {
	// 		this.hideMenu();
	// 	} else {
	// 		this.showMenu();
	// 	}
	// }

	// showMenu() {
	// 	this.show = true;
	// 	this.hostElement.nativeElement.children[0].classList.add('selected');
	// }

	// hideMenu() {
	// 	this.show = false;
	// 	this.hostElement.nativeElement.children[0].classList.remove('selected');
	// }

	// TODO: triangle menu
	// was: any = null;
	// lastx: any = 0;
	// want_change: any = false;
	// deactivation_timeout: any = 0;
	// want_change_timeout: any = 0;
	// sub_opened: any = false;

	// activateNode(dom: any) {
	// 	this.want_change = false;
	// 	childsOfType(dom.parentNode, 'li').forEach((x) =>
	// 		x.classList.remove('selected'),
	// 	);
	// 	dom.classList.add('selected');
	// 	this.was = dom;
	// 	this.sub_opened = childsOfType(dom, 'ul').length > 0;
	// }

	// // @HostListener('mousemove', ['$event'])
	// mouseMoveHandler(e: any) {
	// 	let cur = e.target && e.target.tagName == 'SPAN' && e.target.parentNode;

	// 	if (!this.was && !cur) return;

	// 	clearTimeout(this.deactivation_timeout);
	// 	if (!cur) {
	// 		this.deactivation_timeout = setTimeout(() => {
	// 			while (this.was && this.was.id != 'someid') {
	// 				this.was.classList.remove('selected');
	// 				this.was = this.was.parentNode;
	// 				this.sub_opened = false;
	// 			}
	// 			this.was = null;
	// 			console.log('DEACTIVATED');
	// 		}, 300);
	// 		return;
	// 	}

	// 	// node changed
	// 	if (this.was != cur) {
	// 		this.want_change = true;

	// 		if (!this.was) {
	// 			console.log('ACTIVATE WAS=NULL');
	// 			return this.activateNode(cur);
	// 		} else if (isChild(cur, this.was)) {
	// 			console.log('ACTIVATE CHILD');
	// 			this.activateNode(cur);
	// 		} else if (isChild(this.was, cur)) {
	// 			console.log('ACTIVATE PARENT');
	// 			this.was.classList.remove('selected');
	// 			this.activateNode(cur);
	// 		} else if (this.sub_opened) {
	// 			clearTimeout(this.want_change_timeout);
	// 			if (e.clientX < this.lastx) {
	// 				console.log('ACTIVATE BY DISTANCE');
	// 				this.activateNode(cur);
	// 			} else {
	// 				this.want_change = cur;
	// 				this.want_change_timeout = setTimeout(() => {
	// 					console.log('CHANGE BECAUSE WANTED');
	// 					this.activateNode(cur);
	// 				}, 1000);
	// 			}
	// 		} else {
	// 			console.log('ACTIVATE CHILDLESS');
	// 			console.log(cur);

	// 			this.activateNode(cur);
	// 		}
	// 	}
	// 	this.lastx = e.clientX;
	// }
}
