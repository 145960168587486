import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-breadcrumb',
	template: `
		<a
			class="eui-breadcrumb"
			[ngClass]="{ active: active, dots: dots, subtler: subtler }"
			[routerLink]="[url]">
			<ng-content></ng-content>
		</a>
	`,
	styleUrls: ['./breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
	@Input() url = '/';
	@Input() active = false;
	@Input() dots = false;
	@Input() subtler = false;
}
