import { Injectable, Inject } from '@angular/core';
import {
	LOCALE_CONFIG,
	DefaultLocaleConfig,
	LocaleConfig,
} from './datepicker.config';

@Injectable()
export class LocaleService {
	constructor(@Inject(LOCALE_CONFIG) private configHolder: LocaleConfig) {}

	get config() {
		if (!this.configHolder) {
			return DefaultLocaleConfig;
		}
		return { ...DefaultLocaleConfig, ...this.configHolder };
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	configWithLocale(locale: any) {
		if (!this.configHolder && !locale) {
			return DefaultLocaleConfig;
		}

		return {
			...DefaultLocaleConfig,
			...{
				daysOfWeek: locale.weekdaysMin,
				monthNames: locale.monthsShort,
				firstDay: locale.weekStart,
			},
			...this.configHolder,
		};
	}
}
