import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IconsModule } from '../icons';
import { PaginatorComponent } from './paginator.component';
import { DropdownModule } from '../dropdown';

@NgModule({
	imports: [CommonModule, FormsModule, IconsModule, DropdownModule],
	exports: [PaginatorComponent],
	declarations: [PaginatorComponent],
})
export class PaginatorModule {}
