import { EuiIcon } from '../types/icon.interface';

export const domclick: EuiIcon = {
	name: 'domclick',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9372 17.9068H4.14307C3.92728 17.9112 3.71281 17.8722 3.51246 17.7919C3.31211 17.7116 3.12997 17.5918 2.97692 17.4397C2.82386 17.2875 2.70303 17.106 2.62163 16.9062C2.54022 16.7063 2.49991 16.492 2.50311 16.2762V7.48134C2.50311 6.80661 2.84984 6.12251 3.4168 5.77109L8.82867 2.44432C9.53151 1.97576 10.4686 1.97576 11.1715 2.44432L16.6489 5.86481C17.2112 6.22091 17.497 6.92375 17.497 7.59848V10.8784L10.9372 17.9068ZM5.78303 14.6269H11.4057L14.2171 11.5812V8.5356L10.0001 5.95852L5.78303 8.5356V14.6269Z" fill="url(#paint0_linear_3925_991)"/>
<path d="M10.9372 17.9068L10.9372 12.7244C10.9372 11.553 11.64 10.8783 12.8114 10.8783L17.497 10.8784L10.9372 17.9068Z" fill="#FBBC33"/>
<defs>
<linearGradient id="paint0_linear_3925_991" x1="2.83578" y1="5.19945" x2="14.5685" y2="15.6765" gradientUnits="userSpaceOnUse">
<stop stop-color="#6BBD3F"/>
<stop offset="0.62" stop-color="#209A73"/>
<stop offset="1" stop-color="#0276A2"/>
</linearGradient>
</defs>
</svg>
`,
};
