import { EuiIcon } from '../types/icon.interface';

export const refreshCw: EuiIcon = {
	name: 'refresh-cw',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.2499 1.49994C16.6642 1.49994 16.9999 1.83573 16.9999 2.24994V5.24994C16.9999 5.66415 16.6642 5.99994 16.2499 5.99994H13.2499C12.8357 5.99994 12.4999 5.66415 12.4999 5.24994C12.4999 4.83573 12.8357 4.49994 13.2499 4.49994H14.3313C13.0649 3.49985 11.5333 2.99994 10 2.99994C8.20738 2.99994 6.41723 3.68321 5.05025 5.05019C3.68327 6.41718 3 8.20732 3 9.99994C3 10.3498 3.02602 10.6995 3.07807 11.046C3.15055 11.5286 2.80021 11.9999 2.31222 11.9999C1.97509 11.9999 1.67852 11.7695 1.62178 11.4371C1.54059 10.9617 1.5 10.4808 1.5 9.99994C1.5 7.82576 2.33001 5.64912 3.98959 3.98953C5.64917 2.32995 7.82582 1.49994 10 1.49994C11.9577 1.49994 13.9175 2.17293 15.4999 3.51859V2.24994C15.4999 1.83573 15.8357 1.49994 16.2499 1.49994Z" fill="#262626"/>
  <path d="M2.99994 14.7499C2.99994 14.3357 3.33573 13.9999 3.74994 13.9999H6.74994C7.16415 13.9999 7.49994 14.3357 7.49994 14.7499C7.49994 15.1642 7.16415 15.4999 6.74994 15.4999H5.6687C6.93514 16.5 8.46667 16.9999 10 16.9999C11.7926 16.9999 13.5828 16.3167 14.9497 14.9497C16.3167 13.5827 17 11.7926 17 9.99994C17 9.65011 16.974 9.30036 16.9219 8.95384C16.8494 8.47127 17.1998 7.99994 17.6878 7.99994C18.0249 7.99994 18.3215 8.23042 18.3782 8.56273C18.4594 9.03818 18.5 9.51912 18.5 9.99994C18.5 12.1741 17.67 14.3508 16.0104 16.0103C14.3508 17.6699 12.1742 18.4999 10 18.4999C8.0422 18.4999 6.0824 17.8269 4.49994 16.4812V17.7499C4.49994 18.1642 4.16415 18.4999 3.74994 18.4999C3.33573 18.4999 2.99994 18.1642 2.99994 17.7499V14.7499Z" fill="#262626"/>
  </svg>
  `,
};
