import { EuiIcon } from '../types/icon.interface';

export const questionmarkBubbleOutline: EuiIcon = {
	name: 'questionmark-bubble-outline',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.94897 6.56604C9.06665 6.10962 9.48169 5.77307 9.97388 5.77307C10.5579 5.77307 11.0315 6.2467 11.0315 6.83096C11.0315 7.28702 10.8445 7.495 10.4661 7.84799L10.4089 7.90115C10.0251 8.25742 9.47974 8.76331 9.34546 9.70331C9.29565 10.0504 9.53687 10.3719 9.88403 10.4214C10.2307 10.471 10.5525 10.2299 10.6018 9.88284C10.6721 9.39233 10.9211 9.16006 11.3254 8.7825L11.3323 8.77606C11.7478 8.38855 12.301 7.85599 12.301 6.83096C12.301 5.54559 11.259 4.5036 9.97388 4.5036C8.88843 4.5036 7.97778 5.24591 7.71948 6.2496C7.63208 6.58908 7.83667 6.93515 8.17603 7.02252C8.51587 7.10989 8.86157 6.90552 8.94897 6.56604Z" fill="#262626"/>
<path d="M10.8201 11.7058C10.8201 12.1732 10.4412 12.5521 9.97388 12.5521C9.5061 12.5521 9.1272 12.1732 9.1272 11.7058C9.1272 11.2384 9.5061 10.8595 9.97388 10.8595C10.4412 10.8595 10.8201 11.2384 10.8201 11.7058Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.66821 17.2545C7.14673 17.7118 6.83569 17.9167 6.41675 17.9167C5.81128 17.9167 5.46216 17.4935 5.46216 16.8293V14.9762H5.15259C2.96704 14.9762 1.66675 13.6823 1.66675 11.445V5.61453C1.66675 3.3772 2.93579 2.08337 5.19995 2.08337H14.7991C17.0642 2.08337 18.3333 3.38336 18.3333 5.61453V11.445C18.3333 13.6761 17.0642 14.9762 14.7991 14.9762H10.2439L7.66821 17.2545ZM6.78198 14.1145V16.2609L9.17114 13.8985C9.4895 13.5769 9.70728 13.5032 10.1638 13.5032H14.7581C16.1746 13.5032 16.8591 12.7804 16.8591 11.3982V5.65515C16.8591 4.27304 16.1746 3.55627 14.7581 3.55627H5.24194C3.81958 3.55627 3.14087 4.27304 3.14087 5.65515V11.3982C3.14087 12.7804 3.81958 13.5032 5.24194 13.5032H6.17017C6.59302 13.5032 6.78198 13.6734 6.78198 14.1145Z" fill="#262626"/>
</svg>
`,
};
