import { EuiIcon } from '../types/icon.interface';

export const arrowUpCircle: EuiIcon = {
	name: 'arrow-up-circle',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.98959 3.98965C5.52695 2.4523 7.653 1.50006 10 1.50006C12.347 1.50006 14.4731 2.4523 16.0104 3.98965C17.5478 5.52701 18.5 7.65306 18.5 10.0001C18.5 12.3471 17.5478 14.4731 16.0104 16.0105C14.4731 17.5478 12.347 18.5001 10 18.5001C7.653 18.5001 5.52695 17.5478 3.98959 16.0105C2.45224 14.4731 1.5 12.3471 1.5 10.0001C1.5 7.65306 2.45224 5.52701 3.98959 3.98965ZM10 3.04552C8.07934 3.04552 6.34176 3.82308 5.08239 5.08245C3.82302 6.34183 3.04546 8.0794 3.04546 10.0001C3.04546 11.9207 3.82302 13.6583 5.08239 14.9177C6.34176 16.177 8.07934 16.9546 10 16.9546C11.9207 16.9546 13.6582 16.177 14.9176 14.9177C16.177 13.6583 16.9545 11.9207 16.9545 10.0001C16.9545 8.0794 16.177 6.34183 14.9176 5.08245C13.6582 3.82308 11.9207 3.04552 10 3.04552Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 14.0001C9.58579 14.0001 9.25 13.6643 9.25 13.2501V8.56072L8.03033 9.78039C7.73744 10.0733 7.26256 10.0733 6.96967 9.78039C6.67678 9.4875 6.67678 9.01262 6.96967 8.71973L9.46967 6.21973C9.76256 5.92684 10.2374 5.92684 10.5303 6.21973L13.0303 8.71973C13.3232 9.01262 13.3232 9.4875 13.0303 9.78039C12.7374 10.0733 12.2626 10.0733 11.9697 9.78039L10.75 8.56072V13.2501C10.75 13.6643 10.4142 14.0001 10 14.0001Z" fill="#262626"/>
  </svg>
  `,
};
