import { EuiIcon } from '../types/icon.interface';

export const meet: EuiIcon = {
	name: 'meet',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.54266 7.2868V12.7131H10.9689V7.2868H5.54266Z" fill="white"/>
<path d="M1.66675 7.2868V12.7131L5.54266 12.7131V7.2868L1.66675 7.2868Z" fill="#1E88E5"/>
<path d="M14.8449 9.99994V15.4262C14.8449 16.0685 14.3243 16.589 13.6821 16.589H5.54266L5.54266 12.7131H10.9689V9.99994H14.8449Z" fill="#4CAF50"/>
<path d="M14.8448 4.57366L14.8449 9.99994H10.9689V7.2868H5.54266L5.54266 3.41089H13.6821C14.3243 3.41089 14.8448 3.93142 14.8448 4.57366Z" fill="#FBC02D"/>
<path d="M5.54266 12.7131L5.54266 16.589H2.82952C2.18728 16.589 1.66675 16.0685 1.66675 15.4262V12.7131L5.54266 12.7131Z" fill="#1565C0"/>
<path d="M5.54266 3.41089L5.54266 7.2868L1.66675 7.2868L5.54266 3.41089Z" fill="#E53935"/>
<path d="M14.8449 9.99994L14.8449 13.2751L10.9689 9.99994L14.8449 6.72479L14.8449 9.99994Z" fill="#2E7D32"/>
<path d="M18.3332 4.6163V15.3836C18.3332 15.7092 17.9533 15.8913 17.7014 15.6859L14.8449 13.2751V6.72479L17.7014 4.31398C17.9533 4.10855 18.3332 4.29072 18.3332 4.6163Z" fill="#4CAF50"/>
</svg>
`,
};
