import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';

@Component({
	selector: 'eui-input-number',
	template: `<div
		class="eui-input-number"
		[ngClass]="{ 'eui-input-number__disabled': disabled }">
		<button
			euiIconButton
			color="transparent"
			size="sm"
			[disabled]="disabled || value === min"
			(click)="onRemove()">
			<eui-icons name="minus"></eui-icons>
		</button>

		<input
			#inputRef
			class="eui-input-number__value"
			euiDigitOnly
			[disabled]="disabled"
			[(ngModel)]="value"
			[maxlength]="3"
			[attr.min]="min"
			[attr.max]="max"
			(ngModelChange)="valueChangeHandler($event)" />

		<button
			euiIconButton
			color="transparent"
			size="sm"
			[disabled]="disabled || value === max"
			(click)="onAdd()">
			<eui-icons name="plus"></eui-icons>
		</button>
	</div>`,
	styleUrls: ['./input-number.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: InputNumberComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: InputNumberComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent
	implements AfterViewInit, ControlValueAccessor, Validator
{
	@ViewChild('inputRef') inputRef!: ElementRef;

	@Input() increment = 1;

	@Input() disabled = false;

	@Input() min = 0;

	@Input() max = 999;

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	onChange = (value: number) => {};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	touched = false;
	value = 0;

	ngAfterViewInit() {
		if (this.value !== null) {
			this.changeInputSize(this.value);
		}
	}

	valueChangeHandler(e: number) {
		this.changeInputSize(e);
	}

	private changeInputSize(e: number) {
		this.inputRef.nativeElement.setAttribute(
			'size',
			e.toString().length > 0 ? e.toString().length : 1,
		);
	}

	onAdd() {
		this.markAsTouched();
		if (!this.disabled) {
			const newValue = this.value + this.increment;
			if (newValue > this.max) {
				this.value = this.max;
			} else {
				this.value = +this.value + this.increment;
			}

			this.changeInputSize(this.value);
			this.onChange(this.value);
		}
	}

	onRemove() {
		this.markAsTouched();
		if (!this.disabled) {
			const newValue = this.value - this.increment;
			if (newValue < this.min) {
				this.value = this.min;
			} else {
				this.value = +this.value - this.increment;
			}

			this.changeInputSize(this.value);
			this.onChange(this.value);
		}
	}

	writeValue(quantity: number) {
		this.value = quantity;

		if (this.inputRef) {
			this.changeInputSize(this.value);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const value = control.value;
		if (value <= 0) {
			return {
				mustBePositive: {
					value,
				},
			};
		}

		return null;
	}
}
