import { Overlay, ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { DialogRef } from './types/dialog-ref';
import { DIALOG_CONFIG, DIALOG_DATA } from './types/dialog-tokens';

export interface DialogConfig {
	data?: unknown;
	position?: 'right' | 'left' | 'center';
	hasBackdrop?: boolean;
}

@Injectable()
export class DialogService {
	constructor(private overlay: Overlay, private injector: Injector) {}

	/**
	 * Open a custom component in an overlay
	 */
	open<T>(component: ComponentType<T>, config?: DialogConfig): DialogRef {
		// Globally centered position strategy
		let positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		if (config && config.position) {
			if (config.position === 'right') {
				positionStrategy = this.overlay
					.position()
					.global()
					.right()
					.centerVertically();
			} else if (config.position === 'left') {
				positionStrategy = this.overlay
					.position()
					.global()
					.left()
					.centerVertically();
			}
		}

		// Create the overlay with customizable options
		const overlayRef = this.overlay.create({
			positionStrategy,
			hasBackdrop:
				config?.hasBackdrop !== undefined ? config.hasBackdrop : true,
			backdropClass: 'eui-overlay-backdrop',
			panelClass: 'eui-overlay-panel',
		});

		// Create dialogRef to return
		const dialogRef = new DialogRef(overlayRef);

		// Create injector to be able to reference the DialogRef from within the component
		const injector = Injector.create({
			parent: this.injector,
			providers: [
				{ provide: DialogRef, useValue: dialogRef },
				{ provide: DIALOG_DATA, useValue: config?.data },
				{ provide: DIALOG_CONFIG, useValue: config },
			],
		});

		// Attach component portal to the overlay
		const portal = new ComponentPortal(component, null, injector);
		overlayRef.attach(portal);

		document.body.style.overflow = 'hidden';

		return dialogRef;
	}
}
