import { EuiIcon } from '../types/icon.interface';

export const settings2: EuiIcon = {
	name: 'settings-2',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8513 7.78552C13.9827 7.78552 14.9377 7.03455 15.2463 6.00391H17.219C17.5959 6.00391 17.9167 5.67651 17.9167 5.28235C17.9167 4.88416 17.5974 4.56714 17.219 4.56714H15.2463C14.9377 3.5365 13.9827 2.78552 12.8513 2.78552C11.72 2.78552 10.7649 3.5365 10.4563 4.56714H2.80005C2.39771 4.56714 2.08325 4.88684 2.08325 5.28235C2.08325 5.67383 2.39917 6.00391 2.80005 6.00391H10.4563C10.7649 7.03455 11.72 7.78552 12.8513 7.78552ZM11.8513 5.28552C11.8513 5.83777 12.2991 6.28552 12.8513 6.28552C13.4036 6.28552 13.8513 5.83777 13.8513 5.28552C13.8513 4.73328 13.4036 4.28552 12.8513 4.28552C12.2991 4.28552 11.8513 4.73328 11.8513 5.28552Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.77466 9.27917C2.39771 9.27917 2.08325 9.6051 2.08325 10.0006C2.08325 10.3907 2.39917 10.7159 2.77466 10.7159H4.87183C5.18042 11.7466 6.1355 12.4976 7.26685 12.4976C8.39819 12.4976 9.35327 11.7466 9.66187 10.7159H17.1936C17.5959 10.7159 17.9167 10.3948 17.9167 10.0006C17.9167 9.60107 17.5974 9.27917 17.1936 9.27917H9.66187C9.35327 8.24854 8.39819 7.49756 7.26685 7.49756C6.1355 7.49756 5.18042 8.24854 4.87183 9.27917H2.77466ZM7.26685 10.9976C6.7146 10.9976 6.26685 10.5498 6.26685 9.99756C6.26685 9.44531 6.7146 8.99756 7.26685 8.99756C7.81909 8.99756 8.26685 9.44531 8.26685 9.99756C8.26685 10.5498 7.81909 10.9976 7.26685 10.9976Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4563 13.9961H2.80005C2.6062 13.9961 2.43286 14.0718 2.30493 14.1946C2.16772 14.3265 2.08325 14.5127 2.08325 14.7175C2.08325 15.1089 2.39917 15.4329 2.80005 15.4329H10.4563C10.7649 16.4635 11.72 17.2145 12.8513 17.2145C13.9827 17.2145 14.9377 16.4635 15.2463 15.4329H17.219C17.5959 15.4329 17.9167 15.1117 17.9167 14.7175C17.9167 14.3192 17.5974 13.9961 17.219 13.9961H15.2463C14.9377 12.9655 13.9827 12.2145 12.8513 12.2145C11.72 12.2145 10.7649 12.9655 10.4563 13.9961ZM12.8513 15.7145C12.2991 15.7145 11.8513 15.2667 11.8513 14.7145C11.8513 14.1622 12.2991 13.7145 12.8513 13.7145C13.4036 13.7145 13.8513 14.1622 13.8513 14.7145C13.8513 15.2667 13.4036 15.7145 12.8513 15.7145Z" fill="#262626"/>
  </svg>
  `,
};
