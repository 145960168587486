import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-dot',
	template: `<div
		class="eui-dot"
		[ngClass]="{
			'eui-dot__sm': size === 'sm',
			'eui-dot__md': size === 'md',
			'eui-dot__lg': size === 'lg',

			'eui-dot__red': color === 'red',
			'eui-dot__green': color === 'green',
			'eui-dot__yellow': color === 'yellow',
			'eui-dot__gray': color === 'gray'
		}"></div>`,
	styleUrls: ['./dot.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotComponent {
	@Input() size: 'sm' | 'md' | 'lg' = 'sm';
	@Input() color: 'red' | 'green' | 'yellow' | 'gray' = 'gray';
}
