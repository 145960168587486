import { EuiIcon } from '../types/icon.interface';

export const edit3: EuiIcon = {
	name: 'edit-3',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3045 3.3389C11.8329 3.3389 11.3591 3.51911 10.9987 3.87916L9.3432 5.53302L2.5793 12.3044C2.06232 12.8208 1.77304 13.522 1.77602 14.2525L1.77603 15.8285C1.77603 16.2883 2.14881 16.6611 2.60867 16.6611H4.1987C4.92601 16.6611 5.62353 16.3725 6.13783 15.8589L13.7732 8.21668L14.5573 7.43351C14.9178 7.07345 15.0982 6.60029 15.0982 6.12919C15.0982 5.65809 14.9178 5.18493 14.5573 4.82488L13.6104 3.87916C13.2499 3.51911 12.7762 3.3389 12.3045 3.3389ZM13.6736 6.55089L12.9016 7.32193L11.1104 5.53282L11.8823 4.76178C11.999 4.64521 12.1508 4.58711 12.3045 4.58711C12.4583 4.58711 12.61 4.64521 12.7267 4.76178L13.6736 5.7075C13.7903 5.82407 13.8485 5.9756 13.8485 6.12919C13.8485 6.28278 13.7903 6.43432 13.6736 6.55089ZM3.46296 13.1871L10.2147 6.42777L12.006 8.21688L5.25418 14.9762C4.97424 15.2558 4.59458 15.4129 4.1987 15.4129H3.27491C3.13689 15.4129 3.02503 15.301 3.02512 15.1629L3.02572 14.2414C3.02572 13.8459 3.183 13.4667 3.46296 13.1871Z" fill="#262626"/>
  <path d="M17.5066 16.6611H6.27256L7.86122 15.1623H17.5066C17.9014 15.1623 18.2241 15.5032 18.2241 15.9142C18.2241 16.3251 17.9014 16.6611 17.5066 16.6611Z" fill="#262626"/>
  </svg>
  `,
};
