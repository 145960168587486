import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DotComponent } from './dot.component';

@NgModule({
	imports: [CommonModule],
	exports: [DotComponent],
	declarations: [DotComponent],
})
export class DotModule {}
