import { EuiIcon } from '../types/icon.interface';

export const headsetMic: EuiIcon = {
	name: 'headset-mic',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.49988C2 5.0816 5.58172 1.49988 10 1.49988C14.4183 1.49988 18 5.0816 18 9.49988V13.2499C18 14.7687 16.7688 15.9999 15.25 15.9999C13.7312 15.9999 12.5 14.7687 12.5 13.2499V10.7499C12.5 9.23109 13.7312 7.99988 15.25 7.99988C15.6521 7.99988 16.034 8.08617 16.3782 8.24123C15.7921 5.25374 13.1592 2.99988 10 2.99988C6.84076 2.99988 4.20794 5.25374 3.62177 8.24123C3.96602 8.08616 4.34793 7.99988 4.75 7.99988C6.26878 7.99988 7.5 9.23109 7.5 10.7499V13.2499C7.5 14.7687 6.26878 15.9999 4.75 15.9999C3.23122 15.9999 2 14.7687 2 13.2499V9.49988ZM3.5 10.7499V13.2499C3.5 13.9402 4.05964 14.4999 4.75 14.4999C5.44036 14.4999 6 13.9402 6 13.2499V10.7499C6 10.0595 5.44036 9.49988 4.75 9.49988C4.05964 9.49988 3.5 10.0595 3.5 10.7499ZM16.5 10.7499C16.5 10.0595 15.9404 9.49988 15.25 9.49988C14.5596 9.49988 14 10.0595 14 10.7499V13.2499C14 13.9402 14.5596 14.4999 15.25 14.4999C15.9404 14.4999 16.5 13.9402 16.5 13.2499V10.7499Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8004 15.2858L3.77602 15.1957L5.22398 14.804L5.24835 14.8941C5.52382 15.9124 5.6181 16.2078 5.77765 16.415C5.93799 16.6233 6.15023 16.7857 6.39311 16.8861C6.63479 16.986 6.94458 16.9999 7.99946 16.9999H8.28508L8.35668 16.8567C8.36188 16.8463 8.36746 16.8349 8.37344 16.8228C8.43171 16.7043 8.52778 16.5089 8.68298 16.3569C8.81535 16.2272 8.97488 16.1286 9.15004 16.0682C9.35541 15.9974 9.57313 15.9988 9.70521 15.9997C9.71876 15.9998 9.73141 15.9999 9.74305 15.9999H10.75C11.4403 15.9999 12 16.5595 12 17.2499C12 17.9402 11.4403 18.4999 10.75 18.4999H7.99946C7.9515 18.4999 7.9042 18.4999 7.85754 18.5C7.01053 18.5006 6.37358 18.5012 5.82008 18.2724C5.33432 18.0716 4.90983 17.7466 4.58915 17.3302C4.22375 16.8556 4.05791 16.2406 3.83739 15.4228C3.82524 15.3778 3.81292 15.3321 3.8004 15.2858Z" fill="#262626"/>
  </svg>
  `,
};
