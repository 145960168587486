import { EuiIcon } from '../types/icon.interface';

export const downloadCloud: EuiIcon = {
	name: 'download-cloud',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.49841 6.19463L5.35372 6.91513L4.65378 7.13907C3.64479 7.46188 2.91675 8.40798 2.91675 9.52085C2.91675 10.9016 4.03604 12.0209 5.41675 12.0209L5.83341 12.0212C6.17822 12.027 6.45595 12.3046 6.45595 12.6462C6.45595 12.9913 6.17235 13.2712 5.82251 13.2712H5.68581L5.41675 13.2709C3.34568 13.2709 1.66675 11.5919 1.66675 9.52085C1.66675 7.84869 2.76122 6.43215 4.27288 5.94851C4.7705 3.47061 6.95899 1.60419 9.58341 1.60419C11.845 1.60419 13.7829 2.9902 14.5939 4.95915C16.6944 5.17305 18.3334 6.94722 18.3334 9.10419C18.3334 11.3832 16.5038 13.2348 14.2333 13.2703C13.905 13.2755 13.567 12.9802 13.567 12.6462C13.567 12.3121 13.7818 12.0381 14.116 12.0209H14.1667C15.7776 12.0209 17.0834 10.715 17.0834 9.10419C17.0834 7.59508 15.9363 6.35231 14.4673 6.20272L13.723 6.12693L13.4381 5.43524C12.8133 3.91829 11.3211 2.85419 9.58341 2.85419C7.56607 2.85419 5.88109 4.2891 5.49841 6.19463Z" fill="#262626"/>
<path d="M10.0001 10.0958C10.3591 10.0958 10.6501 10.3868 10.6501 10.7458V16.1766L12.5405 14.2862C12.7943 14.0324 13.2059 14.0324 13.4597 14.2862C13.7136 14.54 13.7136 14.9516 13.4597 15.2054L10.4597 18.2054C10.2059 18.4593 9.79432 18.4593 9.54048 18.2054L6.54048 15.2054C6.28664 14.9516 6.28664 14.54 6.54048 14.2862C6.79432 14.0324 7.20588 14.0324 7.45972 14.2862L9.3501 16.1766V10.7458C9.3501 10.3868 9.64111 10.0958 10.0001 10.0958Z" fill="#262626"/>
</svg>
`,
};
