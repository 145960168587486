import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[euiTableInputCell]',
})
export class TableInputCellDirective {
	constructor(private el: ElementRef) {
		this.el.nativeElement.classList.add('eui-table__input-cell');
	}
}
