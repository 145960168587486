import { EuiIcon } from '../types/icon.interface';

export const browserWindowCursor: EuiIcon = {
	name: 'browser-window-cursor',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5043 5.56038V10.0357L16.2635 8.7637V6.7844H3.74073V13.1635C3.74073 13.7789 4.06573 14.0837 4.65571 14.0837H13.9562L13.9385 15.3244H4.57857C3.21574 15.3244 2.5 14.6242 2.5 13.2678V5.56038C2.5 4.20274 3.21574 3.50372 4.57857 3.50372H15.4269C16.7948 3.50372 17.5043 4.20794 17.5043 5.56038ZM4.10006 5.14293C4.10006 5.45653 4.36477 5.73163 4.69395 5.73163C5.01795 5.73163 5.28152 5.45653 5.28152 5.14293C5.28152 4.82932 5.01795 4.55536 4.69395 4.55536C4.36477 4.55536 4.10006 4.82932 4.10006 5.14293ZM5.96661 5.14293C5.96661 5.45653 6.23019 5.73163 6.56049 5.73163C6.88449 5.73163 7.1481 5.45653 7.1481 5.14293C7.1481 4.82932 6.88449 4.55536 6.56049 4.55536C6.23019 4.55536 5.96661 4.82932 5.96661 5.14293ZM7.83314 5.14293C7.83314 5.45653 8.09675 5.73163 8.42189 5.73163C8.74994 5.73163 9.01461 5.45653 9.01461 5.14293C9.01461 4.82932 8.74994 4.55536 8.42189 4.55536C8.09675 4.55536 7.83314 4.82932 7.83314 5.14293Z" fill="#262626"/>
<path d="M14.8481 14.4459C14.8418 14.7299 15.1752 14.8305 15.3609 14.6379L16.2803 13.6986L17.3323 16.3027C17.4008 16.4527 17.5468 16.537 17.7075 16.4767L18.3516 16.2234C18.5146 16.158 18.543 15.9782 18.4814 15.8453L17.3695 13.273L18.6923 13.2366C18.9729 13.2308 19.1062 12.9549 18.9078 12.745L15.3886 9.1438C15.1988 8.95808 14.9205 9.06387 14.9131 9.33348L14.8481 14.4459Z" fill="#262626"/>
</svg>
`,
};
