import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { ToastService } from './toast.service';

@Component({
	selector: 'eui-toast',
	template: `
		<div class="eui-toast">
			<div class="eui-toast__title">{{ text }}</div>
			@if (actionText) {
				<div class="eui-toast__action">
					<button euiActionButton color="primary" (click)="action()">
						{{ actionText }}
					</button>
				</div>
			}
			<div class="eui-toast__close">
				<eui-icons name="close" (click)="close()"></eui-icons>
			</div>
		</div>
	`,
	styleUrl: './toast.component.scss',
	animations: [
		trigger('toast', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(100%)' }),
				animate(
					'200ms ease-in-out',
					style({ opacity: 1, transform: 'translateX(0)' }),
				),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateX(0)' }),
				animate(
					'200ms ease-in-out',
					style({ opacity: 0, transform: 'translateY(100%)' }),
				),
			]),
		]),
	],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { '[@toast]': 'in' },
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
	@Input() text = '';
	@Input() actionText: string | undefined;
	@Input() action = () => {};

	constructor(private toastRef: ToastService) {}

	close() {
		this.toastRef.close();
	}
}
