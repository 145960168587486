import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

@Component({
	selector: 'eui-expand-button',
	templateUrl: './expand-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandButtonComponent {
	@Input() context: unknown;
	@Input() expanded = false;

	@Output() expandClick = new EventEmitter();

	onExpandClick(e: Event) {
		e.stopPropagation();

		this.expanded = !this.expanded;
		this.expandClick.emit({ expanded: this.expanded, context: this.context });
	}
}
