import {
	Overlay,
	OverlayRef,
	OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { ToastComponent } from './toast.component';
import { ToastConfig } from './toast-config.type';

@Injectable()
export class ToastService {
	private overlayRef!: OverlayRef;
	private timer!: NodeJS.Timeout;

	private DEFAULT_TIMEOUT = 3000;

	constructor(
		private overlay: Overlay,
		private overlayPositionBuilder: OverlayPositionBuilder,
		private injector: Injector,
	) {}

	open(config: Partial<ToastConfig>) {
		this.close();

		const positionStrategy = this.overlayPositionBuilder
			.global()
			.centerHorizontally()
			.bottom('40px');

		this.overlayRef = this.overlay.create({ positionStrategy });

		const injector = Injector.create({
			parent: this.injector,
			providers: [],
		});

		const toastRefPortal: ComponentRef<ToastComponent> = this.overlayRef.attach(
			new ComponentPortal(ToastComponent, null, injector),
		);

		toastRefPortal.instance.text = config.text || 'New Toast';
		toastRefPortal.instance.actionText = config.actionText || '';

		if (config.action) {
			toastRefPortal.instance.action = config.action;
		}

		this.timer = setTimeout(() => {
			this.close();
		}, config.timeout || this.DEFAULT_TIMEOUT);
	}

	close() {
		if (this.overlayRef) {
			if (this.timer) {
				clearTimeout(this.timer);
			}

			this.overlayRef.detach();
		}
	}
}
