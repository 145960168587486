import { EuiIcon } from '../types/icon.interface';

export const externalLink: EuiIcon = {
	name: 'external-link',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.31739 2C7.36495 1.99999 6.6044 1.99999 5.99014 2.05018C5.36012 2.10165 4.81824 2.20963 4.32054 2.46322C3.52085 2.87068 2.87068 3.52085 2.46322 4.32054C2.20963 4.81824 2.10165 5.36012 2.05018 5.99013C1.99999 6.60439 1.99999 7.36494 2 8.31738V11.6826C1.99999 12.6351 1.99999 13.3956 2.05018 14.0099C2.10165 14.6399 2.20963 15.1818 2.46322 15.6795C2.87068 16.4791 3.52085 17.1293 4.32054 17.5368C4.81824 17.7904 5.36012 17.8984 5.99014 17.9498C6.60439 18 7.36493 18 8.31737 18H11.6826C12.6351 18 13.3956 18 14.0099 17.9498C14.6399 17.8984 15.1818 17.7904 15.6795 17.5368C16.4791 17.1293 17.1293 16.4791 17.5368 15.6795C17.7904 15.1818 17.8984 14.6399 17.9498 14.0099C18 13.3956 18 12.6351 18 11.6826V10.25C18 9.83579 17.6642 9.5 17.25 9.5C16.8358 9.5 16.5 9.83579 16.5 10.25V11.65C16.5 12.6425 16.4994 13.3417 16.4548 13.8877C16.4109 14.425 16.3282 14.7475 16.2003 14.9985C15.9366 15.5159 15.5159 15.9366 14.9985 16.2003C14.7475 16.3282 14.425 16.4109 13.8877 16.4548C13.3417 16.4994 12.6425 16.5 11.65 16.5H8.35C7.35753 16.5 6.65829 16.4994 6.11228 16.4548C5.57503 16.4109 5.25252 16.3282 5.00153 16.2003C4.48408 15.9366 4.06338 15.5159 3.79973 14.9985C3.67184 14.7475 3.58909 14.425 3.5452 13.8877C3.50059 13.3417 3.5 12.6425 3.5 11.65V8.35C3.5 7.35753 3.50059 6.65829 3.5452 6.11228C3.58909 5.57503 3.67184 5.25252 3.79973 5.00153C4.06338 4.48408 4.48408 4.06338 5.00153 3.79973C5.25252 3.67184 5.57503 3.58909 6.11228 3.54519C6.65829 3.50058 7.35753 3.5 8.35 3.5H9.75C10.1642 3.5 10.5 3.16421 10.5 2.75C10.5 2.33579 10.1642 2 9.75 2H8.31739Z" fill="#262626"/>
  <path d="M12.75 2C12.3358 2 12 2.33579 12 2.75C12 3.16421 12.3358 3.5 12.75 3.5H15.4393L9.75 9.18934C9.45711 9.48223 9.45711 9.95711 9.75 10.25C10.0429 10.5429 10.5178 10.5429 10.8107 10.25L16.5 4.56066V7.25C16.5 7.66421 16.8358 8 17.25 8C17.6642 8 18 7.66421 18 7.25V2.75C18 2.33579 17.6642 2 17.25 2H12.75Z" fill="#262626"/>
  </svg>
  `,
};
