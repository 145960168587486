import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-progress-bar',
	template: `<div class="eui-progress-bar">
		<span
			class="eui-progress-bar__inner"
			[ngStyle]="{ width: value + '%' }"></span>
	</div>`,
	styleUrls: ['./progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
	wrappedValue = 0;

	@Input() set value(value: number) {
		if (value < 0) {
			this.wrappedValue = 0;
		} else if (value > 100) {
			this.wrappedValue = 100;
		} else {
			this.wrappedValue = value;
		}
	}

	get value() {
		return this.wrappedValue;
	}
}
