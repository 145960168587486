import { Directive, OnInit, Renderer2, Input, ElementRef } from '@angular/core';

@Directive({
	selector: '[euiResizeColumn]',
})
export class ResizeColumnDirective implements OnInit {
	@Input() resizable!: boolean;
	@Input() columnIndex!: number;

	private readonly column!: HTMLElement;
	private table!: HTMLElement;
	private resizer!: HTMLElement;
	private pressed!: boolean;
	private startX!: number;
	private startWidth!: number;

	constructor(private renderer: Renderer2, private el: ElementRef) {
		this.column = this.el.nativeElement;
	}

	ngOnInit() {
		if (this.resizable) {
			const row = this.renderer.parentNode(this.column);
			const thead = this.renderer.parentNode(row);
			this.table = this.renderer.parentNode(thead);

			const resizeHolder = this.renderer.createElement('span');
			this.renderer.addClass(resizeHolder, 'eui-table__resize-holder');
			this.renderer.appendChild(this.column, resizeHolder);

			this.renderer.listen(resizeHolder, 'mousedown', this.onMouseDown);
			this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
			this.renderer.listen('document', 'mouseup', this.onMouseUp);

			this.resizer = this.renderer.createElement('span');
			this.renderer.addClass(this.resizer, 'eui-table__resizer');
			this.renderer.appendChild(resizeHolder, this.resizer);
		}
	}

	onMouseDown = (event: MouseEvent) => {
		this.pressed = true;
		this.startX = event.pageX;
		this.startWidth = this.column.offsetWidth;

		this.renderer.addClass(this.resizer, 'eui-table__resizer-active');
	};

	onMouseMove = (event: MouseEvent) => {
		const offset = 35;
		if (this.pressed && event.buttons) {
			this.renderer.addClass(this.table, 'eui-table__resize');

			// Calculate width of column
			const width = this.startWidth + (event.pageX - this.startX - offset);

			const tableCells = Array.from(this.table.getElementsByTagName('tr'))
				.map((row) => row.getElementsByTagName('td').item(this.columnIndex))
				.filter((cell) => cell);

			// Set table header width
			this.renderer.setStyle(this.column, 'width', `${width}px`);

			// Set table cells width
			for (const cell of tableCells) {
				this.renderer.setStyle(cell, 'width', `${width}px`);
			}
		}
	};

	onMouseUp = () => {
		if (this.pressed) {
			this.pressed = false;
			this.renderer.removeClass(this.table, 'eui-table__resize');
			this.renderer.removeClass(this.resizer, 'eui-table__resizer-active');
		}
	};
}
