import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DigitOnlyModule } from '../../directives/digit-only';
import { IconButtonModule } from '../icon-button';
import { IconsModule } from '../icons';
import { InputNumberComponent } from './input-number.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IconButtonModule,
		IconsModule,
		DigitOnlyModule,
	],
	exports: [InputNumberComponent],
	declarations: [InputNumberComponent],
})
export class InputNumberModule {}
