import { EuiIcon } from '../types/icon.interface';

export const allOrders: EuiIcon = {
	name: 'all-orders',
	data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="19" cy="5" r="4" fill="#5295FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9569 9.60256C20.3558 9.85842 19.6945 10 19 10C18.9948 10 18.9896 9.99999 18.9844 9.99998C18.9948 10.1645 19 10.3312 19 10.5V11.5H17C16.45 11.5 15.9792 11.6958 15.5875 12.0875C15.1958 12.4792 15 12.95 15 13.5V17.5C15 18.05 15.1958 18.5208 15.5875 18.9125C15.9792 19.3042 16.45 19.5 17 19.5H19V20.5H13C12.7167 20.5 12.4792 20.5958 12.2875 20.7875C12.0958 20.9792 12 21.2167 12 21.5C12 21.7833 12.0958 22.0208 12.2875 22.2125C12.4792 22.4042 12.7167 22.5 13 22.5H19C19.55 22.5 20.0208 22.3042 20.4125 21.9125C20.8042 21.5208 21 21.05 21 20.5V10.5C21 10.1966 20.9856 9.89742 20.9569 9.60256ZM14.9892 2.01395C14.0439 1.67132 13.0475 1.5 12 1.5C10.7667 1.5 9.60417 1.7375 8.5125 2.2125C7.42083 2.6875 6.46667 3.33333 5.65 4.15C4.83333 4.96667 4.1875 5.92083 3.7125 7.0125C3.2375 8.10417 3 9.26667 3 10.5V17.5C3 18.05 3.19583 18.5208 3.5875 18.9125C3.97917 19.3042 4.45 19.5 5 19.5H7C7.55 19.5 8.02083 19.3042 8.4125 18.9125C8.80417 18.5208 9 18.05 9 17.5V13.5C9 12.95 8.80417 12.4792 8.4125 12.0875C8.02083 11.6958 7.55 11.5 7 11.5H5V10.5C5 8.56667 5.68333 6.91667 7.05 5.55C8.41667 4.18333 10.0667 3.5 12 3.5C12.7573 3.5 13.4711 3.60484 14.1414 3.81451C14.3022 3.1531 14.5946 2.54315 14.9892 2.01395ZM7 17.5H5V13.5H7V17.5ZM19 17.5H17V13.5H19V17.5Z" fill="#262626"/>
  </svg>
  `,
};
