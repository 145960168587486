import { EuiIcon } from '../types/icon.interface';

export const mop: EuiIcon = {
	name: 'mop',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.84444 1.3019C7.97459 1.30098 6.87075 2.33818 6.86883 4.10026C6.86887 5.32919 7.68401 7.40128 7.8023 8.47404L5.55658 8.46888C4.65076 8.46373 4.09697 8.99078 4.0989 9.83317L4.10428 11.1926C4.10966 12.0418 4.64738 12.5516 5.55658 12.5516L14.1279 12.5577C15.04 12.5586 15.5846 12.0452 15.5846 11.1907L15.5801 9.83407C15.5801 8.98804 15.041 8.46467 14.1269 8.46559L11.8831 8.46891C11.996 7.40126 12.8146 5.33245 12.8146 4.10544C12.8127 2.34337 11.7099 1.29673 9.84444 1.3019ZM14.13 10.1043L14.1221 10.8996C14.1212 11.1318 13.9942 11.2639 13.7509 11.2639L5.93737 11.2681C5.69854 11.2639 5.5652 11.1361 5.5652 10.8945L5.56078 10.1128C5.55196 9.86932 5.68785 9.73062 5.94282 9.73815L8.53357 9.74237C8.97386 9.7433 9.24386 9.44329 9.25019 8.9711C9.25658 8.17188 8.22718 5.78376 8.23353 4.14259C8.22915 3.19365 8.82489 2.62453 9.84447 2.61938C10.8551 2.6245 11.4626 3.19455 11.4582 4.1435C11.4509 5.78375 10.4235 8.17184 10.4298 8.97297C10.445 9.44329 10.7106 9.74756 11.1474 9.74333L13.7499 9.74001C13.9942 9.73908 14.13 9.86928 14.13 10.1043ZM9.1919 3.50306C8.83822 3.84203 8.84262 4.39368 9.19535 4.73174C9.54808 5.06981 10.1193 5.0698 10.4729 4.73083C10.8256 4.39279 10.8301 3.84113 10.4773 3.50306C10.1246 3.165 9.5446 3.16502 9.1919 3.50306ZM5.06126 11.796L2.92138 16.785C2.56391 18.2818 3.68728 18.691 4.96872 18.6919L15.5824 18.698C16.8066 18.698 17.5894 17.4706 16.8719 16.3786C15.1425 13.7236 14.9636 13.5425 14.6283 11.6042L13.2071 11.6061C13.4931 13.7986 14.0458 14.4383 15.4284 16.7509C15.6069 17.0395 15.472 17.2881 15.1528 17.2924L5.01919 17.2966C4.57212 17.2966 4.14346 17.0583 4.31599 16.6555L6.45399 11.796H5.06126ZM7.91928 13.9787C7.34924 13.9778 6.64801 16.3226 6.78907 17.4986L9.15272 17.4944C8.3017 16.4825 8.10776 15.3468 8.10776 14.1904C8.10778 14.0562 8.01525 13.9778 7.91928 13.9787Z" fill="#262626"/>
  <path d="M11.8821 17.4987C11.7411 16.3226 11.7517 15.0887 12.0631 14.7941C12.1658 14.6969 12.2079 15.2424 12.2549 15.5823C12.4112 16.7149 13.0257 16.4826 13.8767 17.4944L11.8821 17.4987Z" fill="#262626"/>
  </svg>
  `,
};
