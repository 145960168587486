import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarComponent } from './avatar.component';
import { UserNoAvatarComponent } from './icons/user-no-avatar.component';

@NgModule({
	imports: [CommonModule],
	declarations: [AvatarComponent, UserNoAvatarComponent],
	exports: [AvatarComponent],
})
export class AvatarModule {}
