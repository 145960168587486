import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'euiFilter',
})
export class FilterPipe implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transform(items: any[], searchTerm: string, labelKey?: string) {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter((item) => {
			if (typeof item === 'object') {
				return item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase());
			} else {
				return item.toString().toLowerCase().includes(searchTerm.toLowerCase());
			}
		});
	}
}
