import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Badge } from './types/badge.interface';

@Component({
	selector: 'eui-badge-group',
	template: `<ng-container *ngIf="badgeList.length > 0">
		<div class="eui-badge-group">
			<eui-badge
				[euiTooltip]="badgeList[0].label"
				[euiTooltipTrigger]="'mouseenter'"
				[color]="badgeList[0].color">
				{{ badgeList[0].label }}
			</eui-badge>
			<ng-container *ngIf="badgeList.length > 1">
				<eui-badge
					[euiTooltip]="getGroupTooltip(badgeList)"
					[euiTooltipTrigger]="'mouseenter'"
					[color]="'grey'">
					{{ getBadgeCount(badgeList.length) }}
				</eui-badge>
			</ng-container>
		</div>
	</ng-container>`,
	styleUrls: ['./badge-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeGroupComponent {
	@Input() badgeList: Badge[] = [];

	getGroupTooltip(badges: Badge[]) {
		return badges
			.slice(1)
			.map((badge) => badge.label)
			.join(', ');
	}

	getBadgeCount(length: number) {
		return `+${length - 1}`;
	}
}
