import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ValidationErrors,
	ControlValueAccessor,
} from '@angular/forms';

@Component({
	selector: 'eui-icon-toggle',
	templateUrl: 'icon-toggle.component.html',
	styleUrls: ['./icon-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: IconToggleComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: IconToggleComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconToggleComponent implements ControlValueAccessor {
	@Input() disabled = false;
	@Input() icon = '';
	@Output() disabledChange = new EventEmitter();
	@Output() checkedChange = new EventEmitter();

	checked = false;

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	onChange = (value: unknown | unknown[]) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	touched = false;

	clickHandler() {
		this.markAsTouched();

		if (!this.disabled) {
			this.checked = !this.checked;
			this.checkedChange.emit(this.checked);
			this.onChange(this.checked);
		}
	}

	writeValue(checked: boolean) {
		this.checked = checked;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	validate(): ValidationErrors | null {
		return null;
	}
}
