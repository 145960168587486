import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import dayjs from 'dayjs/esm';

import { DateRanges } from './datepicker-core.component';
import { DatePeriod } from './types/date-period.interface';
import { DatepickerDirective } from './datepicker.directive';

@Component({
	selector: 'eui-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit, OnDestroy {
	@ViewChild(DatepickerDirective) datepickerInput!: DatepickerDirective;
	@Input() timePicker = false;
	@Input() single = false;
	@Input() label = '';
	@Input() position = 'left';
	@Input() placeholder = 'Выберите дату';
	@Input() minDate!: dayjs.Dayjs;
	@Input() showWeekNumbers = false;
	@Output() valueChange = new EventEmitter();
	@Output() init = new EventEmitter();

	formControl = new FormControl();
	invalidDates: dayjs.Dayjs[] = [];
	ranges: DateRanges = {
		['Сегодня']: [dayjs(), dayjs()],
		['Вчера']: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
		['Последние 7 дней']: [
			dayjs().subtract(7, 'days'),
			dayjs().subtract(1, 'days'),
		],
		['Последние 30 дней']: [
			dayjs().subtract(30, 'days'),
			dayjs().subtract(1, 'days'),
		],

		['Этот месяц']: [dayjs().startOf('month'), dayjs().subtract(1, 'days')],

		['Прошлый месяц']: [
			dayjs().subtract(1, 'month').startOf('month'),
			dayjs().subtract(1, 'month').endOf('month'),
		],
		['Прошлые 3 месяца']: [
			dayjs().subtract(3, 'month').startOf('month'),
			dayjs().subtract(1, 'month').endOf('month'),
		],
	};

	private destroy$ = new Subject();
	private wrappedValue: DatePeriod | null = null;

	constructor(private cdr: ChangeDetectorRef) {}

	@Input() set value(value: DatePeriod | null) {
		// && value !== this.wrappedValue

		if (value) {
			this.wrappedValue = {
				startDate: dayjs(value.startDate),
				endDate: dayjs(value.endDate),
			};
			this.formControl.setValue(this.wrappedValue);
		} else {
			this.wrappedValue = null;
		}

		this.cdr.markForCheck();
	}

	get value() {
		return this.wrappedValue;
	}

	ngOnInit() {
		this.formControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((e) => {
				if (this.compare(e)) {
					return;
				}

				if (e.startDate === null && e.endDate === null) {
					this.valueChange.emit(null);
					return;
				}

				this.valueChange.emit(e);
			});

		this.init.emit(null);
	}

	ngOnDestroy() {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	isInvalidDate = (m: dayjs.Dayjs): boolean => {
		return this.invalidDates.some((d) => d.isSame(m, 'day'));
	};

	private compare(e: DatePeriod): boolean {
		if (!this.value) {
			return false;
		}

		if (!e.startDate || !e.endDate) {
			return false;
		}

		const a = JSON.stringify({
			startDate: e.startDate.toString(),
			endData: e.endDate.toString(),
		});

		const b = JSON.stringify({
			startDate: this.value.startDate.toString(),
			endData: this.value.endDate.toString(),
		});

		return a === b;
	}

	open(e: MouseEvent) {
		e.stopPropagation();
		this.datepickerInput.toggle();
	}

	clear() {
		this.datepickerInput.clear();
	}
}
