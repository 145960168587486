import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputErrorComponent } from './input-error.component';
import { InputGroupComponent } from './input-group.component';
import { InputHintComponent } from './input-hint.component';
import { InputLabelComponent } from './input-label.component';
import { InputPrefixComponent } from './input-prefix.component';
import { InputSuffixComponent } from './input-suffix.component';
import { InputDirective } from './input.directive';

const COMPONENTS = [
	InputGroupComponent,
	InputHintComponent,
	InputErrorComponent,
	InputLabelComponent,
	InputPrefixComponent,
	InputSuffixComponent,
	InputDirective,
];

@NgModule({
	imports: [CommonModule],
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
})
export class InputModule {}
