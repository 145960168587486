import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'eui-skeleton-loader',
	template: `
		<div
			class="eui-skeleton-loader"
			[ngStyle]="{ height: height, margin: margin, width: width }"></div>
	`,
	styleUrls: ['./skeleton-loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent {
	@Input() height = '12px';
	@Input() width = '100%';
	@Input() margin = '0';
}
