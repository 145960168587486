import { InjectionToken } from '@angular/core';
import dayjs from 'dayjs/esm';
import localeData from 'dayjs/esm/plugin/localeData';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as ru from 'dayjs/locale/ru';

export const LOCALE_CONFIG = new InjectionToken<LocaleConfig>(
	'datepicker.config',
);
/**
 *  LocaleConfig Interface
 */
export interface LocaleConfig {
	direction?: string;
	separator?: string;
	weekLabel?: string;
	applyLabel?: string;
	cancelLabel?: string;
	clearLabel?: string;
	customRangeLabel?: string;
	daysOfWeek?: string[];
	monthNames?: string[];
	firstDay?: number;
	format?: string;
	displayFormat?: string;
	displayFormatWithTime?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	locale?: any;
}
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleConfig = {
	direction: 'ltr',
	separator: ' - ',
	weekLabel: '#',
	applyLabel: 'Применить',
	cancelLabel: 'Отменить',
	clearLabel: 'Очистить',
	customRangeLabel: 'Свой диапазон',
	daysOfWeek: ru.weekdaysMin,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	monthNames: ru.months.s,
	firstDay: ru.weekStart,
	format: ru.formats.L,
	displayFormat: 'D, MMM YYYY',
	displayFormatWithTime: 'D, MMM YYYY HH:mm',
};
