import { EuiIcon } from '../types/icon.interface';

export const lightMode: EuiIcon = {
	name: 'light-mode',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.7095 4.27182C10.7095 4.66288 10.3882 4.98352 10.0023 4.98352C9.61195 4.98352 9.29067 4.66288 9.29067 4.27182V2.79501C9.29067 2.40524 9.61195 2.08331 10.0023 2.08331C10.3882 2.08331 10.7095 2.40524 10.7095 2.79501V4.27182Z" fill="#262626"/>
  <path d="M14.5551 6.4635C14.2785 6.73967 13.8228 6.7364 13.5488 6.46733C13.2767 6.19571 13.2753 5.74086 13.545 5.46724L14.6016 4.40985C14.8771 4.14079 15.3328 4.13823 15.6022 4.40602C15.8743 4.68347 15.8756 5.14415 15.6061 5.41194L14.5551 6.4635Z" fill="#262626"/>
  <path d="M15.7301 10.7075C15.3442 10.7075 15.0171 10.3868 15.0171 10.0029C15.0171 9.61773 15.3442 9.2912 15.7301 9.2912H17.2096C17.5942 9.2912 17.9167 9.61773 17.9167 10.0029C17.9167 10.3868 17.5942 10.7075 17.2096 10.7075H15.7301Z" fill="#262626"/>
  <path d="M13.5488 14.5424C13.2767 14.272 13.2753 13.8185 13.545 13.5437C13.8216 13.2791 14.2772 13.2766 14.5468 13.5397L15.6049 14.5946C15.877 14.865 15.8782 15.3198 15.61 15.5934C15.3379 15.8592 14.8822 15.8663 14.6068 15.6044L13.5488 14.5424Z" fill="#262626"/>
  <path d="M9.29067 15.7269C9.29067 15.343 9.61195 15.0223 10.0023 15.0223C10.3882 15.0223 10.7095 15.343 10.7095 15.7269V17.2096C10.7095 17.5947 10.3882 17.9166 10.0023 17.9166C9.61195 17.9166 9.29067 17.5947 9.29067 17.2096V15.7269Z" fill="#262626"/>
  <path d="M5.44617 13.5397C5.71583 13.2766 6.17729 13.2791 6.45519 13.5437C6.71773 13.8185 6.7164 14.272 6.44428 14.5424L5.3921 15.5985C5.11665 15.8604 4.6552 15.8534 4.38309 15.5876C4.12065 15.3139 4.12188 14.8592 4.3941 14.5888L5.44617 13.5397Z" fill="#262626"/>
  <path d="M4.26872 9.2912C4.65464 9.2912 4.98305 9.61773 4.98305 10.0029C4.98305 10.3868 4.65464 10.7075 4.26872 10.7075H2.78924C2.40466 10.7075 2.08337 10.3868 2.08337 10.0029C2.08337 9.61773 2.40466 9.2912 2.78924 9.2912H4.26872Z" fill="#262626"/>
  <path d="M6.45519 5.46724C6.71773 5.73503 6.7164 6.20154 6.44428 6.46733C6.17017 6.7364 5.7145 6.73384 5.44361 6.4635L4.39277 5.40611C4.12911 5.14415 4.13167 4.68347 4.40244 4.40602C4.672 4.13823 5.13356 4.14662 5.40312 4.40985L6.45519 5.46724Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7321 10.0029C13.7321 12.0472 12.0386 13.7375 9.99654 13.7375C7.95447 13.7375 6.26106 12.0472 6.26106 10.0029C6.26106 7.9528 7.95447 6.2625 9.99654 6.2625C12.0386 6.2625 13.7321 7.9528 13.7321 10.0029ZM7.57779 10.0029C7.57779 11.321 8.67614 12.4173 9.99654 12.4173C11.317 12.4173 12.4166 11.321 12.4166 10.0029C12.4166 8.67899 11.317 7.58141 9.99654 7.58141C8.67614 7.58141 7.57779 8.67899 7.57779 10.0029Z" fill="#262626"/>
  </svg>
  `,
};
