import { EuiIcon } from '../types/icon.interface';

export const headsetMicDot: EuiIcon = {
	name: 'headset-mic-dot',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5 4C17.5 5.65685 16.1569 7 14.5 7C12.8431 7 11.5 5.65685 11.5 4C11.5 2.34315 12.8431 1 14.5 1C16.1569 1 17.5 2.34315 17.5 4Z" fill="#5295FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1109 6.33095C16.7719 6.71047 16.3513 7.01561 15.8767 7.21885C16.1034 7.69713 16.2735 8.20749 16.3782 8.74128C16.034 8.58621 15.6521 8.49992 15.25 8.49992C13.7312 8.49992 12.5 9.73114 12.5 11.2499V13.7499C12.5 15.2687 13.7312 16.4999 15.25 16.4999C16.7688 16.4999 18 15.2687 18 13.7499V9.99992C18 8.67744 17.6791 7.42991 17.1109 6.33095ZM15.25 9.99992C15.9404 9.99992 16.5 10.5596 16.5 11.2499V13.7499C16.5 14.4403 15.9404 14.9999 15.25 14.9999C14.5596 14.9999 14 14.4403 14 13.7499V11.2499C14 10.5596 14.5596 9.99992 15.25 9.99992Z" fill="#262626"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5307 2.14621C11.0353 2.05021 10.5235 1.99992 10 1.99992C5.58172 1.99992 2 5.58165 2 9.99992V13.7499C2 14.9956 2.82826 16.0479 3.96412 16.386C4.13154 16.9791 4.29622 17.4498 4.58915 17.8302C4.90983 18.2467 5.33432 18.5716 5.82008 18.7724C6.37358 19.0012 7.01053 19.0007 7.85754 19L10.75 18.9999C11.4403 18.9999 12 18.4403 12 17.7499C12 17.0596 11.4403 16.4999 10.75 16.4999H9.74305L9.70521 16.4997C9.57313 16.4989 9.35541 16.4974 9.15004 16.5682C8.97488 16.6287 8.81535 16.7273 8.68298 16.8569C8.52778 17.0089 8.43171 17.2043 8.37344 17.3228L8.35668 17.3568L8.28508 17.4999H7.99946C6.94458 17.4999 6.63479 17.4861 6.39311 17.3862C6.15023 17.2858 5.93799 17.1233 5.77765 16.9151C5.68984 16.801 5.6218 16.6603 5.53245 16.387C6.67007 16.05 7.5 14.9969 7.5 13.7499V11.2499C7.5 9.73114 6.26878 8.49992 4.75 8.49992C4.34793 8.49992 3.96602 8.58621 3.62177 8.74127C4.20794 5.75378 6.84076 3.49992 10 3.49992C10.3487 3.49992 10.691 3.52739 11.0249 3.58027C11.0873 3.05829 11.2646 2.5716 11.5307 2.14621ZM3.5 13.7499V11.2499C3.5 10.5596 4.05964 9.99992 4.75 9.99992C5.44036 9.99992 6 10.5596 6 11.2499V13.7499C6 14.4403 5.44036 14.9999 4.75 14.9999C4.05964 14.9999 3.5 14.4403 3.5 13.7499Z" fill="#262626"/>
  </svg>
  `,
};
