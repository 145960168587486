import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconToggleComponent } from './icon-toggle.component';
import { IconsModule } from '../icons';

@NgModule({
	imports: [CommonModule, IconsModule],
	declarations: [IconToggleComponent],
	exports: [IconToggleComponent],
})
export class IconToggleModule {}
