import {
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core';
import {
	ConnectedPosition,
	Overlay,
	OverlayPositionBuilder,
	OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { positionX, positionY } from './types/tooltip.type';
import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[euiTooltip]' })
export class TooltipDirective implements OnInit {
	@Input() euiTooltip: string | undefined = '';
	@Input() euiTooltipPosition:
		| 'top-left'
		| 'top'
		| 'top-right'
		| 'right'
		| 'bottom-right'
		| 'bottom'
		| 'bottom-left'
		| 'left' = 'top';
	@Input() euiTooltipTrigger: 'mouseenter' | 'click' = 'mouseenter';
	@Input() euiTooltipTemplateRef: TemplateRef<unknown> | undefined;

	private overlayRef: OverlayRef | undefined;
	private showTimer!: NodeJS.Timeout;
	private hideTimer!: NodeJS.Timeout;

	private SHOW_DELAY = 600;
	private HIDE_DELAY = 2000;

	constructor(
		private hostEl: ElementRef,
		private overlay: Overlay,
		private overlayPositionBuilder: OverlayPositionBuilder,
	) {}

	ngOnInit() {
		if (this.euiTooltip || this.euiTooltipTemplateRef) {
			const positionStrategy = this.overlayPositionBuilder
				.flexibleConnectedTo(this.hostEl)
				.withPositions(this.getPositionSet());

			this.overlayRef = this.overlay.create({
				positionStrategy,
			});
		}
	}

	@HostListener('mouseenter')
	onMouseEnter() {
		this.onMouseLeave();
		if (this.euiTooltipTrigger === 'mouseenter') {
			this.showTimer = setTimeout(() => {
				this.show();
			}, this.SHOW_DELAY);
		}
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.euiTooltipTrigger === 'mouseenter') {
			this.hide();
			clearTimeout(this.showTimer);
		}
	}

	@HostListener('click')
	onShowClick() {
		this.hide();
		clearTimeout(this.showTimer);
		clearTimeout(this.hideTimer);

		if (this.euiTooltipTrigger === 'click') {
			this.showTimer = setTimeout(() => {
				this.show();
			}, this.SHOW_DELAY);

			this.hideTimer = setTimeout(() => {
				this.hide();
			}, this.SHOW_DELAY + this.HIDE_DELAY);
		}
	}

	@HostListener('document:wheel', ['$event.target'])
	onScroll() {
		this.hide();
	}

	private getPositionSet(): ConnectedPosition[] {
		switch (this.euiTooltipPosition) {
			case 'top':
				return this.generatePositionSet(
					'center',
					'top',
					'center',
					'bottom',
					0,
					-8,
				);
			case 'top-left':
				return this.generatePositionSet(
					'start',
					'top',
					'start',
					'bottom',
					0,
					-8,
				);
			case 'top-right':
				return this.generatePositionSet('end', 'top', 'end', 'bottom', 0, -8);
			case 'bottom':
				return this.generatePositionSet(
					'center',
					'bottom',
					'center',
					'top',
					0,
					8,
				);
			case 'bottom-left':
				return this.generatePositionSet(
					'start',
					'bottom',
					'start',
					'top',
					0,
					8,
				);
			case 'bottom-right':
				return this.generatePositionSet('end', 'bottom', 'end', 'top', 0, 8);
			case 'left':
				return this.generatePositionSet(
					'start',
					'center',
					'end',
					'center',
					-8,
					0,
				);
			case 'right':
				return this.generatePositionSet(
					'end',
					'center',
					'start',
					'center',
					8,
					0,
				);
			default:
				return this.generatePositionSet(
					'start',
					'top',
					'start',
					'bottom',
					0,
					-8,
				);
		}
	}

	private generatePositionSet(
		originX: positionX,
		originY: positionY,
		overlayX: positionX,
		overlayY: positionY,
		offsetX: number,
		offsetY: number,
	): ConnectedPosition[] {
		return [
			{
				originX,
				originY,
				overlayX,
				overlayY,
				offsetY,
				offsetX,
			},
		];
	}

	private show() {
		this.hide();

		if (this.overlayRef) {
			const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
				new ComponentPortal(TooltipComponent),
			);

			tooltipRef.instance.tooltip = this.euiTooltip;
			tooltipRef.instance.position = this.euiTooltipPosition;
			tooltipRef.instance.tooltipTemplateRef = this.euiTooltipTemplateRef;
			tooltipRef.instance.width = this.hostEl.nativeElement.offsetWidth;
			tooltipRef.instance.height = this.hostEl.nativeElement.offsetHeight;
		}
	}

	private hide() {
		if (this.overlayRef) {
			this.overlayRef.detach();
		}
	}
}
