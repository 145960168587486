import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';

import { getNthParent } from '../../utils';

@Directive({
	selector: 'input[euiInput], textarea[euiInput]',
})
export class InputDirective implements AfterViewInit, OnChanges {
	@Input() error = false;
	@Output() focusChange = new EventEmitter();

	private parent!: HTMLElement;

	constructor(private el: ElementRef) {}

	ngAfterViewInit() {
		this.parent = getNthParent(this.el.nativeElement, 1);

		if (this.el.nativeElement.disabled) {
			this.parent.classList.add('eui-input-group__disabled');
		}

		if (this.error) {
			this.parent.classList.add('eui-input-group__error');
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['error'] && this.parent) {
			const error = changes['error'].currentValue;

			if (error) {
				this.parent.classList.add('eui-input-group__error');
			} else {
				this.parent.classList.remove('eui-input-group__error');
			}
		}
	}

	@HostListener('focusin')
	onFocusIn() {
		if (this.parent.classList.contains('eui-input-group')) {
			this.parent.classList.add('eui-input-group__focused');
		}

		this.focusChange.emit(true);
	}

	@HostListener('focusout')
	onFocusOut() {
		if (this.parent.classList.contains('eui-input-group')) {
			this.parent.classList.remove('eui-input-group__focused');
		}

		this.focusChange.emit(false);
	}

	@HostListener('click') onMouseEnter() {
		if (this.parent.classList.contains('eui-input-group')) {
			this.parent.classList.remove('eui-input-group__focused');
		}
	}
}
