import { EuiIcon } from '../types/icon.interface';

export const eyeOff: EuiIcon = {
	name: 'eye-off',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.0774 14.8031C12.1459 15.1145 11.1213 15.3069 10.0019 15.3069C5.09608 15.3069 1.66663 11.3436 1.66663 10.117C1.66663 9.39791 2.83922 7.74654 4.82411 6.50258L5.73563 7.40268C4.07512 8.4112 3.04034 9.71453 3.04034 10.117C3.04034 10.9528 6.12152 14.1565 10.0019 14.1565C10.7498 14.1565 11.4496 14.0397 12.1114 13.8459L13.0774 14.8031Z" fill="#262626"/>
  <path d="M7.09212 5.39979C7.98267 5.10811 8.94539 4.92929 10.0019 4.92929C14.9749 4.92929 18.3333 8.88771 18.3333 10.117C18.3333 10.8214 17.2159 12.4356 15.3155 13.6746L14.4156 12.7816C15.9789 11.8118 16.9595 10.5866 16.9595 10.117C16.9595 9.41638 13.8773 6.07969 10.0019 6.07969C9.31199 6.07969 8.6779 6.18067 8.06309 6.3559L7.09212 5.39979Z" fill="#262626"/>
  <path d="M15.465 15.4084C15.2726 15.6096 14.9705 15.5997 14.782 15.4084L4.50445 5.25947C4.322 5.08017 4.322 4.76462 4.50445 4.58424C4.69685 4.39786 5.00497 4.39786 5.1935 4.58424L15.465 14.7331C15.6524 14.9245 15.6673 15.2073 15.465 15.4084Z" fill="#262626"/>
  <path d="M6.99988 10C6.99988 9.53161 7.10723 9.08828 7.29866 8.69328L8.5033 9.89792C8.50103 9.93166 8.49988 9.9657 8.49988 10C8.49988 10.8284 9.17145 11.5 9.99988 11.5C10.0342 11.5 10.0682 11.4989 10.102 11.4966L11.3066 12.7012C10.9116 12.8927 10.4683 13 9.99988 13C8.34302 13 6.99988 11.6569 6.99988 10Z" fill="#262626"/>
  <path d="M11.4999 10C11.4999 10.0567 11.4967 10.1126 11.4906 10.1677L12.6774 11.3545C12.8837 10.9476 12.9999 10.4874 12.9999 10C12.9999 8.34316 11.6567 7.00002 9.99988 7.00002C9.5125 7.00002 9.05228 7.11623 8.64539 7.32247L9.8322 8.50928C9.88725 8.50316 9.9432 8.50001 9.99988 8.50001C10.8283 8.50001 11.4999 9.17159 11.4999 10Z" fill="#262626"/>
  </svg>
  `,
};
