import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';

@Component({
	selector: 'eui-checkbox',
	template: `
		<div
			class="eui-checkbox"
			[ngClass]="[disabled ? 'eui-checkbox__disabled' : '']"
			(click)="clickHandler()">
			<input
				class="eui-checkbox-input"
				[ngClass]="[
					error ? 'eui-checkbox-input__error' : '',
					indeterminate ? 'eui-checkbox-input__indeterminate' : ''
				]"
				type="checkbox"
				[checked]="checked"
				[disabled]="disabled" />
			<label class="eui-checkbox-label" *ngIf="label">{{ label }}</label>
		</div>
	`,
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: CheckboxComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: CheckboxComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() label = '';
	@Input() indeterminate = false;
	@Input() error = false;
	@Input() disabled = false;
	@Input() checked = false;
	@Output() disabledChange = new EventEmitter();
	@Output() checkedChange = new EventEmitter();

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	onChange = (value: unknown | unknown[]) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};
	touched = false;

	constructor(private cdr: ChangeDetectorRef) {}

	clickHandler() {
		this.markAsTouched();

		if (!this.disabled) {
			this.checked = !this.checked;
			this.checkedChange.emit(this.checked);
			this.onChange(this.checked);

			this.cdr.detectChanges();
		}
	}

	writeValue(checked: boolean) {
		this.checked = checked;
		this.cdr.detectChanges();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
		this.cdr.detectChanges();
	}

	validate(): ValidationErrors | null {
		return null;
	}
}
