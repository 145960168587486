import { NgModule } from '@angular/core';

import * as IconsSvg from './svg';
import { IconsRegistry } from './icons-registry.service';
import { IconsComponent } from './icons.component';
import { EuiIcon } from './types/icon.interface';

@NgModule({
	declarations: [IconsComponent],
	exports: [IconsComponent],
})
export class IconsModule {
	constructor(private iconRegistry: IconsRegistry) {
		const icons: EuiIcon[] = Object.values(IconsSvg);

		this.iconRegistry.registerIcons(icons);
	}
}
