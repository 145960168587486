import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';

import { SortEvent, SortOrder } from '@emrm/common/helpers/sort';

@Directive({
	selector: '[euiSort]',
})
export class SortDirective {
	@Input() sortProperty!: unknown;
	@Input() sortOrder: SortOrder = SortOrder.Desc;
	@Input() sortType = '';
	@Output() sortClick = new EventEmitter<SortEvent>();

	@HostListener('click')
	sortData() {
		this.sortOrder =
			this.sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc;

		this.sortClick.emit({
			property: this.sortProperty as string,
			order: this.sortOrder,
			type: this.sortType,
		});
	}
}
