<div #dropdown class="eui-dropdown">
	<!-- DEFAULT CHEVRONS TEMPLATE -->
	<ng-template #chevronsTemplate>
		@if (opened) {
			<eui-icons name="chevron-up" [size]="20"></eui-icons>
		} @else {
			<eui-icons name="chevron-down" [size]="20"></eui-icons>
		}
	</ng-template>

	@if (customTriggerTemplateRef) {
		<!-- CUSTOM TRIGGER TEMPLATE -->
		<div
			class="eui-dropdown__custom-trigger"
			[ngClass]="{
				'eui-dropdown__custom-trigger__disabled': disabled,
				'eui-dropdown__custom-trigger__focused': opened
			}"
			(click)="toggleDropdown($event)">
			<ng-template
				[ngTemplateOutlet]="customTriggerTemplateRef"
				[ngTemplateOutletContext]="{
					$implicit: value,
					opened: opened,
					label: label,
					error: error,
					disabled: disabled,
					field: displayField
				}">
			</ng-template>
		</div>
	} @else {
		<!-- DEFAULT TRIGGER TEMPLATE -->
		<div class="form-control-group">
			@if (label) {
				<eui-input-label>{{ label }}</eui-input-label>
			}

			<eui-input-group [noBorder]="noBorder" (click)="toggleDropdown($event)">
				<input
					class="eui-dropdown__input"
					readonly
					euiInput
					[value]="displaySelectedOptions"
					[placeholder]="placeholder"
					[disabled]="disabled"
					[error]="!!error"
					type="text" />

				<ng-template *ngTemplateOutlet="chevronsTemplate"></ng-template>
			</eui-input-group>

			@if (description) {
				<eui-input-hint>{{ description }}</eui-input-hint>
			}

			@if (error) {
				<eui-input-error>{{ error }}</eui-input-error>
			}
		</div>
	}

	<!-- DEFAULT OPTIONS TEMPLATE -->
	<ng-template #defaultOptionsTemplateRef let-option>
		{{ displayFn(option) }}
	</ng-template>

	<!-- OPTIONS TEMPLATE -->
	<ng-template cdk-portal #overlayTemplate="cdkPortal">
		<eui-dropdown-options
			#dropdownOptions
			[optionsFitContent]="optionsFitContent">
			<!-- SEARCH -->
			@if (search) {
				<eui-dropdown-options-search
					(valueChange)="searchChangeHandler($event)"
					[placeholder]="searchPlaceholder"></eui-dropdown-options-search>
			}

			<!-- OPTIONS LIST -->
			<cdk-virtual-scroll-viewport
				appendOnly
				itemSize="25"
				class="eui-dropdown__options-list"
				[ngStyle]="{ height: getVirtualScrollHeight() }">
				<!-- SELECT ALL -->
				@if (multiple) {
					<div
						class="eui-dropdown__options-item eui-dropdown__options-select-all"
						(click)="toggleAll()">
						<eui-checkbox
							[checked]="selectedAll()"
							[indeterminate]="selectedAllIndeterminate()"></eui-checkbox>
						Выбрать все
					</div>
				}

				<!-- OPTIONS -->
				@if (
					options | euiFilter: searchValue : displayField;
					as filteredOptions
				) {
					<div
						*cdkVirtualFor="
							let option of filteredOptions;
							index as i;
							trackBy: trackByFn
						"
						class="eui-dropdown__options-item"
						[ngClass]="{
							selected: selected(option),
							disabled: disableOptions && option[disableOptionField]
						}"
						(click)="select(option)">
						@if (multiple) {
							<eui-checkbox [checked]="selected(option)"></eui-checkbox>
						}

						<ng-template
							[ngTemplateOutletContext]="{
								$implicit: option,
								index: i,
								field: displayField
							}"
							[ngTemplateOutlet]="
								customOptionsTemplateRef
									? customOptionsTemplateRef
									: defaultOptionsTemplateRef
							">
						</ng-template>
					</div>
				}
			</cdk-virtual-scroll-viewport>

			<!-- ACTIONS -->
			@if (actionsButtons && multiple) {
				<div class="eui-dropdown__actions">
					<button euiButton color="primary" size="sm" (click)="saveChanges()">
						Сохранить
					</button>
					<button
						euiButton
						color="secondary"
						size="sm"
						(click)="resetChanges()">
						Сбросить
					</button>
				</div>
			}
		</eui-dropdown-options>
	</ng-template>
</div>
