import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[euiTableRowHeader]',
})
export class TableRowHeaderDirective {
	constructor(private el: ElementRef) {
		this.el.nativeElement.classList.add('eui-table__row-header');
	}
}
