import { EuiIcon } from '../types/icon.interface';

export const etagiPro: EuiIcon = {
	name: 'etagi-pro',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z" fill="#FBB225"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.42853 5.23805H11.7301C12.725 5.23805 13.5344 6.0863 13.5593 7.14277H8.16444L8.16444 14.7593C7.19751 14.7071 6.42853 13.853 6.42853 12.807V5.23805ZM9.99419 12.8069H13.5599C13.5599 13.8866 12.7407 14.7619 11.7301 14.7619H8.25829L8.24805 14.7618H9.99419V12.8069ZM9.90797 8.99121H12.6508C13.1589 8.99156 13.5714 9.43179 13.5714 9.9748C13.5714 10.518 13.1592 10.9584 12.6508 10.9584C12.6349 10.9584 12.6192 10.958 12.6036 10.9571H9.90797V8.99121Z" fill="#464646"/>
</svg>
`,
};
