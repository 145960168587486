import {
	Directive,
	ViewContainerRef,
	ElementRef,
	HostListener,
	forwardRef,
	ChangeDetectorRef,
	OnInit,
	OnChanges,
	SimpleChanges,
	Input,
	DoCheck,
	KeyValueDiffer,
	KeyValueDiffers,
	Output,
	EventEmitter,
	Renderer2,
	HostBinding,
	AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs/esm';

import {
	ChosenDate,
	DateRange,
	DatepickerCoreComponent,
	DateRanges,
	EndDate,
	StartDate,
	TimePeriod,
} from './datepicker-core.component';
import { LocaleConfig } from './datepicker.config';
import { LocaleService } from './locale.service';
import { getNthParent } from '../../utils';

@Directive({
	selector: 'input[euiDatepicker]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerDirective),
			multi: true,
		},
	],
})
export class DatepickerDirective
	implements OnInit, OnChanges, DoCheck, AfterViewInit
{
	// eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-native
	@Output('change') onChange: EventEmitter<TimePeriod | null> =
		new EventEmitter();
	@Output() rangeClicked: EventEmitter<DateRange> = new EventEmitter();
	@Output() datesUpdated: EventEmitter<TimePeriod> = new EventEmitter();
	@Output() startDateChanged: EventEmitter<StartDate> = new EventEmitter();
	@Output() endDateChanged: EventEmitter<EndDate> = new EventEmitter();
	@Output() clearClicked: EventEmitter<void> = new EventEmitter();

	@Input()
	minDate!: dayjs.Dayjs;

	@Input()
	maxDate!: dayjs.Dayjs;

	@Input()
	autoApply!: boolean;

	@Input()
	alwaysShowCalendars!: boolean;

	@Input()
	showCustomRangeLabel!: boolean;

	@Input()
	linkedCalendars!: boolean;

	@Input()
	dateLimit: number | null = null;

	@Input()
	singleDatePicker!: boolean;

	@Input()
	showWeekNumbers!: boolean;

	@Input()
	showISOWeekNumbers!: boolean;

	@Input()
	showDropdowns!: boolean;

	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	isInvalidDate!: (Dayjs: any) => boolean;

	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	isCustomDate!: (Dayjs: any) => string | boolean;

	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	isTooltipDate!: (Dayjs: any) => string | boolean | null;

	@Input()
	showClearButton!: boolean;

	@Input()
	customRangeDirection!: boolean;

	@Input()
	ranges!: DateRanges | null;

	@Input()
	opens: string;

	@Input()
	drops: string;

	@Input()
	firstMonthDayClass!: string;

	@Input()
	lastMonthDayClass!: string;

	@Input()
	emptyWeekRowClass!: string;

	@Input()
	emptyWeekColumnClass!: string;

	@Input()
	firstDayOfNextMonthClass!: string;

	@Input()
	lastDayOfPreviousMonthClass!: string;

	@Input()
	keepCalendarOpeningWithRange!: boolean;

	@Input()
	showRangeLabelOnInput!: boolean;

	@Input()
	showCancel = false;

	@Input()
	lockStartDate = false;

	// timepicker variables
	@Input()
	timePicker = false;

	@Input()
	timePicker24Hour = false;

	@Input()
	timePickerIncrement = 1;

	@Input()
	timePickerSeconds = false;

	@Input() closeOnAutoApply = true;
	@Input()
	private endKeyHolder!: string;

	public picker: DatepickerCoreComponent;
	private startKeyHolder!: string;
	private notForChangesProperty: Array<string> = [
		'locale',
		'endKey',
		'startKey',
	];
	private onChangeFn = Function.prototype;
	private onTouched = Function.prototype;
	private disabledHolder!: boolean;
	private valueHolder!: TimePeriod | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private localeDiffer!: KeyValueDiffer<string, any>;
	private localeHolder: LocaleConfig = {};
	private parent!: HTMLElement;

	constructor(
		public viewContainerRef: ViewContainerRef,
		public ref: ChangeDetectorRef,
		private el: ElementRef,
		private renderer: Renderer2,
		private differs: KeyValueDiffers,
		private localeHolderService: LocaleService,
		private elementRef: ElementRef,
	) {
		this.endKey = 'endDate';
		this.startKey = 'startDate';
		this.drops = 'down';
		this.opens = 'auto';
		viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(
			DatepickerCoreComponent,
		);
		this.picker = componentRef.instance as DatepickerCoreComponent;
		this.picker.inline = false; // set inline to false for all directive usage
	}

	@HostBinding('disabled') get disabled(): boolean {
		return this.disabledHolder;
	}

	@Input() set startKey(value: string) {
		if (value !== null) {
			this.startKeyHolder = value;
		} else {
			this.startKeyHolder = 'startDate';
		}
	}

	get locale(): LocaleConfig {
		return this.localeHolder;
	}

	@Input() set locale(value: LocaleConfig) {
		this.localeHolder = { ...this.localeHolderService.config, ...value };
	}

	@Input() set endKey(value: string) {
		if (value !== null) {
			this.endKeyHolder = value;
		} else {
			this.endKeyHolder = 'endDate';
		}
	}

	get value(): TimePeriod | null {
		return this.valueHolder || null;
	}

	set value(val: TimePeriod | null) {
		this.valueHolder = val;
		this.onChangeFn(val);
		this.ref.markForCheck();
	}

	/**
	 * For click outside the calendar's container
	 *
	 * @param event event object
	 */
	@HostListener('document:click', ['$event'])
	outsideClick(event: Event): void {
		if (!event.target) {
			return;
		}

		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.hide();
		}
	}

	@HostListener('keyup.esc', ['$event'])
	hide(e?: Event): void {
		this.parent.classList.remove('eui-input-group__focused');
		this.picker.hide(e);
	}

	@HostListener('blur')
	onBlur(): void {
		this.onTouched();
	}

	@HostListener('keyup', ['$event'])
	inputChanged(e: KeyboardEvent): void {
		if ((e.target as HTMLElement).tagName.toLowerCase() !== 'input') {
			return;
		}
		if (!(e.target as HTMLInputElement).value.length) {
			return;
		}
		const separator = this.picker.locale.separator || '';
		const dateString = (e.target as HTMLInputElement).value.split(separator);
		let start = null;
		let end = null;
		if (dateString.length === 2) {
			start = dayjs(dateString[0], this.picker.locale.format);
			end = dayjs(dateString[1], this.picker.locale.format);
		}
		if (this.singleDatePicker || start === null || end === null) {
			start = dayjs(
				(e.target as HTMLInputElement).value,
				this.picker.locale.format,
			);
			end = start;
		}
		if (!start.isValid() || !end.isValid()) {
			return;
		}
		this.picker.setStartDate(start);
		this.picker.setEndDate(end);
		this.picker.updateView();
	}

	// @HostListener('click', ['$event'])
	open(event?: Event): void {
		if (this.disabled) {
			return;
		}

		this.parent.classList.add('eui-input-group__focused');
		this.picker.show(event);
		this.setPosition();
	}

	ngOnInit(): void {
		this.picker.startDateChanged
			.asObservable()
			.subscribe((itemChanged: StartDate) => {
				this.startDateChanged.emit(itemChanged);
			});
		this.picker.endDateChanged
			.asObservable()
			.subscribe((itemChanged: EndDate) => {
				this.endDateChanged.emit(itemChanged);
			});
		this.picker.rangeClicked.asObservable().subscribe((range: DateRange) => {
			this.rangeClicked.emit(range);
		});
		this.picker.datesUpdated.asObservable().subscribe((range: TimePeriod) => {
			this.datesUpdated.emit(range);
		});
		this.picker.clearClicked.asObservable().subscribe(() => {
			this.clearClicked.emit();
		});
		this.picker.choosedDate.asObservable().subscribe((change: ChosenDate) => {
			if (change) {
				const value = {
					[this.startKeyHolder]: change.startDate,
					[this.endKeyHolder]: change.endDate,
				};
				this.value = value as TimePeriod;
				this.onChange.emit(value as TimePeriod);
				if (typeof change.chosenLabel === 'string') {
					this.el.nativeElement.value = change.chosenLabel;
				}
			}
		});
		this.picker.firstMonthDayClass = this.firstMonthDayClass;
		this.picker.lastMonthDayClass = this.lastMonthDayClass;
		this.picker.emptyWeekRowClass = this.emptyWeekRowClass;
		this.picker.emptyWeekColumnClass = this.emptyWeekColumnClass;
		this.picker.firstDayOfNextMonthClass = this.firstDayOfNextMonthClass;
		this.picker.lastDayOfPreviousMonthClass = this.lastDayOfPreviousMonthClass;
		this.picker.drops = this.drops;
		this.picker.opens = this.opens;
		this.localeDiffer = this.differs.find(this.locale).create();
		this.picker.closeOnAutoApply = this.closeOnAutoApply;
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const change in changes) {
			if (Object.prototype.hasOwnProperty.call(changes, change)) {
				if (this.notForChangesProperty.indexOf(change) === -1) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.picker[change] = changes[change].currentValue;
				}
			}
		}
	}

	ngDoCheck(): void {
		if (this.localeDiffer) {
			const changes = this.localeDiffer.diff(this.locale);
			if (changes) {
				this.picker.updateLocale(this.locale);
			}
		}
	}

	ngAfterViewInit(): void {
		this.parent = getNthParent(this.el.nativeElement, 1);
	}

	toggle(e?: Event): void {
		if (this.picker.isShown) {
			this.hide(e);
		} else {
			this.open(e);
		}
	}

	clear(): void {
		this.picker.clear();
	}

	writeValue(value: TimePeriod): void {
		this.setValue(value);
	}

	registerOnChange(fn: () => TimePeriod | null): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(state: boolean): void {
		this.disabledHolder = state;
	}

	/**
	 * Set position of the calendar
	 */
	setPosition(): void {
		let style;
		let containerTop;
		const container = this.picker.pickerContainer.nativeElement;
		const element = this.el.nativeElement;
		if (this.drops && this.drops === 'up') {
			containerTop = element.offsetTop - container.clientHeight + 'px';
		} else {
			containerTop = 'auto';
		}

		const rlPadding = 20;
		const iconWidth = 20;

		if (this.opens === 'left') {
			style = {
				top: containerTop,
				left:
					element.offsetLeft -
					container.clientWidth +
					element.clientWidth +
					rlPadding +
					'px',
				right: 'auto',
			};
		} else if (this.opens === 'center') {
			style = {
				top: containerTop,
				left:
					element.offsetLeft +
					element.clientWidth / 2 -
					container.clientWidth / 2 +
					'px',
				right: 'auto',
			};
		} else if (this.opens === 'right') {
			style = {
				top: containerTop,
				left: element.offsetLeft - rlPadding - iconWidth + 'px',
				right: 'auto',
			};
		} else {
			const position =
				element.offsetLeft +
				element.clientWidth / 2 -
				container.clientWidth / 2;
			if (position < 0) {
				style = {
					top: containerTop,
					left: element.offsetLeft + 'px',
					right: 'auto',
				};
			} else {
				style = {
					top: containerTop,
					left: position + 'px',
					right: 'auto',
				};
			}
		}

		if (style) {
			this.renderer.setStyle(container, 'top', style.top);
			this.renderer.setStyle(container, 'left', style.left);
			this.renderer.setStyle(container, 'right', style.right);
		}
	}

	private setValue(val: TimePeriod) {
		if (val) {
			this.value = val;
			if (val[this.startKeyHolder]) {
				this.picker.setStartDate(val[this.startKeyHolder]);
			}
			if (val[this.endKeyHolder]) {
				this.picker.setEndDate(val[this.endKeyHolder]);
			}
			this.picker.calculateChosenLabel();
			if (this.picker.chosenLabel) {
				this.el.nativeElement.value = this.picker.chosenLabel;
			}
		} else {
			this.picker.clear();
		}
	}
}
