import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DropdownsService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentDropdown: any | null = null;

	bind(dropdown: unknown) {
		if (this.currentDropdown) {
			this.currentDropdown.hide();
		}

		this.currentDropdown = dropdown;
	}

	unbind(dropdown: unknown) {
		if (dropdown === this.currentDropdown) {
			this.currentDropdown = null;
		}
	}
}
