import { EuiIcon } from '../types/icon.interface';

export const mrmCircleMonochrome: EuiIcon = {
	name: 'mrm-circle-monochrome',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.51 7.23577C10.6317 7.24856 10.7535 7.27735 10.8752 7.32215L7.81904 11.3725C7.76778 11.2829 7.72616 11.1806 7.69413 11.0654C7.66209 10.9502 7.63325 10.8318 7.60762 10.7103C7.5884 10.5823 7.57237 10.4575 7.55955 10.3359C7.55315 10.2144 7.54996 10.1056 7.54996 10.0096C7.54996 9.58088 7.61724 9.19696 7.75179 8.85783C7.88633 8.5123 8.06891 8.21796 8.29957 7.97481C8.53663 7.73166 8.81214 7.5461 9.12608 7.41813C9.44643 7.28375 9.78601 7.21657 10.1448 7.21657C10.2729 7.21657 10.3947 7.22297 10.51 7.23577Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5ZM9.83725 12.793C9.73474 12.7802 9.63224 12.7578 9.52973 12.7258L14.4503 6.80385C14.2069 6.45832 13.9249 6.14799 13.6046 5.87284C13.2842 5.5913 12.9383 5.35455 12.5667 5.16258C12.2015 4.97062 11.8138 4.82345 11.4038 4.72107C10.9937 4.61869 10.5741 4.5675 10.1448 4.5675C9.42081 4.5675 8.73845 4.69868 8.09774 4.96102C7.46345 5.22337 6.90605 5.5913 6.42552 6.0648C5.9514 6.53831 5.57658 7.11099 5.30108 7.78285C5.02557 8.45472 4.88783 9.19696 4.88783 10.0096C4.88783 10.803 5.02557 11.5325 5.30108 12.198C5.57658 12.857 5.9514 13.4265 6.42552 13.9064C6.90605 14.3863 7.46345 14.7606 8.09774 15.0294C8.73845 15.2981 9.42081 15.4325 10.1448 15.4325C10.5741 15.4325 10.9905 15.3813 11.3942 15.2789C11.8042 15.1829 12.1918 15.039 12.557 14.847C12.9286 14.6551 13.2714 14.4215 13.5854 14.1464C13.9057 13.8712 14.1876 13.5609 14.4311 13.2153L12.509 11.2861C12.4065 11.5165 12.2719 11.7276 12.1054 11.9196C11.9452 12.1052 11.7594 12.2651 11.5479 12.3995C11.3429 12.5275 11.1219 12.6267 10.8848 12.6971C10.6478 12.7674 10.4011 12.8026 10.1448 12.8026C10.0423 12.8026 9.93976 12.7994 9.83725 12.793Z" fill="#262626"/>
</svg>
`,
};
