import { EuiIcon } from '../types/icon.interface';

export const yandexRealty: EuiIcon = {
	name: 'yandex-realty',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.2718 2.08366C10.112 1.98774 9.87214 1.98774 9.71227 2.08366C5.77942 4.67359 3.74904 6.01652 3.60516 6.11244C3.39732 6.24034 3.33337 6.44817 3.33337 6.656C3.33337 6.79989 3.33337 10.0453 3.33337 16.3762C3.33337 16.5681 3.47726 16.7279 3.68509 16.7279H7.36215V10.0613H12.574C12.606 10.0613 12.6379 10.0933 12.6379 10.1252V16.7279H16.315C16.4908 16.7279 16.6667 16.5681 16.6667 16.3762C16.6667 10.0293 16.6667 6.79989 16.6667 6.656C16.6667 6.44817 16.6028 6.24034 16.3949 6.09645C16.251 6.01652 14.2207 4.67359 10.2718 2.08366Z" fill="#FFCB03"/>
<path d="M7.36215 10.0613V16.7279C9.00883 17.5433 9.85616 17.959 9.90412 17.9749C9.96807 18.0069 10.016 17.9749 10.016 17.943C10.016 13.7223 10.016 11.5961 10.016 11.5161C10.016 11.4042 10.016 11.3722 9.87214 11.3083C9.77622 11.2603 8.9289 10.8447 7.36215 10.0613Z" fill="#EDA901"/>
</svg>
`,
};
