import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '../icons';

import { SideMenuItemDirective } from './side-menu-item.directive';
import { SideMenuComponent } from './side-menu.component';
import { BadgeModule } from '../badge';

@NgModule({
	declarations: [SideMenuComponent, SideMenuItemDirective],
	imports: [CommonModule, IconsModule, BadgeModule],
	exports: [SideMenuComponent],
})
export class SideMenuModule {}
