import { EuiIcon } from '../types/icon.interface';

export const uploadCloud: EuiIcon = {
	name: 'upload-cloud',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.35364 8.40262L5.49817 7.68213C5.88098 5.77658 7.56604 4.34167 9.58337 4.34167C11.3209 4.34167 12.8131 5.40579 13.4381 6.92273L13.723 7.61441L14.4672 7.69022C15.9362 7.83981 17.0834 9.08258 17.0834 10.5917C17.0834 12.2025 15.7775 13.5083 14.1666 13.5083H14.1158C13.7816 13.5256 13.5668 13.7996 13.5668 14.1336C13.5668 14.4677 13.9049 14.763 14.233 14.7578C16.5035 14.7223 18.3334 12.8707 18.3334 10.5917C18.3334 8.43469 16.6942 6.66055 14.5939 6.44662C13.7828 4.47769 11.8448 3.09167 9.58337 3.09167C6.95886 3.09167 4.77039 4.9581 4.27283 7.436C2.76111 7.91965 1.66663 9.33618 1.66663 11.0083C1.66663 13.0794 3.34558 14.7583 5.41663 14.7583L5.68567 14.7586H5.82239C6.17224 14.7586 6.45593 14.4788 6.45593 14.1336C6.45593 13.7921 6.1781 13.5145 5.83337 13.5087L5.41663 13.5083C4.03601 13.5083 2.91663 12.389 2.91663 11.0083C2.91663 9.89548 3.64465 8.94937 4.65369 8.62656L5.35364 8.40262Z" fill="#262626"/>
  <path d="M10.65 10.8275V16.2583C10.65 16.6172 10.359 16.9083 9.99988 16.9083C9.64099 16.9083 9.34998 16.6172 9.34998 16.2583V10.8275L7.91931 12C7.66541 12.2538 7.25403 12.2538 7.00012 12C6.74622 11.7461 6.74622 11.3346 7.00012 11.0807L9.54041 8.79865C9.79431 8.5448 10.2057 8.5448 10.4596 8.79865L13.0001 11.0807C13.254 11.3346 13.254 11.7461 13.0001 12C12.7462 12.2538 12.3348 12.2538 12.0809 12L10.65 10.8275Z" fill="#262626"/>
  </svg>
  `,
};
