export const getNthParent = (elem: HTMLElement, n: number): HTMLElement => {
	return n === 0 ? elem : getNthParent(elem.parentNode as HTMLElement, n - 1);
};

export const findObjectInArray = (
	array: unknown[],
	value: unknown,
): unknown => {
	return array.find(
		(x: unknown) => JSON.stringify(x) === JSON.stringify(value),
	);
};

export const equalsObjects = (a: unknown, b: unknown): boolean => {
	return JSON.stringify(a) === JSON.stringify(b);
};

// Sets the caret in the specified HTML element
export const setCaretInHTMLElement = (
  target: HTMLElement,
  isStart: boolean,
) => {
  const range = document.createRange(); // Create a new range object
  const sel = window.getSelection(); // Get the current selection
  if (isStart) {
    const newText = document.createTextNode(''); // Create an empty text node
    target.appendChild(newText); // Add it to the target element
    range.setStart(target.childNodes[0], 0); // Set the start position of the range
  } else {
    range.selectNodeContents(target); // Select the contents of the target element
  }
  range.collapse(isStart); // Collapse the range to the specified position
  sel?.removeAllRanges(); // Remove all previous selections
  sel?.addRange(range); // Add the new range to the selection
  target.focus(); // Set focus on the target element
  target.scrollLeft = target.scrollWidth; // Scroll the element to the right end
};
