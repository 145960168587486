import { EuiIcon } from '../types/icon.interface';

export const notePlus: EuiIcon = {
	name: 'note-plus',
	data: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.12793 18H11.416C11.2119 17.5329 11.0771 17.0285 11.0249 16.5H6.12793C4.59668 16.5 4.1665 16.3637 3.75391 16.143C3.36523 15.9352 3.06494 15.6348 2.85693 15.2463C2.63623 14.8336 2.5 14.4032 2.5 12.8722V7.12778C2.5 5.59677 2.63623 5.16644 2.85693 4.75369C3.06494 4.3652 3.36523 4.06476 3.75391 3.85699C4.10352 3.66992 4.46582 3.54355 5.5 3.50934V6.25C5.5 6.66422 5.83594 7 6.25 7C6.66406 7 7 6.66422 7 6.25V3.5H11V6.25C11 6.66422 11.3359 7 11.75 7C12.1641 7 12.5 6.66422 12.5 6.25V3.50934C13.5342 3.54355 13.8965 3.66992 14.2461 3.85699C14.6348 4.06476 14.9351 4.3652 15.1431 4.75369C15.3638 5.16644 15.5 5.59677 15.5 7.12778V11.0247C15.6646 11.0084 15.8311 11 16 11C16.3423 11 16.6768 11.0344 17 11.1V7.12778C17 5.51749 16.8623 4.78763 16.4658 4.0463C16.1182 3.39639 15.6035 2.88184 14.9536 2.53427C14.2124 2.13779 13.4824 2 11.8721 2H6.12793C4.51758 2 3.7876 2.13779 3.04639 2.53427C2.39648 2.88184 1.88184 3.39639 1.53418 4.0463C1.1377 4.78763 1 5.51749 1 7.12778V12.8722C1 14.4825 1.1377 15.2124 1.53418 15.9537C1.88184 16.6036 2.39648 17.1182 3.04639 17.4657C3.7876 17.8622 4.51758 18 6.12793 18Z" fill="#262626"/>
<path d="M4.75 12.5H12.4292C12.0063 12.9312 11.6611 13.4388 11.416 14H4.75C4.33594 14 4 13.6642 4 13.25C4 12.8358 4.33594 12.5 4.75 12.5Z" fill="#262626"/>
<path d="M13.25 10.5C13.6641 10.5 14 10.1642 14 9.75C14 9.33578 13.6641 9 13.25 9H4.75C4.33594 9 4 9.33578 4 9.75C4 10.1642 4.33594 10.5 4.75 10.5H13.25Z" fill="#262626"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 16C20 18.2091 18.209 20 16 20C13.791 20 12 18.2091 12 16C12 13.7909 13.791 12 16 12C18.209 12 20 13.7909 20 16ZM15.4653 18.0065C15.4653 18.3171 15.6743 18.5324 15.999 18.5324C16.3188 18.5324 16.5278 18.3171 16.5278 18.0065V16.5266H18C18.3169 16.5266 18.5322 16.3177 18.5322 15.998C18.5322 15.6796 18.3169 15.4707 18 15.4707H16.5278V13.9949C16.5278 13.6815 16.3188 13.4662 15.999 13.4676C15.6743 13.4691 15.4653 13.6844 15.4653 13.9949V15.4707H13.9946C13.6748 15.4707 13.4658 15.6796 13.4673 15.998C13.4688 16.3177 13.6777 16.5266 13.9946 16.5266H15.4653V18.0065Z" fill="#262626"/>
</svg>
`,
};
